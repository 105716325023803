import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AppStore, CurrentUserStore, ShopStore, CarStore } from 'stores'; // Ensure CarStore has the required state
import styled from 'styled-components';
import {
  Form,
  Button,
  Typography,
  notification,
  InputNumber,
  Spin,
  Switch, // Import Switch from Ant Design
} from 'antd';

const { Text } = Typography;

const Container = styled.div`
  padding-right: 2vw;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

class EditProfilePage extends Component {
  formRef = React.createRef();

  state = {
    shopId: 0,
    loadingInitial: true,
    savingForm: false,
    shopStores: [],
    markAsCompleteThresholdHours: {
      value: 5,
      validateStatus: 'success',
      errorMsg: null,
    },
    taxType: 'absolute',
    taxValue: 0,
    taxOptions: [],
    demoDataEnabled: CarStore.demo || false, // Initialize from CarStore
  };

  async componentDidMount() {
    this.load(this.shopId);
    const { shop = [] } = CurrentUserStore.user;

    try {
      const settings = await ShopStore.getShopSettings(ShopStore.currentShop.id);
      const fleetSettings = _.get(settings.result, '[0].settings.fleetsettings', {});

      let demoDataEnabled = _.get(fleetSettings, 'demoDataEnabled', CarStore.demo);

      // ✅ If user is admin OR real cars exist, force toggle OFF
      if (CurrentUserStore.user.role === "admin" || CarStore.hasRealCars) {
        console.log("🚫 Forcing demo toggle OFF (Admin or Real Cars Exist)");
        demoDataEnabled = false;
      } else {
        // ✅ Otherwise, ensure it matches hasDemoCars
        demoDataEnabled = CarStore.hasDemoCars;
      }

      this.setState({
        shopId: ShopStore.currentShop.id,
        shopStores: shop.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        demoDataEnabled,
      });

      // ✅ Update CarStore state
      CarStore.demo = demoDataEnabled;
    } catch (err) {
      console.error("Error fetching demoDataEnabled state:", err);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // ✅ Ensure demo toggle updates when the fleet changes
    if (prevState.shopId !== this.state.shopId) {
      console.log(`🏢 Switched fleet to ID: ${this.state.shopId}`);

      const fleetHasRealCars = CarStore.hasRealCars;
      let demoDataEnabled = this.state.demoDataEnabled;

      if (fleetHasRealCars) {
        console.log("🚫 New fleet has real cars. Forcing demo mode OFF.");
        demoDataEnabled = false;
      } else if (CurrentUserStore.user.role === "admin") {
        console.log("✅ Admin selected a fleet without real cars. Unlocking toggle.");
        demoDataEnabled = CarStore.hasDemoCars;
      }

      this.setState({ demoDataEnabled });
    }
  }

  toggleDemoData = async () => {
    CarStore.toggleDemo();
    this.setState({ demoDataEnabled: CarStore.demo });

    if (CarStore.demo) {
      console.log("📢 Assigning Demo Cars...");
      await CarStore.assignDemoCar();
    } else {
      console.log("🚫 Removing Demo Cars...");
      await CarStore.unassignDemoCar();
      this.setState({ demoDataEnabled: false });

      if (!CarStore.hasDemoCars) {
        console.log("⚠️ No more demo cars. Preventing invalid UI state.");
        this.setState({ demoDataEnabled: false }); // Ensure the toggle updates correctly
      }

    }
  };

  validateNumber = (number) => {
    if (number < 1) {
      return {
        validateStatus: 'error',
        errorMsg: 'Number must be greater or equal to 1',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };

  load = async (shopId) => {
    this.setState({
      taxType: '',
      taxValue: 0,
      taxOptions: [],
    });
    try {
      const { result } = await ShopStore.getShopSettings(shopId);
      if (
        !_.isEmpty(result) &&
        !_.isNil(result[0].settings) &&
        !_.isNil(result[0].settings.fleetsettings)
      ) {
        const fleetSettings = result[0].settings.fleetsettings;

        const value = _.get(fleetSettings, 'markAsCompleteThresholdHours', 5);

        this.props.form.setFieldsValue({
          taxType: fleetSettings.taxType,
          taxValue: fleetSettings.taxValue,
          taxOptions: fleetSettings.taxOptions,
        });
        this.setState(
          {
            markAsCompleteThresholdHours: {
              ...this.validateNumber(value),
              value,
            },
            taxType: fleetSettings.taxType,
            taxValue: fleetSettings.taxValue,
            taxOptions: fleetSettings.taxOptions,
          },
          () => {
            this.setState({ loadingInitial: false });
          }
        );
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({ loadingInitial: false });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <React.Fragment>
        <Form ref={this.formRef} onSubmit={this.updateFleetRules} className="edit-fleet-rules-form">
          <Form.Item
            label="Clear Fault Codes After"
            validateStatus={this.state.markAsCompleteThresholdHours.validateStatus}
            help={this.state.markAsCompleteThresholdHours.errorMsg}
          >
            {getFieldDecorator('markAsCompleteThresholdHours', {
              initialValue: this.state.markAsCompleteThresholdHours,
            })(
              <>
                <InputNumber
                  placeholder="5"
                  value={this.state.markAsCompleteThresholdHours.value}
                  onChange={(value) =>
                    this.setState({
                      markAsCompleteThresholdHours: {
                        ...this.validateNumber(value),
                        value,
                      },
                    })
                  }
                />
                <Text style={{ marginLeft: '3px' }} type="secondary">
                  Driving Hours
                </Text>
              </>
            )}
          </Form.Item>

          {/* ✅ Toggle Sample Data Switch with working UI update */}
          <Form.Item label="Turn on Demo Fleet Data">
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={this.state.demoDataEnabled}
              onChange={this.toggleDemoData}
              disabled={CarStore.hasRealCars || (CurrentUserStore.user.role === "admin" && CarStore.hasRealCars)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={this.state.markAsCompleteThresholdHours.validateStatus !== 'success'}
              loading={this.state.savingForm}
            >
              Update Rules
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditProfilePage.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create({ name: 'edit-fleet-rules-form' })(EditProfilePage);