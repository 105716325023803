import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Input, Icon, Tooltip, message } from 'antd';

import AppStore from 'stores/App';

const TextStlyed = styled.p`
  flex: 1;
  margin-bottom: 0;
  align-self: center;
`;

const EditIcon = styled(Icon)`
  width: 30px;
  align-self: flex-end;
  margin-bottom: 0;
  align-self: center;
`;

const InputStyled = styled(Input)`
  && {
    .ant-input {
      padding-right: 50px;
    }

    .ant-input-suffix {
      i {
        padding: 0px 2px;
      }
    }
  }
`;

export default function TextField({
  placeholder,
  type,
  defaultValue,
  onSubmit,
  noValueText,
  isEdit = false,
  maxLenght,
  requiredValue = false,
}) {
  const [value, setValue] = useState(defaultValue);
  const [editable, setEditable] = useState(isEdit);

  //DEFINE MAXLENGTH ON FIELD
  const maxLen = maxLenght || (type === 'year' ? 4 : undefined);

  //DEFINE VALIDATION FOR YEAR FIELD
  const isValidYear = (year) => {
    if (type !== 'year') return true;
    const yearNumber = parseInt(year, 10);
    return (
      year.length === 4 &&
      yearNumber >= 1900 &&
      yearNumber <= new Date().getFullYear()
    );
  };

  const handleChange = (e) => {
    let newValue = e.target.value;

    if (['year', 'number'].includes(type)) {
      newValue = newValue.replace(/\D/g, '');
      if (newValue.length > maxLen) {
        newValue = newValue.slice(0, maxLen);
      }
    }
    //(e) => setValue(e.target.value)

    setValue(newValue);
  };

  const onSubmitEdit = async () => {
    try {
      console.log({
        rv: requiredValue,
        val: value,
      });

      if (requiredValue && !value.trim() && value == null) {
        message.error('Required value');
        return;
      }

      let newValue = ['year', 'number'].includes(type)
        ? value.replace(/\D/g, '')
        : value;
      await onSubmit(newValue);
      setEditable(false);
    } catch (err) {
      AppStore.addError(err.message);
    }
  };

  const handlerKeyDown = (e) => {
    if (
      ['year', 'number'].includes(type) &&
      !/^[0-9]$/.test(e.key) &&
      !['Backspace', 'Tab', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(
        e.key
      ) &&
      e.keyCode !== 222
    ) {
      e.preventDefault();
    }
  };

  if (editable) {
    return (
      <>
        <InputStyled
          required
          placeholder={defaultValue || placeholder}
          type={type || 'string'}
          min={['number', 'year'].includes(type) ? 0 : null}
          suffix={[
            <Tooltip key="close-circle" title="Close">
              <Icon
                type="close-circle"
                onClick={() => {
                  setValue(defaultValue);
                  setEditable(false);
                }}
              />
            </Tooltip>,
            <Tooltip key="check-circle" title="Confirm">
              <Icon type="check-circle" onClick={onSubmitEdit} />
            </Tooltip>,
          ]}
          defaultValue={defaultValue}
          onChange={handleChange}
          onKeyDown={handlerKeyDown}
          onPressEnter={onSubmitEdit}
          maxLength={maxLen}
        />
      </>
    );
  }

  return (
    <>
      <TextStlyed>
        {!_.isNil(value) ? value : noValueText || placeholder}
      </TextStlyed>
      <Tooltip title="Edit">
        <EditIcon type="edit" onClick={() => setEditable(true)} />
      </Tooltip>
    </>
  );
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noValueText: PropTypes.string,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  maxLenght: PropTypes.number,
  requiredValue: PropTypes.bool,
};

TextField.defaultProps = {
  requiredValue: false,
};
