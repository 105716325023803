import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import {
  Card,
  Col,
  Row,
  Spin,
  notification,
} from 'antd'; // New: Import necessary components for filters
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { get } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FleetReportsFiltersForm } from 'components/FleetReports/FleetReportsFiltersForm';

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class FleetCostsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingVehicleCosts: false,
    isLoadingReasonsForCost: false,
    dataForReasonsForCost: [],
    dataForVehicleCosts: [],
    pmDataForVehicleCosts: [],
    nonPMDataForVehicleCosts: [],
    isStacked: false,
    timePeriod: 30, // New: Add timePeriod to state
    limit: 20, // New: Add limit to state
    filter: {
      vendor: [],
      make: [],
      model: [],
      year: [],
      carIds: [],
    }
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.init();
  });

  deburrLoadFleetCosts = null;

  loadDataForVehicleCosts = async () => {
    // of interest
    try {
      this.setState({ isLoadingVehicleCosts: true });
      const { timePeriod, limit, filter } = this.state; // New: Destructure timePeriod and limit from state
      const response = await ShopStore.getShopDataForVehicleCosts(
        ShopStore.currentShop.id,
        timePeriod, // New: Pass timePeriod as parameter
        limit, // New: Pass limit as parameter
        filter
      );
      const data = get(response, 'data', []);

      const idCars = Array.isArray(data) ? data.map((item) => item.id_car) : [];
      const response2 = await ShopStore.getUnitIDsFromCarIds(idCars);
      const carNamesData = get(response2, 'data', []);

      // Create a mapping of car IDs to car names
      const carNamesMap = {};
      carNamesData.forEach((car) => {
        carNamesMap[car.id] = car.car_name;
      });

      // Process the data
      const combinedData = [];
      const pmData = [];
      const nonPMData = [];
      const yAxisCategories = data.map((item) => carNamesMap[item.id_car]);

      data.forEach((item, index) => {
        const vehicleId = item.id_car;
        combinedData.push({
          x: index,
          y: parseFloat(item.visit_cost.toFixed(2)),
          vehicleId: vehicleId,
          carName: carNamesMap[vehicleId],
        });
        pmData.push({
          x: index,
          y: parseFloat(item.pm_visit_cost.toFixed(2)),
          vehicleId: vehicleId,
          carName: carNamesMap[vehicleId],
        });
        nonPMData.push({
          x: index,
          y: parseFloat(item.non_pm_visit_cost.toFixed(2)),
          vehicleId: vehicleId,
          carName: carNamesMap[vehicleId],
        });
      });

      this.setState({
        dataForVehicleCosts: combinedData,
        pmDataForVehicleCosts: pmData,
        nonPMDataForVehicleCosts: nonPMData,
        vehicles: yAxisCategories,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({
        dataForVehicleCosts: [],
        pmDataForVehicleCosts: [],
        nonPMDataForVehicleCosts: [],
      });
    } finally {
      this.setState({ isLoadingVehicleCosts: false });
    }
  };

  loadDataForReasonsForCost = async () => {
    // of interest
    try {
      this.setState({ isLoadingReasonsForCost: true });
      const data = await ShopStore.getShopDataForReasonsForCost(
        ShopStore.currentShop.id
      );
      this.setState({ dataForReasonsForCost: data });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    } finally {
      this.setState({ isLoadingReasonsForCost: false });
    }
  };

  init = async () => {
    // load data for Vehicle Costs
    this.loadDataForVehicleCosts();
    // load data for Reasons for Cost
    // this.loadDataForReasonsForCost();
  };

  toggleChartType = (checked) => {
    this.setState({ isStacked: checked });
  };

  handleFilterChange = (changedFields) => {
    // New: Handle filter changes
    this.setState(changedFields);

    // call deburrLoadAssetDuration
    if (changedFields.limit || changedFields.timePeriod) {
      if (this.deburrLoadFleetCosts) {
        clearTimeout(this.deburrLoadFleetCosts);
      }
      this.deburrLoadFleetCosts = setTimeout(() => {
        this.loadDataForVehicleCosts();
      }, 500);
    }
  };

  handleResetFilters = () => {
    this.setState({
      filter: {
        vendor: [],
        make: [],
        model: [],
        year: [],
        carIds: [],
      },
      timePeriod: 30,
      limit: 20,
    }, () => {
      this.loadDataForVehicleCosts();
    });
  };

  handleApplyFilters = () => {
    // New: Apply filters
    this.init();
  };

  render() {
    const {
      isStacked,
      pmDataForVehicleCosts,
      nonPMDataForVehicleCosts,
      dataForVehicleCosts,
      timePeriod,
      limit,
      isLoadingVehicleCosts,
    } = this.state;

    const vehicleCostsChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.state.vehicles, // Adjusted field name
        crosshair: true,
        title: {
          text: 'Unit ID',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost',
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color:
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      plotOptions: {
        column: {
          stacking: isStacked ? 'normal' : undefined,
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y < 1000 && this.y > 0) {
                return Math.round(this.y); // Display numbers less than 1000 rounded to the nearest whole number
              } else if (this.y === 0) {
                return null;
              } else {
                return Math.round(this.y / 100) / 10 + 'k'; // Display 1000 and greater rounded to the nearest hundred with 'k'
              }
            },
            style: {
              textOutline: '2px contrast', // Adjust the thickness here
            },
          },
        },
        series: {
          pointWidth: 37,
          cursor: 'pointer',
          point: {
            events: {
              click: (options) => {
                // Construct the URL dynamically
                const vehicleId = options?.target?.point?.vehicleId;
                if (vehicleId) {
                  const customUrl = `/car/${vehicleId}/work-orders`;
                  this.props.history.push(customUrl);
                }
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y < 1000 && this.y > 0) {
                return Math.round(this.y); // Display numbers less than 1000 rounded to the nearest whole number
              } else if (this.y === 0) {
                return null;
              } else {
                return Math.round(this.y / 100) / 10 + 'k'; // Display 1000 and greater rounded to the nearest hundred with 'k'
              }
            },
            style: {
              textOutline: '2px contrast', // Adjust the thickness here
            },
          },
        },
      },
      series: isStacked
        ? [
            {
              name: 'Unplanned Maintenance',
              data: nonPMDataForVehicleCosts.map((item) => ({
                y: item.y,
                vehicleId: item.vehicleId,
                carName: item.carName,
              })),
              color: '#bf2412',
              dataLabels: {
                allowOverlap: true,
                overflow: 'none',
                verticalAlign: 'top',
                y: -30,
                crop: false,
                style: {
                  textOutline: '2px contrast', // Adjust the thickness here
                },
              },
            },
            {
              name: 'Planned Maintenance',
              data: pmDataForVehicleCosts.map((item) => ({
                y: item.y,
                vehicleId: item.vehicleId,
                carName: item.carName,
              })),
              color: '#123fbf',
            },
          ]
        : [
            {
              name: 'Total Cost',
              data: dataForVehicleCosts.map((item) => ({
                y: item.y,
                vehicleId: item.vehicleId,
                carName: item.carName,
              })),
              color: '#183cbc',
            },
          ],
    };

    // const reasonsForCostChartOptions = {
    //   chart: {
    //     type: 'column',
    //   },
    //   title: {
    //     text: 'Reasons for Cost',
    //   },
    //   xAxis: {
    //     categories: dataForReasonsForCost.map(item => item.name), // Adjusted field name
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Cost',
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Cost',
    //       data: dataForReasonsForCost.map(item => item.amount), // Adjusted field name
    //     },
    //   ],
    // };

    return (
      <Fragment>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <h1>Fleet Costs</h1>
          </Col>
        </Row>
        <DealershipChooser />
        {/* <Form layout="inline" onValuesChange={this.handleFilterChange}>
          {' '}
          <Form.Item label="Time Period">
            <Select
              defaultValue={30}
              value={timePeriod}
              onChange={(value) =>
                this.handleFilterChange({ timePeriod: value })
              }
            >
              <Option value={30}>30 days</Option>
              <Option value={90}>90 days</Option>
              <Option value={180}>180 days</Option>
              <Option value={360}>360 days</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Limit">
            <InputNumber
              min={1}
              defaultValue={20}
              value={limit}
              onChange={(value) => this.handleFilterChange({ limit: value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>
              Apply Filters
            </Button>{' '}
          </Form.Item>
          <Form.Item label="Maintenance Insights">
            <Switch
              checked={isStacked}
              onChange={this.toggleChartType}
              checkedChildren="ON"
              unCheckedChildren="OFF"
            />
            <Tooltip
              placement="right"
              title={
                'Toggle to divide each bar in the chart highlighting a red section for unplanned maintenance and blue section for planned maintenance'
              }
            >
              <Button
                style={{ marginLeft: 10, marginRight: 20 }}
                size="small"
                shape="circle"
                icon="info"
              />
            </Tooltip>
          </Form.Item>
        </Form> */}
        <FleetReportsFiltersForm
          filter={{
            filter: this.state.filter,
          }}
          timePeriod={timePeriod}
          limit={limit}
          hideStatusFilter={true}
          hideVendorFilter={true}
          showMaintenanceInsights={true}
          suppressTimePeriod={[7]}
          onChange={this.handleFilterChange}
          onReset={this.handleResetFilters}
          onApply={this.handleApplyFilters}
          isMaintenanceInsightsStacked={isStacked}
          onToggleMaintenanceInsights={this.toggleChartType}
        />
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingVehicleCosts}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Vehicle Costs</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={vehicleCostsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          {/* <Col span={8}>
            <Spin spinning={this.state.isLoadingVehicleCosts}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Vehicle Costs</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={vehicleCostsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col> */}
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(FleetCostsPage));
