import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Button, Card, Col, Icon, Row, Spin } from 'antd';
import styled from 'styled-components';
import { observe } from 'mobx';
import { CarStatusStore, ShopStore } from 'stores';
import { get } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FleetReportsFiltersForm } from 'components/FleetReports/FleetReportsFiltersForm';
import { downloadCSV } from 'shared/utils';
import { notifications } from '../../helpers/notificationHelper';

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class AssetDurationPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isInitialState: true,
    isLoadingAssetDuration: false,
    assetDurationData: [],
    timePeriod: 7,
    limit: 20,
    status: [],
    selectedStatusDescription: '',
    searchText: '', // State for storing search input
    isLoadingInitialFilterData: false,
    filter: {
      status: [],
      vendor: undefined,
      make: undefined,
      model: undefined,
      year: undefined,
      carIds: undefined,
    },
    shopStatuses: [],
  };

  async componentDidMount() {
    await Promise.all([
      this.loadInitialFilterData(),
      // this.loadDataForAssetDuration(),
    ]);
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    await Promise.all([
      this.loadInitialFilterData(),
      this.loadDataForAssetDuration(),
    ]);
  });

  loadInitialFilterData = async () => {
    this.setState({ isLoadingInitialFilterData: true });
    const [
      shopStatuses,
    ] = await Promise.all([
      CarStatusStore.fetchShopCarStatuses(ShopStore.currentShop.id),
    ]);
    this.setState({
      shopStatuses: shopStatuses || [],
    });
  };

  loadDataForAssetDuration = async () => {
    try {
      this.setState({ isLoadingAssetDuration: true });
      const {
        timePeriod,
        limit,
        searchText,
        filter: {
          status,
          vendor,
          make,
          model,
          year,
          carIds,
        },
      } = this.state;

      const response = await ShopStore.getAssetDurationData(
        ShopStore.currentShop.id,
        timePeriod,
        limit,
        status,
        searchText,
        {
          vendor: vendor,
          make: make,
          model: model,
          year: year,
          carIds: carIds,
        },
      );

      const data = get(response, 'data.assets', []);

      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          assetDurationData: data,
        });
      } else {
        notifications.warning('No asset duration data available for the selected filters.');
        this.setState({ assetDurationData: [] });
      }
    } catch (error) {
      if (this.state.filter.status.length === 0) {
        notifications.warning('Please select at least one status to filter.');
      } else {
        notifications.error(`Error loading asset duration data: ${error.message || 'Unknown error'}`);
      }
      this.setState({ assetDurationData: [] });
    } finally {
      this.setState({ isLoadingAssetDuration: false, isInitialState: false });
    }
  };

  deburrLoadAssetDuration = null;

  handleFilterChange = (changedFields) => {
    if (changedFields.filter && changedFields.filter.status && Array.isArray(changedFields.filter.status)) {
      const selectedStatuses = this.state.shopStatuses.filter(
        (status) => changedFields.filter.status.includes(status.key)
      );
      this.setState({
        ...changedFields,
        selectedStatusDescription: selectedStatuses.map((status) => status.description).join(', '),
      });
    } else {
      this.setState(changedFields);
    }

    // call deburrLoadAssetDuration
    if (changedFields.limit || changedFields.timePeriod) {
      if (this.deburrLoadAssetDuration) {
        clearTimeout(this.deburrLoadAssetDuration);
      }
      this.deburrLoadAssetDuration = setTimeout(() => {
        this.loadDataForAssetDuration();
      }, 500);
    }
  };

  handleSearchTextChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.loadDataForAssetDuration(); // Automatically call API when search text changes
    });
  };

  handleApplyFilters = () => {
    this.loadDataForAssetDuration();
  };

  handleExportToCSV = async () => {
    // Show a loading notification
    const notificationId = notifications.loading('Exporting Asset Duration data to CSV... You can keep using the app while this is happening.');

    try {
      const {
        timePeriod,
        limit,
        searchText,
        filter: {
          status,
          vendor,
          make,
          model,
          year,
          carIds,
        },
      } = this.state;

      const response = await ShopStore.getAssetDurationDataCSV(
        ShopStore.currentShop.id,
        timePeriod,
        limit,
        status,
        searchText,
        {
          vendor: vendor,
          make: make,
          model: model,
          year: year,
          carIds: carIds,
        },
      );

      const fileName = `Asset Duration Data - ${new Date().toLocaleDateString()}`;
      const csvData = response?.data || [];
      downloadCSV(csvData, fileName, false);

      // Update notification to success
      notifications.success('Asset Duration data exported successfully!', { id: notificationId });
    } catch (error) {
      // Update notification to error
      const errorMessage = error?.message || 'Unknown error occurred';
      notifications.error(`Failed to export CSV: ${errorMessage}`, { id: notificationId });
      this.setState({ assetDurationData: [] });
    }
  };

  handleResetFilters = () => {
    this.setState({
      filter: {
        status: ['running'],
        vendor: undefined,
        make: undefined,
        model: undefined,
        year: undefined,
        carIds: undefined,
      },
      status: ['running'],
      selectedStatusDescription: '',
    }, () => {
      this.loadDataForAssetDuration();
    });
  };

  render() {
    const {
      assetDurationData,
      timePeriod,
      isLoadingAssetDuration,
      selectedStatusDescription,
      limit,
      filter: {
        status,
      },
    } = this.state;

    let statusToDisplay = '';
    if (Array.isArray(status)) {
      statusToDisplay = status.join(',');
    } else if (!status) {
      statusToDisplay = '';
    } else {
      statusToDisplay = status.replace('-', ' ');
    }

    const assetDurationChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: assetDurationData.map(item => `${item.asset_name} (${item.asset_type})`),
        crosshair: true,
        title: {
          text: 'Unit ID',
        },
      },
      yAxis: {
        min: 0,
        max: Math.max(...assetDurationData.map(item => item.duration_in_days)) + 2,
        title: {
          text: 'No. of Days',
        },
        tickInterval: 1
      },
      plotOptions: {
        column: {
          dataLabels: [{
            enabled: true,
            formatter: function () {
              return this.y;
            },
            style: {
              textAlign: 'center',
              color: '#333333',
              fontWeight: 'bold',
            },
            y: -5,
            crop: false,
            overflow: 'allow'
          }, {
            enabled: true,
            formatter: function () {
              const percentage = Math.round((this.y / this.series.userOptions.timePeriod) * 100);
              return `${percentage}%`;
            },
            inside: true,
            align: 'center',
            verticalAlign: 'top',
            y: 10,
            style: {
              textAlign: 'center',
              color: '#FFFFFF',
              fontWeight: 'bold',
              textOutline: 'none'
            }
          }],
          borderRadius: 3,
          pointPadding: 0.2
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (options) => {
                // Construct the URL dynamically
                const vehicleId = options?.target?.point?.assetId;
                if (vehicleId) {
                  const customUrl = `/car/${vehicleId}/work-orders`;
                  this.props.history.push(customUrl);
                }
              },
            },
          },
        },
      },
      series: [
        {
          name: selectedStatusDescription || statusToDisplay || '',
          data: assetDurationData.map(item => {
            return {
              y: item.duration_in_days,
              assetId: item.asset_id,
              assetType: item.asset_type,
            };
          }),
          color: '#183cbc',
          timePeriod: timePeriod
        },
      ],
    };

    return (
      <Fragment>
        <Row gutter={[8, 8]} justify="space-between" align="middle">
          <Col span={20}>
            <h1>Asset Status Duration</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Row gutter={[8, 8]} justify="space-between" align="middle">
          <Col span={20}>
            <FleetReportsFiltersForm
              filter={{
                filter: this.state.filter,
              }}
              timePeriod={timePeriod}
              limit={limit}
              onChange={this.handleFilterChange}
              onReset={this.handleResetFilters}
              onApply={this.handleApplyFilters}
            />
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={this.handleExportToCSV}>
              <Icon type="export" />
              Export to CSV
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingAssetDuration}>
              <Card>
                <Row gutter={[8, 8]} justify="space-between" align="middle">
                  <Col style={{ marginTop: '5px' }} span={4}>
                    <H2Styled>Asset Status Duration</H2Styled>
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    {assetDurationData.length > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={assetDurationChartOptions}
                      />
                    ) : (
                      <p>{
                        this.state.isInitialState
                          ? 'To start, click the filters button above and apply at least one Status filter to see any data here.'
                          : 'No data available for the selected filters.'
                      }</p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(AssetDurationPage));
