import React, { useState, memo, useEffect } from 'react';
import { Card, Typography, Spin, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ShopStore, ReportsDataStore } from 'stores';
import { observe } from 'mobx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const { Paragraph, Text } = Typography;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;
const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 90%;
  width: inherit;
`;

const StyledValue = styled(Text)`
  font-size: 46px;
  font-weight: 600;
  color: ${({ safe }) => (safe ? '#237804' : '#D4380D')} !important;
`;
const StatRow = styled(Row)`
  height: 95%;
  padding: 15px;
`;
const StatCol = styled(Col)`
  height: inherit;
  justify-content: space-around;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    .defects {
      text-decoration: underline;
      text-decoration-color: #9b9b9b;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
  }
`;

const ItalicText = styled(Text)`
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
`;

const StyledIcon = styled(InfoCircleOutlined)`
  color: black !important;
  margin-left: 5px;
`;

const DVIRStatWidget = ({ shopId, history, isTrailer = false }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [stats, setStats] = useState({
    safe: 0,
    unsafe: 0,
    safeDefects: 0,
    unsafeDefects: 0,
  });

  useEffect(() => {
    loadstat();
  }, [isTrailer, shopId]);

  const updateQueryParam = (key, value) => {
    const queryParams = new URLSearchParams(history.location.search);
    const newValue = Array.isArray(value) ? value.join(',') : value;

    queryParams.set(key, newValue);

    history.push({
      search: queryParams.toString(),
    });
  };

  async function loadstat() {
    setIsLoading(true);
    const result = await ReportsDataStore.getDVIRStats(shopId, isTrailer);
    setStats(result?.data);
    setIsLoading(false);
  }

  return (
    <Card className="summary-card">
      <div className="summary-card__title">
        <Paragraph>
          <Text strong>DVIR </Text>
          <ItalicText>(Click on any element below to filter by)</ItalicText>
          <Tooltip title="Defects are counted within assets. E.g, if there are 5 unsafe assets and 20 unsafe defects, those defects are distributed among the 5 assets.">
            <StyledIcon />
          </Tooltip>
        </Paragraph>
      </div>

      {isLoading ? (
        <SpinContainer>
          <Spin tip="Loading..." />
        </SpinContainer>
      ) : (
        <>
          <StatRow>
            <StatCol
              span={12}
              onClick={() =>
                updateQueryParam('vehicleDvir', ['unsafe', 'defects'])
              }
            >
              <Stats
                type="unsafe"
                dvirs={stats?.unsafe || 0}
                dvirsDefects={stats?.unsafeDefects || 0}
              />
            </StatCol>
            <StatCol
              span={12}
              onClick={() => {
                updateQueryParam('vehicleDvir', ['safe', 'defects']);
              }}
            >
              <Stats
                type="safe"
                dvirs={stats?.safe || 0}
                dvirsDefects={stats?.safeDefects || 0}
              />
            </StatCol>
          </StatRow>
        </>
      )}
    </Card>
  );
};

const Stats = ({ type, dvirs, dvirsDefects }) => {
  const captions = {
    safe: (
      <>
        Safe Assets <br /> w/ Defects
      </>
    ),
    unsafe: (
      <>
        Unsafe Assets <br /> w/ Defects
      </>
    ),
    safeDefects: 'Total Safe Defects',
    unsafeDefects: 'Total Unsafe Defects',
  };
  return (
    <>
      <Row align="middle" justify="center">
        <Row align="middle" justify="center" style={{ textAlign: 'center' }}>
          <StyledValue safe={type === 'safe'}>{dvirs}</StyledValue>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            textAlign: 'center',
          }}
          className="defects"
        >
          {captions[type]}
        </Row>
      </Row>
      <Row align="middle" justify="center">
        <Row
          align="middle"
          justify="center"
          style={{
            textAlign: 'center',
          }}
        >
          <StyledValue safe={type === 'safe'}>{dvirsDefects}</StyledValue>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            textAlign: 'center',
          }}
          className="defects"
        >
          {captions[`${type}Defects`]}
        </Row>
      </Row>
    </>
  );
};

export default withRouter(memo(DVIRStatWidget));
