import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Button, Modal } from 'antd';
import 'antd/lib/modal/style/index.css';

// Define TypeScript interface for props
interface PitstopModalProps {
  isVisible: boolean;
  form?: any;
  failureMessage?: string;
  notifyMessage?: string;
  children: React.ReactNode;
  confirmOkMessage?: string;
  okText?: string;
  onOk?: () => Promise<any> | any;
  clearExtraFields?: () => void;
  onCancel?: () => void;
  successMessage?: string;
  title?: string;
  width?: string | number;
  centered?: boolean;
  afterClose?: () => void;
  isManualClose?: boolean;
  cancelButtonProps?: object;
  closable?: boolean;
  okButtonProps?: object;
  closeOnBackdropClick?: boolean;
  cancelText?: string;
}

// Create CSS for dark modal header
const injectDarkHeaderStyles = () => {
  if (typeof document === 'undefined') return;

  const styleId = 'pitstop-modal-dark-header-style';
  if (!document.getElementById(styleId)) {
    const styleEl = document.createElement('style');
    styleEl.id = styleId;
    styleEl.innerHTML = `
      .pitstop-dark-header-modal .ant-modal-header {
        background-color: #02103d;
        border-bottom: 1px solid #02103d;
      }
      .pitstop-dark-header-modal .ant-modal-title {
        color: white;
      }
      .pitstop-dark-header-modal .ant-modal-close {
        color: white;
      }
      .pitstop-dark-header-modal .ant-modal-close-x {
        color: white;
      }
      .modal-content-wrapper {
        position: relative;
        padding: 0;
        background: white;
        border-radius: 4px;
        margin: 0 auto;
        max-width: 90%;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }
      .modal-dark-header {
        background-color: #02103d;
        color: white;
        padding: 16px 24px;
        border-radius: 4px 4px 0 0;
        position: relative;
      }
      .modal-content {
        padding: 24px;
      }
      .modal-footer {
        padding: 10px 16px;
        text-align: right;
        border-top: 1px solid #f0f0f0;
      }
      .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `;
    document.head.appendChild(styleEl);
  }
};

// Call the style injection
injectDarkHeaderStyles();

// Create a custom modal component with dark header
const PitstopModalDarkHeader: React.FC<PitstopModalProps> = ({
  isVisible = false,
  children,
  title,
  width = 520,
  centered,
  okText = 'Finish',
  cancelText = 'Cancel',
  onOk,
  onCancel,
  afterClose,
  isManualClose = false,
  cancelButtonProps = {},
  closable = true,
  confirmOkMessage,
  successMessage,
  failureMessage,
  notifyMessage,
  form,
  clearExtraFields,
  okButtonProps = {},
  closeOnBackdropClick = false,
}) => {
  // Handler for the confirm dialog when confirmOkMessage is provided
  const handleConfirmOk = async () => {
    if (!confirmOkMessage) {
      await handleOk();
      return;
    }

    Modal.confirm({
      title: confirmOkMessage,
      centered: centered,
      onOk: async () => {
        await handleOk();
      },
    });
  };

  // Success message handler
  const showSuccessMessage = () => {
    if (successMessage) {
      // If you have a global notification system, use it here
      console.log('Success:', successMessage);
    }
  };

  // Failure message handler
  const showFailureMessage = () => {
    if (failureMessage) {
      // If you have a global notification system, use it here
      console.log('Error:', failureMessage);
      if (notifyMessage) {
        console.log('Notification:', notifyMessage);
      }
    }
  };

  // Main ok handler
  const handleOk = async () => {
    try {
      if (!onOk) return;
      const result = await onOk();
      if (result === false) return;

      showSuccessMessage();

      // If not manual close, call onCancel to close the modal
      if (!isManualClose && onCancel) {
        onCancel();
      }
    } catch (e) {
      showFailureMessage();
    }
  };

  // Cancel handler
  const handleCancel = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    if (onCancel) {
      onCancel();
      if (clearExtraFields) {
        clearExtraFields();
      }
    }

    if (form && form.resetFields) {
      form.resetFields();
    }

    // Call afterClose after a short delay to ensure the modal has closed
    if (afterClose) {
      setTimeout(afterClose, 100);
    }
  };

  // Handler for backdrop click
  const handleBackdropClick = () => {
    // Only close the modal if closeOnBackdropClick is true
    if (closeOnBackdropClick) {
      handleCancel();
    }
  };

  // Use custom modal implementation to avoid TypeScript issues with antd Modal
  if (!isVisible) {
    return null;
  }

  const modalContentStyle: React.CSSProperties = {
    width: typeof width === 'number' ? `${width}px` : width,
    ...(centered ? { marginTop: 0 } : { marginTop: '100px' }),
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div
        className="modal-content-wrapper"
        onClick={e => e.stopPropagation()}
        style={modalContentStyle}
      >
        <div className="modal-dark-header">
          <h3 style={{ margin: 0, color: 'white', fontSize: '16px', fontWeight: 500 }}>{title}</h3>
          {closable && (
            <button
              onClick={handleCancel}
              style={{
                position: 'absolute',
                top: 5,
                right: 16,
                background: 'transparent',
                border: 'none',
                color: 'white',
                fontSize: 25,
                cursor: 'pointer',
              }}
            >
              ×
            </button>
          )}
        </div>
        <div className="modal-content">
          {children}
        </div>
        <div className="modal-footer">
          <Button
            onClick={handleCancel}
            type="link"
            style={{
              color: 'red',
              marginRight: 8,
            }}
          >
            {cancelText}
          </Button>
          <Button
            onClick={() => confirmOkMessage ? handleConfirmOk() : handleOk()}
            type="primary"
            {...okButtonProps}
          >
            {okText}
          </Button>
        </div>
      </div>
    </div>
  );
};

PitstopModalDarkHeader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  form: PropTypes.object,
  failureMessage: PropTypes.string,
  notifyMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmOkMessage: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  clearExtraFields: PropTypes.func,
  onCancel: PropTypes.func,
  successMessage: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centered: PropTypes.bool,
  afterClose: PropTypes.func,
  isManualClose: PropTypes.bool,
  cancelButtonProps: PropTypes.object,
  closable: PropTypes.bool,
  okButtonProps: PropTypes.object,
  closeOnBackdropClick: PropTypes.bool,
};

export default observer(PitstopModalDarkHeader);
