import React, { useState, useEffect } from 'react';
import { List, Button, Typography, Tooltip, Icon, Popconfirm, Modal, Select, Spin, message } from 'antd';
import styled from 'styled-components';
import { Logger } from 'stores/Classes';
import { CarStore, ShopStore } from 'stores';

const { Text } = Typography;
const { Option } = Select;

const Container = styled.div`
  padding: 4px;
`;

const TagItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 68%;
  margin-right: auto; /* Align left, reducing space from the right */

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const EditModalHeader = styled.div`
  background: #001f3f; /* Dark navy blue */
  color: white;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 8px; /* Add space between buttons */
  padding: 16px;
  border-top: 1px solid #ddd;
`;

const FinishButton = styled(Button)`
  background: #003366 !important;
  color: white !important;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 36px;

  &:hover {
    background: #002244 !important;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .ant-select-selection-overflow-item {
    max-width: 48%;
    flex: 1 1 48%;
  }
`;

const loadShopTags = async () => {
  const shopId = ShopStore.currentShop.id;
  try {
    const response = await CarStore.fetchTagsByShopId(shopId);
    return response;
  } catch (error) {
    Logger.error(error);
    return { shopDetails: {} , data: [], subLocations: [] };
  }
};

const SamsaraTagsForm = () => {
  const [tags, setTags] = useState([]);
  const [subFleetOptions, setSubFleetOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedSubFleets, setSelectedSubFleets] = useState([]);
  const [initialSubFleets, setInitialSubFleets] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [shopDetails, setShopDetails] = useState({}); 

  const fetchTagsAndSubLocations = async () => {
    setIsLoading(true);
    try {
      const response = await loadShopTags();
      const fetchedTags = Array.isArray(response.data) ? response.data : [];
      const fetchedSubLocations = Array.isArray(response.subLocations) ? response.subLocations : [];

      const formattedTags = fetchedTags.map(tag => ({
        id: tag.id,
        name: tag.tag_name,
        vehicles: tag.vehicle_count,
        trailers: tag.trailer_count,
        subFleets: tag.sub_locations ? tag.sub_locations.map(sub => sub.shop_id) : null, // Store sub_location shop_ids
        sub_locations: tag.sub_locations || [] // Include sub_locations in the formattedTags
      }));
      setTags(formattedTags);

      const subFleetNames = fetchedSubLocations.map(location => ({
        id: location.id,
        name: location.name,
      }));
      setSubFleetOptions(subFleetNames);
      setShopDetails(response.shopDetails || {});
    } catch (error) {
      Logger.error('Error fetching tags or sub-locations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTagsAndSubLocations();
  }, []);

  const handleEditClick = (tag) => {
    setSelectedTag(tag);
    const activeSubFleetNames = tag.sub_locations
      ? tag.sub_locations
          .filter(sub => sub.is_active)
          .map(sub => {
            const subFleet = subFleetOptions.find(option => option.id === sub.shop_id);
            return subFleet ? subFleet.name : null;
          })
          .filter(name => name !== null)
      : [];

    setSelectedSubFleets(activeSubFleetNames);
    setInitialSubFleets(activeSubFleetNames);
    setIsModalVisible(true);
  };

  const handleTagActions = async (tag) => {
    setIsUpdating(true);
    try {
      for (const action of tag) {
        await CarStore.updateTags(action);
      }

      await fetchTagsAndSubLocations();
      message.success('Save successful');
    } catch (error) {
      Logger.error('Error updating tags:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async (tagId) => {
    setIsUpdating(true);
    try {
      await CarStore.deleteTag(tagId);
      await fetchTagsAndSubLocations();
      message.success('Delete successful');
    } catch (error) {
      Logger.error('Error deleting tag:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSave = () => {
    const addedSubFleets = selectedSubFleets.filter(subFleet => !initialSubFleets.includes(subFleet));
    const removedSubFleets = initialSubFleets.filter(subFleet => !selectedSubFleets.includes(subFleet));

    const addedSubFleetDetails = addedSubFleets.map(subFleetName => {
      const subFleet = subFleetOptions.find(option => option.name === subFleetName);
      return subFleet ? { shopId: subFleet.id, tagName: selectedTag.name, action: 'add' } : null;
    }).filter(subFleet => subFleet !== null);

    const removedSubFleetDetails = removedSubFleets.map(subFleetName => {
      const subFleet = subFleetOptions.find(option => option.name === subFleetName);
      return subFleet ? { shopId: subFleet.id, tagName: selectedTag.name, action: 'remove' } : null;
    }).filter(subFleet => subFleet !== null);

    const subFleetDetails = [...addedSubFleetDetails, ...removedSubFleetDetails];

    handleTagActions(subFleetDetails);

    setTags(tags.map(tag => tag.id === selectedTag.id ? { ...tag, subFleets: selectedSubFleets } : tag));
    setIsModalVisible(false);
  };

  return (
    <Container>
      {shopDetails.id_parent_shop && (
        <Text style={{ color: 'black', marginBottom: '14px', display: 'block' }}>
          To edit tags, please go to the main fleet <span style={{ color: '#123FBF'}}>{shopDetails.parent_shop_name}</span>
        </Text>
      )}
      <Spin spinning={isLoading || isUpdating}>
        <List
          dataSource={tags}
          renderItem={(tag) => (
            <TagItem>
              <div>
                <Text style={{ color: '#000000', fontSize: '20px' }}>{tag.name}</Text>
                <br />
                <Text style={{ color: '#000000', fontSize: '14px', opacity: 0.65 }}>{tag.vehicles} Vehicles / {tag.trailers} Trailers</Text>
              </div>
              {!shopDetails.id_parent_shop && (
                <ActionButtons  style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
                  <Tooltip title="Edit">
                    <Button type="link" onClick={() => handleEditClick(tag)}>
                      <Icon type="edit" style={{ color: '#123FBF', fontSize: '14px' }} /> Edit
                    </Button>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure delete this tag?"
                    icon={<Icon type="exclamation-circle" style={{ color: 'orange' }} />}
                    onConfirm={() => handleDelete(tag.id)}
                    okText="Yes"
                    placement="topLeft"
                    cancelText="No"
                  >
                    <Button type="link" danger>
                      <Icon type="delete" style={{ color: '#FF0000', fontSize: '16px' }} />
                    </Button>
                  </Popconfirm>
                </ActionButtons>
              )}
            </TagItem>
          )}
        />
      </Spin>

      {/* Edit Modal */}
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={420}
        centered
        closable={false}
        bodyStyle={{ padding: '0px' }}
      >
        {/* Header */}
        <EditModalHeader>
          <span>Edit Tags</span>
          <Icon type="close" onClick={() => setIsModalVisible(false)} style={{ cursor: 'pointer' }} />
        </EditModalHeader>

        {/* Body */}
        <div style={{ padding: '20px' }}>
          <Text strong style={{ fontSize: '16px' }}>{selectedTag?.name}</Text>
          <br />
          <Text type="secondary">Vehicles & Trailers</Text>
          <br /><br />
          <Text strong>Select Sub-fleet(s):</Text>
          <StyledSelect
            mode="multiple"
            style={{ width: '100%', marginTop: '8px', minHeight: '36px' }}
            placeholder="Select Sub-fleet(s)"
            value={selectedSubFleets}
            onChange={setSelectedSubFleets}
          >
            {subFleetOptions.map(option => (
              <Option key={option.id} value={option.name}>{option.name}</Option>
            ))}
          </StyledSelect>
        </div>

        {/* Footer */}
        <ModalFooter>
          <Button type="link" onClick={() => setIsModalVisible(false)} style={{ color: 'red' }}>
            Cancel
          </Button>
          <FinishButton type="primary" onClick={handleSave}>
            Finish
          </FinishButton>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default SamsaraTagsForm;