import React from 'react';
import { Alert, Spin } from 'antd';
import styled from 'styled-components';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Notification, NotificationType } from '../../stores/NotificationStore';

const StyledNotification = styled.div`
  margin-bottom: 12px;
  width: 400px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  animation: slide-in 0.3s ease-out forwards;

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .ant-alert {
    border-radius: 6px;
    padding: 14px 16px;
    display: flex;
    align-items: flex-start;
  }

  .ant-alert-content {
    flex: 1;
    min-width: 0; /* Allows text to truncate */
  }

  .ant-alert-message {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    padding-right: 24px; /* Make space for close icon */
    word-wrap: break-word;
    padding-left: 1rem;
  }

  .ant-alert-icon {
    font-size: 18px;
    margin-right: 16px;
    position: relative;
    top: 2px;
    flex-shrink: 0;
  }

  .ant-alert .ant-alert-close-icon {
    font-size: 16px;
    margin-right: 0;
    position: absolute;
    top: 14px;
    right: 16px;
    z-index: 100;
  }

  /* Fix for the Spin component alignment */
  .ant-spin {
    margin-right: 8px;
    position: relative;
    top: 4px;
  }
`;

interface NotificationItemProps {
  notification: Notification;
  onClose: (id: string) => void;
}

/* eslint-disable react/prop-types */
const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onClose }) => {
  const getIcon = () => {
    switch (notification.type) {
      case NotificationType.SUCCESS:
        return <CheckCircleOutlined style={{ fontSize: '18px' }} />;
      case NotificationType.ERROR:
        return <CloseCircleOutlined style={{ fontSize: '18px' }} />;
      case NotificationType.WARNING:
        return <ExclamationCircleOutlined style={{ fontSize: '18px' }} />;
      case NotificationType.LOADING:
        return <Spin size="small" />;
      case NotificationType.INFO:
      default:
        return <InfoCircleOutlined style={{ fontSize: '18px' }} />;
    }
  };

  const getAlertType = () => {
    switch (notification.type) {
      case NotificationType.SUCCESS:
        return 'success';
      case NotificationType.ERROR:
        return 'error';
      case NotificationType.WARNING:
        return 'warning';
      case NotificationType.LOADING:
        return 'info';
      case NotificationType.INFO:
      default:
        return 'info';
    }
  };

  return (
    <StyledNotification>
      <Alert
        message={notification.message}
        type={getAlertType()}
        showIcon
        icon={getIcon()}
        closable
        onClose={() => onClose(notification.id)}
      />
    </StyledNotification>
  );
};
/* eslint-enable react/prop-types */

export default NotificationItem;