import styled from 'styled-components';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { customFleets, PitstopTable } from 'shared';
import { PitStopList } from 'shared';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';
import { currencyOrNumber } from 'shared/utils';
import moment from 'moment-timezone';
import {
  AppStore,
  WorkOrderStore,
  TableStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  CarStore,
  ContactStore,
} from 'stores';
import {
  List,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Select,
  Typography,
  Spin,
  notification,
  Button,
  Input,
  Checkbox,
  Popconfirm,
  message,
  Tag,
  Tooltip
} from 'antd';

import {
  WorkOrderRepairPriorityEnum,
  filteredWorkOrderStatusKeys,
} from 'stores/Classes/WorkOrderObject';
import DealershipChooser from 'components/DealershipChooser';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';
import AppAlert from 'components/AppAlert';
import { Logger } from 'stores/Classes';
import WorkOrderStatusByAssignedToWidget from 'components/WorkOrder/WorkOrderStatusByAssignedToWidget';
import FlexRow from 'components/General/FlexRow';
import DeviceStore from 'stores/Classes/DeviceStore';

import { WOCardItemPart } from 'components/WorkOrder/Parts/WOCardItemPart';
import WOFormFilterParts from 'components/WorkOrder/Parts/WOFormFilterParts';
import CustomTableColumn from 'components/Tables/CustomTableColumn';

import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import ContactFormModal from 'components/Contacts/ContactFormModal';
import StatusWrapper from 'components/Vehicles/VehiclesTableComponents/StatusWrapper';
import WorkOrderEditShopStatusesModal from 'components/WorkOrder/WorkOrderEditShopStatusesModal';
import { EventBus } from 'stores/EventBus';

const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const VENDOR_DETAILS_MODAL_ID = 'vendor-details-modal';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ customPadding }) => customPadding || '25px'};
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const DropdownButtonContainer = styled.div`
  .more-button-dropdown {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    i {
      font-size: 1.3rem;
      font-weight: bold;
      color: #000;
    }
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    i {
      font-size: 1rem;
      font-weight: bold;
      color: #000;
    }
  }
`;

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  &.assigned-to-dropdown {
    min-width: 300px;
    width: 300px;
  }
`;

const StyledTableRow = styled.div`
  .ant-table-row {
    cursor: default !important;
  }

  & .ant-table-content {
    transition: max-width 0.3s ease;
    max-width: ${({ isSidebarCollapsed }) => isSidebarCollapsed ? 'calc(100vw - 40px)' : 'calc(100vw - 300px)'} !important;
  }

  .clickable-cell:hover {
    cursor: pointer;
  }

  .status-select {
    font-weight: 500;

    &.open {
      color: #123fbf;
    }

    &.in_progress {
      color: #218730;
    }

    &.pending {
      color: #fe8f44;
    }

    &.blocked_for_parts {
      color: #ff202f;
    }

    &.waiting_for_approval {
      color: #ff202f;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    height: 32px;
    font-size: 14px;
    border-radius: 20px; /* Rounded rectangle */
    font-weight: 500;
    padding: 0 16px; /* Adds proper button padding */

    &.reset-btn {
      color: #000000;
      border: 1px solid #d9d9d9;
      background-color: #ffffff; /* White background for reset */
      margin-right: auto; /* Align Reset to the left */
    }

    &.ok-btn {
      color: #ffffff;
      background-color: #1e90ff; /* Blue color for OK */
      border: none;
      margin-left: auto; /* Align OK to the right */
    }

    &.ok-btn:hover {
      background-color: #3b99fc; /* Darker blue on hover */
    }

    &.reset-btn:hover {
      color: #000000;
      background-color: #f5f5f5; /* Light gray on hover */
    }
  }
`;


class WorkOrderTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    shopId: PropTypes.number,
    isSelectionEnabled: PropTypes.bool,
    getCurrentSelectedPriorities: PropTypes.func,
    columnKeys: PropTypes.array,
    isEditable: PropTypes.bool,
    showSetupButton: PropTypes.bool,
  };

  tableStore = new TableStore();
  usersTableStore = new TableStore();
  carsTableStore = new TableStore();

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    // reset table
    this.tableStore.reset();
    // reset assigned and shop related filters
    this.setState(
      {
        loadedShopWorkOrderStatuses: false,
        currentAssignedToFilters: [],
        workOrdersGroupedByStatusAndAssignedUser: [],
      },
      async () => {
        // remove columns filters if any
        this.allColumns = this.allColumns.map((column) => {
          if (column.key === 'assigned_to') {
            return { ...column, filters: [], filteredValue: [] };
          }
          return column;
        });

        // Add invoice_number_customer column just after the invoice_number column if UFP Fleet
        this.showOrHideInvoiceNumberCustomerColumn();

        await this.loadShopUsers();
        await this.loadWorkOrdersGroupedByStatusAndAssignedUser();
        await this.loadVendors();
        await this.loadShopWorkOrderStatuses();
        this.setState({
          vendorsFilterOptions: this.state.vendors.map((vendor) => ({
            text: vendor.name,
            value: vendor.id,
          }))
        });
      }
    );
  });

  componentWillUnmount () {
    const { device } = DeviceStore;
    this.setState({
      isMobile: device === 'mobile' || device === 'tablet' ? true : false,
    });
    this.disposer();
    EventBus.$off('WORK_ORDER_SHOP_STATUSES_UPDATED', this.loadShopWorkOrderStatusesUpdatedCb);
    EventBus.$off('dashboardWrapper/SIDEBAR_TOGGLED', this.sidebarToggledCb);
  }

  get shopCars () {
    const cars = _.map(this.carsTableStore.data, (id) => CarStore.data.get(id));
    return cars;
  }

  state = {
    isMobile: false,
    loadedShopWorkOrderStatuses: false,
    allowManager: true,
    isSidebarCollapsed: false,
    data: [],
    vendors: [],
    vendorsFilterOptions: [],
    currentVendorFilters: [],
    shopWorkOrderStatuses: [],
    dropdownVisible: false,
    currentWorkOrderStatusDropdownVisibility: {},
    vendorDetailsModalVisible: false,
    selectedVendor: null,
    savingVendor: false,
    expandedCards: {},
    workOrdersGroupedByStatusAndAssignedUser: [],
    selectedWorkOrders: undefined,
    selectedWorkOrdersIds: [],
    timezone: 'America/New_York',
    loadingInitial: true,
    currentSearch: '',
    currentFilters: [],
    filterByStatusDropdownVisible: false,
    shopUsers: [],
    filterByAssignedToDropdownVisible: false,
    currentAssignedToFilters: [],
    currentAssignedToFilterOptions: [],
    customColumns: [],
    popoverVisible: false,
    countFiltersSelected: 0,
    selectedRowKeys: [],
  };

  get shopId () {
    return ShopStore.currentShop.id;
  }

  get currentFiltersApplied () {
    return [
      ...this.state.currentFilters,
      ...this.state.currentAssignedToFilters,
      ...this.state.currentVendorFilters,
    ];
  }

  openVendorDetailsModal = (vendor) => {
    this.setState({ selectedVendor: vendor }, () => {
      AppStore.openModals.set(VENDOR_DETAILS_MODAL_ID, true);
    });
  };

  closeVendorDetailsModal = () => {
    this.setState({ selectedVendor: null }, () => {
      AppStore.openModals.set(VENDOR_DETAILS_MODAL_ID, false);
    });
  };

  loadShopUsers = async () => {
    const shopUsers = await UserStore.fetchUsersForTable(
      {
        offset: 0,
        limit: 100,
        filter: null,
      },
      this.usersTableStore
    );

    const filterOptions = shopUsers.map((user) => ({
      text:
        `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email,
      value: user.userId,
    }));

    this.setState({
      shopUsers,
      currentAssignedToFilterOptions: _.uniqBy(filterOptions, 'value'),
    });
  };

  convertWorkOrdersGroupedToDesiredFormat = (workOrderGrouped) => {
    const formattedData = _.map(
      workOrderGrouped,
      (workOrdersAssignedToUser, userId) => {
        const user = _.find(this.state.shopUsers, { userId: parseInt(userId) });
        const fullName = user
          ? `${user.firstName} ${user.lastName}`
          : 'Unknown';

        const statusCounts = workOrdersAssignedToUser.reduce(
          (acc, workOrder) => {
            if (!acc[workOrder.status]) {
              acc[workOrder.status] = 0;
            }
            acc[workOrder.status] += parseInt(workOrder.count);
            return acc;
          },
          {}
        );

        const defaultStatusCounts = this.state.shopWorkOrderStatuses.map(
          (status) => {
            return {
              [status.key]: 0,
            };
          }
        );

        const combinedCounts = { ...defaultStatusCounts, ...statusCounts };

        const total = _.reduce(
          combinedCounts,
          (acc, value) => {
            return acc + value;
          },
          0
        );

        return {
          key: _.uniqueId(),
          assignedToId: parseInt(userId),
          assignedToName: fullName,
          ...combinedCounts,
          total: total,
        };
      }
    );

    // Sorting based on the total number of tasks
    return formattedData.sort((a, b) => b.total - a.total);
  };

  loadWorkOrdersGroupedByStatusAndAssignedUser = async () => {
    const workOrdersGroupedByStatusAndAssignedUser = await WorkOrderStore.workOrdersGroupedByStatusAndAssignedUser(
      ShopStore.currentShop.id
    );

    const workOrdersGroupedByUser = _.groupBy(
      workOrdersGroupedByStatusAndAssignedUser,
      'assignedTo'
    );

    const formatedWorkOrdersGrouped = this.convertWorkOrdersGroupedToDesiredFormat(
      workOrdersGroupedByUser
    );

    this.setState({
      workOrdersGroupedByStatusAndAssignedUser: formatedWorkOrdersGrouped,
    });
  };

  loadShopCars = async () => {
    await CarStore.getShopCarsForTable(
      {
        offset: 0,
        limit: 500,
        filter: null,
      },
      this.carsTableStore
    );
  };

  loadShopWorkOrderStatusesUpdatedCb = () => {
    this.loadShopWorkOrderStatuses();
  };

  sidebarToggledCb = (collapsed) => {
    this.setState({ isSidebarCollapsed: collapsed });
  };

  async componentDidMount () {
    EventBus.$on('WORK_ORDER_SHOP_STATUSES_UPDATED', this.loadShopWorkOrderStatusesUpdatedCb);
    EventBus.$on('dashboardWrapper/SIDEBAR_TOGGLED', this.sidebarToggledCb);

    await this.load();

    // if the query params are present, update the state with the current filters applied
    const queryParams = new URLSearchParams(
      localStorage.getItem('workOrderFilters')
    );
    const formattedCurrentFilters = (stringKeys) =>
      stringKeys?.split(',').map((key) => ({
        key,
        label:
          this.state.shopWorkOrderStatuses.find(
            (option) => String(option.key) === String(key)
          )?.name || key,
      }));
    const formattedAssignedToFilters = (stringKeys) =>
      stringKeys?.split(',').map((key) => ({
        key,
        label:
          this.state.currentAssignedToFilterOptions.find(
            (option) => String(option.value) === String(key)
          )?.text || key,
      }));
    const formattedVendorFilters = (stringKeys) =>
      stringKeys?.split(',').map((key) => ({
        key,
        label:
          this.state.vendors.find(
            (option) => String(option.id) === String(key)
          )?.name || key,
      }));
    this.setState(
      {
        currentFilters: queryParams.get('status')
          ? formattedCurrentFilters(queryParams.get('status'))
          : [],
        currentAssignedToFilters: queryParams.get('assignedTo')
          ? formattedAssignedToFilters(queryParams.get('assignedTo'))
          : [],
        currentVendorFilters: queryParams.get('vendor')
          ? formattedVendorFilters(queryParams.get('vendor'))
          : [],
        currentSearch: queryParams.get('search') || '',
      },
      () => {
        // update the table with the current filters applied
        this.onChangeCurrentFilters();
      }
    );

    this.loadWorkOrdersGroupedByStatusAndAssignedUser();

    const data = await ShopStore.getColumns('work-order');

    if (data) {
      this.setState({
        customColumns: data.columns,
      });
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    let { currentSearch, currentAssignedToFilterOptions } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChange();
    }

    if (
      currentAssignedToFilterOptions !==
      prevState.currentAssignedToFilterOptions
    ) {
      this.allColumns = this.allColumns.map((column) => {
        if (column.key === 'assigned_to') {
          return {
            ...column,
            filters: currentAssignedToFilterOptions,
          };
        }
        return column;
      });
    }

    if (
      currentSearch !== prevState.currentSearch ||
      this.state.currentFilters !== prevState.currentFilters ||
      this.state.currentAssignedToFilters !== prevState.currentAssignedToFilters ||
      this.state.currentVendorFilters !== prevState.currentVendorFilters
    ) {
      // update the query params with the current filters applied
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(
        'status',
        this.state.currentFilters.map((filter) => filter.key)
      );
      queryParams.set(
        'assignedTo',
        this.state.currentAssignedToFilters.map((filter) => filter.key)
      );
      queryParams.set(
        'vendor',
        this.state.currentVendorFilters.map((filter) => filter.key)
      );
      queryParams.set('search', currentSearch);

      // save it to local storage
      localStorage.setItem('workOrderFilters', queryParams.toString());

      this.props.history.push({
        search: queryParams.toString(),
      });
    }
  }

  searchDebouncer = null;

  // filterOptions = [];

  fileTransferOptions = {
    NO_FILE: { label: 'No File', color: 'default' },
    IN_PROGRESS: { label: 'In Progress', color: 'orange' },
    COMPLETED: { label: 'Complete', color: 'green' },
    FAILED: { label: 'Failed', color: 'red' },
  };

  fileTransferFilterOptions = Object.keys(this.fileTransferOptions).map((key) => ({
    text: this.fileTransferOptions[key].label,
    value: key,
  }));


  handleSearchChange = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.tableStore.reset();
    }, 500);
  };

  load = async () => {
    this.setState({ loadingInitial: true });
    await this.loadShopUsers();
    await this.loadShopWorkOrderStatuses();
    await this.loadVendors();
    this.showOrHideInvoiceNumberCustomerColumn();
    this.setState({
      vendorsFilterOptions: this.state.vendors.map((vendor) => ({
        text: vendor.name,
        value: vendor.id,
      }))
    });
    // await this.loadShopCars();
    this.setState({ loadingInitial: false });
  };

  loadVendors = async () => {
    const vendors = await ContactStore.getShopContacts(ShopStore.currentShop.id) || [];
    this.setState({ vendors });
  };

  showOrHideInvoiceNumberCustomerColumn = () => {
    // Add invoice_number_customer column just after the invoice_number column if UFP Fleet
    if (customFleets.ufpFleet.includes(ShopStore.currentShop.id)) {
      // check if invoice_number_customer is already in the columns
      if (this.allColumns.findIndex(column => column.key === 'invoice_number_customer') === -1) {
        const indexOfInvoiceNumber = this.allColumns.findIndex(column => column.key === 'invoice_number');
        this.allColumns = [...this.allColumns.slice(0, indexOfInvoiceNumber + 1), {
          title: 'Invoice #',
          key: 'invoice_number_customer',
          dataIndex: 'invoice_number_customer',
          sorter: (a, b) => a.invoice_number_customer - b.invoice_number_customer,
          render: (text, record) => {
            return text ? text : '-';
          },
        }, ...this.allColumns.slice(indexOfInvoiceNumber + 1)];
      }
    } else {
      this.allColumns = this.allColumns.filter(column => column.key !== 'invoice_number_customer');
    }
  };

  loadShopWorkOrderStatuses = async (retryTimes = 0) => {
    try {
      this.setState({ loadedShopWorkOrderStatuses: false });
      const shopWorkOrderStatuses = await WorkOrderStore.getShopWorkOrderStatuses(ShopStore.currentShop.id);
      this.setState({
        shopWorkOrderStatuses: _.chain(shopWorkOrderStatuses)
          .get('data', [])
          .map((status) => ({
            id: status.id,
            key: status.key,
            name: status.description,
            description: status.description,
            color: status.color,
            text: status.description,
            value: status.key,
            is_default: status.is_default,
          }))
          .value()
      });
      this.setState({ loadedShopWorkOrderStatuses: true });
    } catch (error) {
      Logger.error(error);
      if (retryTimes < 3) {
        return this.loadShopWorkOrderStatuses(retryTimes + 1);
      }
      AppStore.addError('Failed to load shop work order statuses');
      this.setState({ loadedShopWorkOrderStatuses: true });
    }
  };

  fetchData = (filters, table) => {
    let { currentSearch } = this.state;
    if (_.isNil(filters)) {
      let { offset, limit, sort, filter } = this.tableStore;

      filters = { offset, limit, sort, filter };
    }

    // Set Sort by last created as default
    filters.sort = filters.sort ? filters.sort : '-created_by';

    if (currentSearch && currentSearch.length > 0) {
      filters.filter = {
        ...filters.filter,
        search: currentSearch,
      };
    }

    if (this.state.currentFilters.length > 0) {
      filters.filter = {
        ...filters.filter,
        status: this.state.currentFilters.map((filter) => filter.key),
      };
    }

    if (this.state.currentAssignedToFilters.length > 0) {
      filters.filter = {
        ...filters.filter,
        assignedTo: this.state.currentAssignedToFilters.map(
          (filter) => filter.key
        ),
      };
    }

    if (this.state.currentVendorFilters.length > 0) {
      filters.filter = {
        ...filters.filter,
        vendor: this.state.currentVendorFilters.map((filter) => filter.key),
      };
    }

    WorkOrderStore.fetchWorkOrdersForTable(
      filters,
      table,
      ShopStore.currentShop.id,
      false
    );
  };

  handleMenuClick = async ({ key, domEvent }, row) => {
    domEvent.stopPropagation();
    domEvent.preventDefault();

    switch (key) {
      case '1':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.printWorkOrder(row.id);
        } catch (error) {
          Logger.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      case '2':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.downloadWorkOrder(row.id);
        } catch (error) {
          Logger.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      default:
        break;
    }
  };

  handleStatusChange = async (newStatus, workOrder) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: workOrder.id,
        status: newStatus,
      }, {
        abortPrevious: false,
      });

      // Check if we need to Resolve a DVIR
      if (
        newStatus === 'resolved' &&
        workOrder.type === 'driver_identified' &&
        workOrder.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = workOrder.work_order_dvirs[0].dvir_id;
        workOrder.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }

      notification.success({
        message: 'Status updated',
        key: `statusUpdated${moment().format('x')}`,
        description: <Container>The work order status was updated.</Container>,
      });
      this.loadWorkOrdersGroupedByStatusAndAssignedUser();
      return response;
    } catch (error) {
      Logger.error(error);
      let friendlyMsg =
        "We couldn't update the Work Order status at this moment, please try again later";
      return notification.error({
        message: 'Oops!',
        key: `statusUpdateError${moment().format('x')}`,
        description: <Container>{friendlyMsg}</Container>,
      });
    }
  };

  openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  get data () {
    let data = _.map(this.tableStore.data, (id) => WorkOrderStore.data.get(id));

    return _.filter(data, Boolean);
  }

  filteredInfo = {};

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      status: [],
      assignedTo: [],
      vendor: [],
    };
    this.state.currentFilters.forEach((filter) => {
      filters['status'].push(filter.key);
    });
    this.state.currentAssignedToFilters.forEach((filter) => {
      filters['assignedTo'].push(filter.key);
    });
    this.state.currentVendorFilters.forEach((filter) => {
      filters['vendor'].push(filter.key);
    });
    this.setState({
      filteredInfo: filters,
      countFiltersSelected: filters.status.length + filters.assignedTo.length + filters.vendor.length,
    });

    // update the allColumns
    this.allColumns = this.allColumns.map((column) => {
      if (column.key === 'status') {
        return {
          ...column,
          filters: this.state.shopWorkOrderStatuses,
          filteredValue: filters['status'],
        };
      }
      if (column.key === 'assigned_to') {
        return {
          ...column,
          filters: this.state.currentAssignedToFilterOptions,
          filteredValue: filters['assignedTo'],
        };
      }
      if (column.key === 'vendor_id') {
        return {
          ...column,
          filters: this.state.vendorsFilterOptions,
          filteredValue: filters['vendor'],
        };
      }
      return column;
    });
    this.tableStore.reset();
  };

  handleStatusFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentFilters: selectedKeys.map((key) => {
          const status = this.state.shopWorkOrderStatuses.find((s) => s.key === key);
          return {
            key: key,
            label: status?.name || key,
          };
        }),
        filterByStatusDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleAssignedToFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentAssignedToFilters: selectedKeys.map((key) => {
          const user = this.state.currentAssignedToFilterOptions.find(
            (u) => u.value === key
          );
          return {
            key: key,
            label: user?.text || key,
          };
        }),
        filterByAssignedToDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleVendorFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentVendorFilters: selectedKeys.map((key) => ({
          key: key,
          label: this.state.vendorsFilterOptions.find((v) => v.value === key)?.text || key,
        })),
        filterByVendorDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleReset = (clearFilters, type = 'status') => {
    clearFilters();
    if (type === 'assignedTo') {
      this.setState(
        {
          currentAssignedToFilters: [],
          filterByAssignedToDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
    if (type === 'vendor') {
      this.setState(
        {
          currentVendorFilters: [],
          filterByVendorDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
    this.setState(
      {
        currentFilters: [],
        filterByStatusDropdownVisible: false,
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  handleAssignedToChange = async (newAssignedTo, row) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: row.id,
        assigned_to: newAssignedTo,
      });
      notification.success({
        message: 'Assigned to updated',
        key: `assignedToUpdated${moment().format('x')}`,
        description: (
          <Container>The work order assigned to was updated.</Container>
        ),
      });
      this.loadWorkOrdersGroupedByStatusAndAssignedUser();
      return response;
    } catch (error) {
      Logger.error(error);
      let friendlyMsg =
        'We could not update the Work Order assigned to at this moment, please try again later';
      return notification.error({
        message: 'Oops!',
        key: `assignedToUpdateError${moment().format('x')}`,
        description: <Container>{friendlyMsg}</Container>,
      });
    }
  };

  toggleWorkOrderStatusDropdownVisible = (workOrderId, visible) => {
    this.setState((prevState) => ({
      currentWorkOrderStatusDropdownVisibility: {
        ...prevState.currentWorkOrderStatusDropdownVisibility,
        [workOrderId]: visible,
      },
    }));
  };

  stopPropagation = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.domEvent?.stopPropagation) {
      e.domEvent.stopPropagation();
    }
  };

  // handleStatusChange
  notificationKey = 'statusChange';
  onClickNewWorkOrderStatus = async (e, status, workOrder) => {
    this.stopPropagation(e);

    this.toggleWorkOrderStatusDropdownVisible(workOrder.id, false);

    // update the work order status
    try {
      notification.close(this.notificationKey);
      notification.open({
        key: this.notificationKey,
        message: '',
        description: <LoadingContainer customPadding='10px'><Spin tip='Updating status...' /></LoadingContainer>,
      });
      const newStatus = status.key;
      // force the front-end to change the status
      workOrder.status = newStatus;
      await this.handleStatusChange(newStatus, workOrder);
    } catch (error) {
      AppStore.addError('Failed to update status, please try again.');
      Logger.error(error);
      return;
    } finally {
      notification.close(this.notificationKey);
    }
  };

  renderColumnStatusDropdown = (workOrder) => {
    if (!this.state.loadedShopWorkOrderStatuses) {
      return (
        <StatusWrapper>
          <Spin />
        </StatusWrapper>
      );
    }

    // Determine the current car status
    let currentWorkOrderStatus = this.state.shopWorkOrderStatuses.find(
      (status) => status.key === workOrder.status
    ) || {
      key: workOrder.status,
      description: 'Unknown',
      color: 'grey',
    };

    const DropButton = styled(Button)`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: white;
      border-color: ${({ color }) => color || 'inherit'};
      color: ${({ color }) => color || 'inherit'};
      padding: 5px 10px;
      border-radius: 6px;
    `;

    return (
      <StatusWrapper margin={'5px'}>
        <Dropdown
          trigger={['click']}
          visible={this.state.currentWorkOrderStatusDropdownVisibility[workOrder.id]}
          onVisibleChange={
            (flag) => this.toggleWorkOrderStatusDropdownVisible(workOrder.id, flag)
          }
          overlay={
            <Menu
              className="status-menu"
              onClick={(e) => e.domEvent?.stopPropagation()}
            >
              {this.state.shopWorkOrderStatuses.map((status) => (
                <Menu.Item
                  onClick={(e) => this.onClickNewWorkOrderStatus(e, status, workOrder)}
                  className="menu-item"
                  key={status.key}
                >
                  <Button
                    onClick={(e) =>
                      this.onClickNewWorkOrderStatus(e, status, workOrder)
                    }
                    className="btn-status"
                    ghost
                    style={{
                      color: status.color,
                      backgroundColor: status.color,
                      borderColor: status.color,
                    }}
                  >
                    {status.description}
                  </Button>
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                onClick={this.stopPropagation}
                className="menu-item"
                key="actions"
              >
                <Button
                  block
                  icon="edit"
                  type="primary"
                  onClick={() => {
                    // hide the dropdown
                    this.toggleWorkOrderStatusDropdownVisible(workOrder.id, false);
                    // open the modal
                    AppStore.openModals.set(WorkOrderStore.WORK_ORDER_EDIT_SHOP_STATUSES_MODAL, true);
                  }}
                >
                  Edit Statuses
                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <DropButton
            onClick={(e) => e.stopPropagation()}
            color={currentWorkOrderStatus.color}
            loading={workOrder.isLoadingStatus}
          >
            {currentWorkOrderStatus.description} <Icon type="down" />
          </DropButton>
        </Dropdown>
      </StatusWrapper>
    );
  };

  renderStatusFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.state.shopWorkOrderStatuses.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.key}>
              <Checkbox
                checked={selectedKeys.includes(filter.key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.key]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.key)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleStatusFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderFileTransferFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* Checkbox list for filter options */}
      <div className="filter-dropdown-list">
        {this.fileTransferFilterOptions.map((filter) => (
          <div className="filter-dropdown-list-item" key={filter.value}>
            <Checkbox
              checked={selectedKeys.includes(filter.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, filter.value]
                  : selectedKeys.filter((key) => key !== filter.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {filter.text}
            </Checkbox>
          </div>
        ))}
      </div>
      {/* Action buttons */}
      <div className="filter-dropdown-actions">
        <StyledButton
          size="small"
          className="reset-btn"
          onClick={() => {
            clearFilters();
            this.handleReset(clearFilters, 'fileTransfer');
          }}
        >
          Reset
        </StyledButton>
        <StyledButton
          size="small"
          className="ok-btn"
          type="primary"
          onClick={() => this.handleStatusFilterChange(selectedKeys, confirm)}
        >
          OK
        </StyledButton>
      </div>
    </FilterDropdown>
  );

  renderAssignedToFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="assigned-to-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {this.state.currentAssignedToFilterOptions.map((user) => {
            return (
              <div className="filter-dropdown-list-item" key={user.value}>
                <Checkbox
                  checked={selectedKeys.includes(user.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, user.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== user.value)
                      );
                    }
                  }}
                >
                  {user.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() => this.handleReset(clearFilters, 'assignedTo')}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleAssignedToFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  renderVendorFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="assigned-to-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {this.state.vendorsFilterOptions.map((vendor) => {
            return (
              <div className="filter-dropdown-list-item" key={vendor.value}>
                <Checkbox
                  checked={selectedKeys.includes(vendor.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, vendor.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== vendor.value)
                      );
                    }
                  }}
                >
                  {vendor.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() => this.handleReset(clearFilters, 'vendor')}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleVendorFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  allColumns = [
    {
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      editable: false,
      title: 'Order #',
      required: true,
      render: (wo, row) => {
        return (
          <div
            className="clickable-cell"
            onClick={() =>
              this.props.history.push(`/work-order/${row.id}/edit`)
            }
          >
            <Button type="link">
              {`#${row.w_ord_id ? row.w_ord_id : wo}`}
            </Button>
          </div>
        );
      },
    },
    {
      key: 'car_name',
      dataIndex: 'car_name',
      sorter: true,
      editable: false,
      title: 'Unit ID',
      required: true,
      render: (id_car, row) => {
        return <>{row.car_name || 'N/A'}</>;
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      editable: false,
      title: 'Status',
      filters: this.state.shopWorkOrderStatuses,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByStatusDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderStatusFilterDropdown,
      filteredValue: this.filteredInfo.status || '',
      // onFilter: (value, record) => record.status.includes(value),
      required: true,
      render: (status, row) => {
        return this.renderColumnStatusDropdown(row)
      },
    },
    {
      dataIndex: 'events.current_status', // Accessing nested property
      key: 'current_status',
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="If you're using another work order software integrated with Pitstop, we'll sync its work orders with those in Pitstop">
            <Icon
              type="info-circle"
              style={{
                color: '#808080', // Gray color for the icon
                marginRight: 8, // Space between the icon and the text
              }}
            />
          </Tooltip>
          <span>File Transfer</span>
        </div>
      ),
      sorter: false,
      filters: this.fileTransferFilterOptions,
      filterMultiple: true, // Allows multi-selection in the dropdown
      onFilter: (value, record) => record.events?.current_status === value,
      render: (fileTransferStatus) => {

        if (!fileTransferStatus) {
          fileTransferStatus = "NO_FILE";
        }

        const defaultStyle = {
          backgroundColor: '#ffffff',
          color: '#000000',
          border: '1px solid #d9d9d9',
        };

        const option = this.fileTransferOptions[fileTransferStatus] || {
          label: 'No File',
          color: 'gray',
        };

        let customStyle = {};

        // Define custom styles based on status
        switch (fileTransferStatus) {
          case 'NO_FILE':
            customStyle = {
              backgroundColor: '#FAFAFA',
              color: '#000000',
              border: '1px solid #D9D9D9',
            };
            break;
          case 'IN_PROGRESS':
            customStyle = {
              backgroundColor: '#FFFBE6',
              color: '#FAAD14',
              border: '1px solid #FFE58F',
            };
            break;
          case 'COMPLETED':
            customStyle = {
              backgroundColor: '#f6ffed',
              color: '#52c41a',
              border: '1px solid #b7eb8f',
            };
            break;
          case 'FAILED':
            customStyle = {
              backgroundColor: '#fff2f0',
              color: '#f5222d',
              border: '1px solid #ffa39e',
            };
            break;
          default:
            customStyle = defaultStyle; // Default to "No File" style
        }

        if (fileTransferStatus === 'FAILED') {
          return (
            <Tooltip title="Unable to scan due to poor image quality or obstructions. Please manually enter details from the attached image">
              <Tag style={customStyle}>
                <span>
                  <Icon type="info-circle" style={{ color: '#f5222d', marginRight: '8px' }} />
                  {option.label}
                </span>
              </Tag>
            </Tooltip>
          );
        }

        return <Tag style={customStyle}>{option.label}</Tag>;
      },
    },
    {
      key: 'priority',
      sorter: true,
      dataIndex: 'priority',
      editable: false,
      title: 'Repair Priority',
      required: true,
      render: (repairPriority) => {
        return WorkOrderRepairPriorityEnum[repairPriority]
          ? WorkOrderRepairPriorityEnum[repairPriority]
          : 'N/A';
      },
    },
    {
      dataIndex: 'work_order_lines',
      key: 'work_order_lines',
      editable: false,
      title: 'Service Tasks',
      required: true,
      render: (woLines) => {
        const getTitle = (woLine) => {
          const hasVmrsInfo = woLine.vmrs_asm_code && woLine.vmrs_asm_text;
          let title;
          if (hasVmrsInfo) {
            title = `${woLine.vmrs_asm_code} - ${woLine.vmrs_asm_text}`;
          } else {
            title = _.get(woLine, 'title', '');
          }
          return title;
        };
        return woLines && woLines.length ? (
          <List
            size="small"
            dataSource={woLines ? woLines.map(getTitle) : []}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ) : (
          'N/A'
        );
      },
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      editable: false,
      sorter: true,
      title: 'Created At',
      required: true,
      render: (wo, row) => {
        const user = this.state.shopUsers.find((u) => u.userId === row.created_by);
        return (
          <Row>
            <Col span={24}>
              <Text>{row.created_at ? moment(row.created_at).format('ll') : '--'}</Text>
            </Col>
            {user && (
              <Col span={24}>
                {`${user.firstName} ${user.lastName}`.trim() || user.email}
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      key: 'started_at',
      dataIndex: 'started_at',
      editable: false,
      sorter: true,
      title: 'Started At',
      required: true,
      render: (wo, row) => {
        return (
          <Row>
            <Col span={24}>
              <Text>{row.started_at ? moment(row.started_at).format('ll') : '--'}</Text>
            </Col>
          </Row>
        );
      },
    },
    {
      key: 'assigned_to',
      dataIndex: 'assigned_to',
      sorter: true,
      editable: false,
      title: 'Assigned To',
      filters: this.state.currentAssignedToFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByAssignedToDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderAssignedToFilterDropdown,
      // filterDropdownVisible: this.state.filterByAssignedToDropdownVisible,
      required: true,
      render: (wo, row) => {
        const options = this.state.shopUsers
          .map((user) => ({
            key: user.userId,
            value:
              `${user.firstName || ''} ${user.lastName || ''}`.trim() ||
              user.email,
          }))
          .map((userMapped) => {
            return (
              <Option key={userMapped.key} value={userMapped.key}>
                {userMapped.value}
              </Option>
            );
          });
        return (
          <Select
            defaultValue={wo}
            style={{ width: 180 }}
            onChange={(evt) => this.handleAssignedToChange(evt, row)}
            onClick={(evt) => evt.stopPropagation()}
          >
            {options}
          </Select>
        );
      },
    },
    {
      key: 'vendor_id',
      dataIndex: 'vendor_id',
      sorter: true,
      editable: false,
      title: 'Vendor',
      required: true,
      render: (wo, row) => {
        // if no vendor, render a '-' instead of N/A
        const vendor = this.state.vendors.find((v) => v.id === row.vendor_id);
        if (!vendor) {
          return 'N/A';
        }
        // render a Link Button to open the vendor details on a modal
        // with a icon on the right
        return (
          <Button type="link" onClick={() => this.openVendorDetailsModal(vendor)}>
            {vendor.name}
            <ExpandIcon style={{ marginLeft: 8 }} />
          </Button>
        );
      },
      align: 'center',
      filters: this.state.vendorsFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByVendorDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderVendorFilterDropdown,
    },
    {
      dataIndex: 'total_costs',
      key: 'total_costs',
      editable: false,
      title: 'Total Cost',
      required: true,
      render: (totalCosts, row) => {
        return currencyOrNumber(_.get(row, 'costsTotal', 0));
        // return getWorkOrderTotalCostWithTaxAndMisc({
        //   workOrderLines: row.work_order_lines,
        //   taxObj: { tax: row.tax || 0, type: row.tax_type || 'percentage' },
        //   miscObj: { misc: row.misc || 0, type: row.misc_type || 'percentage' },
        //   applyCurrency: true,
        //   workOrder: row,
        // });
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      editable: false,
      title: '',
      required: false,
      render: (_, row) => {
        const menu = (
          <Menu onClick={(evt) => this.handleMenuClick(evt, row)}>
            <Menu.Item key="1">
              <Icon type="printer" />
              Print work order
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="download" />
              Download work order
            </Menu.Item>
          </Menu>
        );
        return (
          <FlexRow>
            <Dropdown overlay={menu}>
              <DropdownButtonContainer>
                <Button
                  shape="circle"
                  size="small"
                  className="more-button-dropdown"
                >
                  <Icon type="more" />
                </Button>
              </DropdownButtonContainer>
            </Dropdown>
            {CurrentUserStore.allowManager && (
              <Popconfirm
                title="Are you sure you want to delete this work order?"
                onConfirm={() => this.onDelete(row)}
              >
                <DeleteButton shape="circle">
                  <Icon type="delete" />
                </DeleteButton>
              </Popconfirm>
            )}
          </FlexRow>
        );
      },
    },
  ];

  transformColumnsToNotEditable = (rows, propIsEditable) => {
    const isEditable = _.isNil(propIsEditable) ? true : propIsEditable;
    if (isEditable === false) {
      return _.map(rows, (row) => {
        return { ...row, editable: false };
      });
    } else {
      return rows;
    }
  };

  generateColumns = (propsColumnKeys) => {
    if (_.isEmpty(propsColumnKeys)) {
      return this.transformColumnsToNotEditable(
        this.allColumns,
        this.props.isEditable
      );
    }
    const filteredColumns = _.filter(this.allColumns, (column) => {
      return (
        _.includes(propsColumnKeys, column.key) ||
        _.includes(propsColumnKeys, column.dataIndex)
      );
    });
    return this.transformColumnsToNotEditable(
      filteredColumns,
      this.props.isEditable
    );
  };

  onEdit = async ({ newValue: updatedUser }) => {
    if (updatedUser) {
      await WorkOrderStore.updateWorkOrder(updatedUser.id, updatedUser);
    }

    this.setState({
      selectedWorkOrdersIds: [updatedUser.id],
    });
  };

  onDelete = async (workOrder) => {
    AppStore.addLoading('Deleting Work Order');
    await WorkOrderStore.deleteWorkOrder(workOrder.id);
    AppStore.removeLoading('Deleting Work Order');
    message.success('Work Order deleted successfully');
    this.tableStore.reset();
  };

  onSelectWorkOrders = (workOrderIds) => {
    this.setState({
      selectedWorkOrdersIds: workOrderIds,
    });
  };

  handlerCustomColumnTable = async (data) => {
    try {
      const settings = await ShopStore.getShopSettings(this.shopId);

      let tables = _.get(settings.result, '[0].settings.table', []);

      let currentTable = _.find(tables, { name: data.name });

      if (currentTable) {
        currentTable.columns = data.columns;
      } else {
        tables.push(data);
      }

      _.set(settings.result, '[0].settings.table', tables);

      await ShopStore.upsertShopSettings(
        this.shopId,
        settings.result[0].settings
      );

      this.setState({
        customColumns: data.columns,
      });
    } catch (e) { }
  };

  toggleCardExpansion = (invoice_number) => {
    this.setState((prevState) => ({
      expandedCards: {
        ...prevState.expandedCards,
        [invoice_number]: !prevState.expandedCards[invoice_number],
      },
    }));
  };

  handleChangeFormFilter = (key, value) => {
    if (key === 'status') {
      this.setState({
        currentFilters: this.state.shopWorkOrderStatuses
          .filter((item) => value.includes(item.key))
          .map((item) => {
            return {
              key: item.key,
              label: item.name,
            };
          }),
      });
    }

    if (key === 'assignedTo') {
      this.setState({
        currentAssignedToFilters: this.state.currentAssignedToFilterOptions
          .filter((item) => value.includes(item.value))
          .map((item) => {
            return {
              key: item.value,
              label: item.text,
            };
          }),
      });
    }

    if (key === 'vendor') {
      this.setState({
        currentVendorFilters: this.state.vendors
          .filter((item) => value.includes(item.id))
          .map((item) => {
            return {
              key: item.id,
              label: item.name,
            };
          }),
      });
    }

    this.onChangeCurrentFilters();
  };

  togglePopoverVisibility = (visible) => {
    this.setState({ popoverVisible: visible });
  };

  onClearAllFilters = () => {
    this.setState({
      currentFilters: [],
      currentAssignedToFilters: [],
      currentVendorFilters: [],
    });

    this.onChangeCurrentFilters();
  };

  handlerCustomColumnTable = async (data) => {
    try {
      AppStore.addLoading('Setting Table Columns');
      const settings = await ShopStore.getShopSettings(this.shopId);

      let tables = _.get(settings.result, '[0].settings.table', []);

      let currentTable = _.find(tables, { name: data.name });

      if (currentTable) {
        currentTable.columns = data.columns;
      } else {
        tables.push(data);
      }

      _.set(settings.result, '[0].settings.table', tables);

      await ShopStore.upsertShopSettings(
        this.shopId,
        settings.result[0].settings
      );

      this.setState({
        customColumns: data.columns,
      });
      AppStore.addSuccess('Saved successfully!');
    } catch (e) {
    } finally {
      AppStore.removeLoading('');
    }
  };

  handlerResetCustomColumnTable = async (data) => {
    try {
      AppStore.addLoading('Setting Table Columns');
      const settings = await ShopStore.getShopSettings(this.shopId);

      let tables = _.get(settings.result, '[0].settings.table', []);

      let currentTableIndex = _.findIndex(tables, { name: data.name });
      if (currentTableIndex !== -1) {
        tables.splice(currentTableIndex, 1);
        _.set(settings.result, '[0].settings.table', tables);
        await ShopStore.upsertShopSettings(
          this.shopId,
          settings.result[0].settings
        );
        this.setState((prevState) => ({
          customColumns: [],
        }));
      } else {
        AppStore.addError('Unable to make the change, please try again!');
      }
    } catch (e) {
    } finally {
      AppStore.removeLoading('');
    }
  };

  getWorkOrderLabelFromKey = (key) => {
    const statusObj = this.state.shopWorkOrderStatuses.find(
      (s) => s.key === key
    );
    return statusObj?.name || key;
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleExportCSV = async () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys.length === 0) {
      message.warning('No rows selected for export');
      return;
    }

    let { queryString } = WorkOrderStore.filtersUsed;
    console.log('Filters queryString:', queryString);
    
    console.log('Filters used:', JSON.stringify(WorkOrderStore.filtersUsed, null, 2));

    
    if (selectedRowKeys.length >= 10) {
      const url = new URL(queryString);
      url.searchParams.set('limit', '10000');
      queryString = url.toString();
    }


    const hideLoadingMessage = message.loading('Generating CSV...', 0);

    try {
      const { data: csvData } = await WorkOrderStore.fetchWorkOrdersForCSV(queryString);
      // console.log('CSV Data:', csvData);

      // Filter the csvData to include only the selected rows if less than 10 are selected
      const selectedData = selectedRowKeys.length < 10 ? csvData.filter(row => selectedRowKeys.includes(row.id)) : csvData;

      const processedData = selectedData.map(row => {
        const assignedUser = this.state.shopUsers.find(user => user.userId === row.assigned_to);
        const assignedTo = assignedUser ? `${assignedUser.firstName || ''} ${assignedUser.lastName || ''}`.trim() || assignedUser.email : 'N/A';

        const vendor = this.state.vendors.find(v => v.id === row.vendor_id);
        const vendorName = vendor ? vendor.name : 'N/A';

        const priority = WorkOrderRepairPriorityEnum[row.priority]
          ? WorkOrderRepairPriorityEnum[row.priority]
          : 'N/A';

        const status = this.state.shopWorkOrderStatuses.find(status => status.key === row.status);
        const statusDescription = status ? status.description : 'Unknown';

        return {
          id: row.id,
          id_car: row.id_car,
          order: row.invoice_number,
          asset_type: row.asset_type,
          car_name: row.car_name,
          status: statusDescription,
          location_code: row.location_code,
          priority: priority,
          vendor_name: vendorName,
          started_at: row.started_at,
          created_at: row.created_at,
          updated_at: row.updated_at,
          service_tasks: row.work_order_lines[0]?.title || '',
          partsTotal: row.partsTotal,
          costsTotal: row.costsTotal,
          laborsTotal: row.laborsTotal,
          assigned_to: assignedTo,
        };
      });

      const csvContent = [
        'Id,Id Car, Order No.,Asset Type,Car Name,Status,Location Code,Priority,Vendor Name,Started At,Created At,Updated At,Service Tasks,Parts Total,Costs Total,Labors Total,Assigned To', // Header row
        ...processedData.map(row => 
          `"${row.id}","${row.id_car}","${row.order}","${row.asset_type}","${row.car_name}","${row.status}","${row.location_code}","${row.priority}","${row.vendor_name}","${row.started_at}","${row.created_at}","${row.updated_at}","${row.service_tasks}","${row.partsTotal}","${row.costsTotal}","${row.laborsTotal}","${row.assigned_to}"`) // Data rows
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const timestamp = moment().format('YYYYMMDD_HHmmss');
      link.setAttribute('download', `export_${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error exporting CSV:', err);
    } finally {
      
      hideLoadingMessage();
    }
  };
  
  render() {
    let newColumns = this.allColumns;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,    // Bind selected rows
      onChange: this.onSelectChange,  // Handle changes
    };

    const { device, orientation } = DeviceStore;
    this.backupColumns = this.allColumns;
    if (device === 'tablet' && orientation === 'portrait') {
      const allowedColumns = [
        'invoice_number',
        'car_name',
        'status',
        'file_transfer',
        'created_at',
        'started_at',
        'assigned_to',
        'actions',
      ];
      newColumns = this.allColumns
        .filter(
          (c) =>
            allowedColumns.includes(c.key) ||
            ['invoice_number', 'actions'].includes(c.dataIndex)
        )
        .map((col) => {
          return {
            ...col,
            ...{
              editable: false,
              sorter: false,
            },
          };
        });
    } else {
      if (this.state.customColumns.length > 0) {
        newColumns = this.allColumns.filter((col) =>
          this.state.customColumns.includes(col.key)
        );
      }
    }

    return this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <>
        <DealershipChooser />

        {device === 'mobile' && (
          <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
            <Col span={10}>
              <WOFormFilterParts
                optionStatus={this.state.shopWorkOrderStatuses.map(
                  (workOrderStatus) => ({
                    value: workOrderStatus.key,
                    title: workOrderStatus.name,
                  })
                )}
                optionAssigned={this.state.shopUsers.map((user) => {
                  return {
                    value: user.userId,
                    title:
                      `${user.firstName || ''} ${user.lastName || ''}`.trim() ||
                      user.email,
                  };
                })}
                changeFilter={this.handleChangeFormFilter}
                popoverVisible={this.state.popoverVisible}
                togglePopoverVisibility={this.togglePopoverVisibility}
                onClear={this.onClearAllFilters}
                countFiltersSelected={
                  this.state.countFiltersSelected
                    ? this.state.countFiltersSelected + 1
                    : 0
                }
              />
            </Col>
            <Col span={14}>
              <Search
                placeholder={
                  customFleets.ufpFleet.includes(ShopStore.currentShop.id)
                    ? 'Search by Order # or Invoice # or Unit ID'
                    : 'Search by Order # or Unit ID'
                }
                value={this.state.currentSearch}
                onChange={(event) => {
                  this.setState({
                    currentSearch: event.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        )}
        {/* Add widget with WorkOrder grouped by assignedTo and status */}
        {this.state.workOrdersGroupedByStatusAndAssignedUser.length > 0 && (
          <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
            <Col span={device === 'mobile' || device === 'tablet' ? 24 : 24}>
              <WorkOrderStatusByAssignedToWidget
                workOrdersGroupedByStatusAndAssignedUser={
                  this.state.workOrdersGroupedByStatusAndAssignedUser
                }
                onClickElement={(type, value, row) => () => {
                  if (type === 'user') {
                    this.setState(
                      {
                        currentAssignedToFilters: [
                          {
                            key: row.assignedToId,
                            label: row.assignedToName,
                          },
                        ],
                        currentFilters: [],
                      },
                      () => {
                        this.onChangeCurrentFilters();
                      }
                    );
                  } else if (type === 'onlystatus') {
                    const statusObj = this.state.shopWorkOrderStatuses.find(
                      (s) => s.key === value
                    );
                    this.setState(
                      {
                        currentFilters: [
                          {
                            key: value,
                            label: statusObj.name,
                          },
                        ],
                      },
                      () => {
                        this.onChangeCurrentFilters();
                      }
                    );
                  } else if (type === 'status') {
                    const statusObj = this.state.shopWorkOrderStatuses.find(
                      (s) => s.key === value
                    );
                    this.setState(
                      {
                        currentAssignedToFilters: [
                          {
                            key: row.assignedToId,
                            label: row.assignedToName,
                          },
                        ],
                        currentFilters: [
                          {
                            key: value,
                            label: statusObj.name,
                          },
                        ],
                      },
                      () => {
                        this.onChangeCurrentFilters();
                      }
                    );
                  }
                }}
              />
            </Col>
          </Row>
        )}
        {device !== 'mobile' && (
          <Row
            type="flex"
            style={{ marginBottom: 10, marginTop: 10 }}
            gutter={[16, 16]}
            justify="space-between"
          >
            <Col span={6}>
              <Search
                style={{ width: '100%' }}
                placeholder={
                  customFleets.ufpFleet.includes(ShopStore.currentShop.id)
                    ? 'Search by Order # or Invoice # or Unit ID'
                    : 'Search by Order # or Unit ID'
                }
                value={this.state.currentSearch}
                onChange={(event) => {
                  this.setState({
                    currentSearch: event.target.value,
                  });
                }}
              />
            </Col>

            <Col>
              <CustomTableColumn
                title={'Customize work order'}
                shopId={this.shopId}
                key={`custom-table-${new Date()}`}
                tableName="work-order"
                fixedColumns={['invoice_number', 'actions']}
                columns={this.allColumns}
                defaultColumns={this.state.customColumns}
                onSubmit={this.handlerCustomColumnTable}
                onReset={this.handlerResetCustomColumnTable}
              />
            </Col>
          </Row>
        )}

        {/* Filter badges */}
        {device !== 'mobile' && (
          <Row type="flex" align="middle">
            <Col>
              <CurrentFilterBadges
                currentFiltersApplied={this.currentFiltersApplied}
                onClearAllFilters={() => {
                  this.setState(
                    {
                      currentFilters: [],
                      currentAssignedToFilters: [],
                      currentVendorFilters: [],
                    },
                    () => {
                      this.onChangeCurrentFilters();
                    }
                  );
                }}
                onCloseTag={(filter) => {
                  this.setState(
                    {
                      currentFilters: this.state.currentFilters.filter(
                        (f) => f.key !== filter.key
                      ),
                      currentAssignedToFilters: this.state.currentAssignedToFilters.filter(
                        (f) => f.key !== filter.key
                      ),
                      currentVendorFilters: this.state.currentVendorFilters.filter(
                        (f) => f.key !== filter.key
                      ),
                    },
                    () => {
                      this.onChangeCurrentFilters();
                    }
                  );
                }}
              />
            </Col>
            <Col>
              <Button type="primary" disabled={this.state.selectedRowKeys.length === 0} style={{marginBottom: 18}} size='small'  onClick={this.handleExportCSV}>Export CSV</Button>
            </Col>
          </Row>
        )}

        {device === 'tablet' && orientation === 'portrait' ? (
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <>
                <AppAlert
                  message={
                    <>
                      <Text strong>Some columns are not shown</Text>
                      <br />
                      <Text>
                        To view all columns, use the device in landscape
                        (horizontally)
                      </Text>
                    </>
                  }
                />
              </>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {device === 'mobile' ? (
          <>
            <PitStopList
              id="my-list"
              data={this.data}
              getTableStore={(tableStore) => (this.tableStore = tableStore)}
              rowKey="id"
              hideTopPagination={true}
              renderItem={(item) => (
                <WOCardItemPart
                  data={item}
                  toggleCard={this.toggleCardExpansion}
                  isExpanded={this.state.expandedCards[item.id]}
                  optionStatus={this.state.shopWorkOrderStatuses.map(
                    (workOrderStatus) => ({
                      key: workOrderStatus.key,
                      value: workOrderStatus.name,
                    })
                  )}
                  changeStatus={this.handleStatusChange}
                  optionAssigned={this.state.shopUsers.map((user) => {
                    return {
                      key: user.userId,
                      value:
                        `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email,
                    };
                  })}
                  changeAssigned={this.handleAssignedToChange}
                  history={this.props.history}
                />
              )}
              pagination={{
                limit: 10,
              }}
              fetchData={this.fetchData}
            />
          </>
        ) : (
          <>
            <StyledTableRow>
              <PitstopTable
                id={`${ShopStore.currentShop.id}-work-order-list`}
                data={this.data}
                rowKey="id"
                onRowClick={(record, evt) => {
                  if (evt.target.closest('.order-number-column')) {
                    if (
                      evt.target.classList.contains(
                        'ant-select-selection__rendered'
                      ) ||
                      evt.target.classList.contains(
                        'ant-select-dropdown-menu-item'
                      ) ||
                      evt.target.classList.contains(
                        'ant-select-selection-selected-value'
                      )
                    ) {
                      evt.stopPropagation();
                      return;
                    }
                    return this.props.history.push(
                      `/work-order/${record.id}/edit`
                    );
                  }
                }}
                columns={newColumns}
                hideTopPagination={true}
                fetchData={this.fetchData}
                getTableStore={(tableStore) => (this.tableStore = tableStore)}
                components={{
                  header: {
                    cell: CustomHeaderCell,
                  },
                }}
                rowSelection={rowSelection}
                onDelete={this.onDelete}
              />
            </StyledTableRow>
          </>
        )}

        <ContactFormModal
          modalId={VENDOR_DETAILS_MODAL_ID}
          saving={this.state.savingVendor}
          title="Vendor Details"
          onCancel={this.closeVendorDetailsModal}
          defaultData={this.state.selectedVendor}
          onSubmit={() => {}}
          hideFooter={true}
          disableFields={['*']}
        />

        <WorkOrderEditShopStatusesModal
          shopId={ShopStore.currentShop.id}
          isSavingStatuses={false}
          saveNewShopStatuses={() => {}}
          onCancel={() => {}}
          dataSource={this.state.shopWorkOrderStatuses.map((status) => ({
            id: status.id,
            key: status.key,
            color: status.color,
            description: status.name,
            is_default: status.is_default,
          }))}
          selectStatusAsDefault={() => {}}
          deleteStatus={() => {}}
        />
      </>
    );
  }
}

decorate(WorkOrderTable, {
  tableStore: observable,
  data: computed,
  shopId: computed,
  shopCars: computed,
});

export default observer(WorkOrderTable);

