import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { Form, Input, Row, Col, Select, Typography, Icon, Button } from 'antd';

import {
  WorkOrderRepairPriorityEnum,
  WorkOrderTypeOfServiceEnum,
} from 'stores/Classes/WorkOrderObject';
import CurrentUserStore from 'stores/CurrentUserStore';
import { TableStore } from 'stores/abstract';

import { UserStore, WorkOrderStore, ShopStore } from 'stores';
import { customFleets } from 'shared';
import { Logger } from 'stores/Classes';

const { TextArea } = Input;

const NoFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { Option } = Select;
const { Text } = Typography;

const fullWidth = {
  width: '100%',
};

const randomWorkOrderNumber = () => {
  // generate a random number between 1 and 1000000
  // and pad it with 0s to make it 6 digits long
  const randomNumber = `000000${Math.floor(Math.random() * 1000000)}`;
  return `${randomNumber.substring(randomNumber.length - 6)}`;
};

class WorkOrderDetailsForm extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    shopId: PropTypes.number,
    childRef: PropTypes.func,
    onSave: PropTypes.func,
    workOrder: PropTypes.object,
    type: PropTypes.string,
  };

  demoDescriptionForUps = '- Check the battery\n- Check the power supply';

  state = {
    currentServiceTaskOnSearch: '',
    currentServiceTask: '',
    currentDescription: '',
    users: [],
    statuses: [],
    initialStatus: '',
    locations: [],
    allLocations: [],
    location: '',
  };

  usersTableStore = new TableStore();

  serviceTaskSuggestions = _.chain(WorkOrderStore.key32ServiceTasks)
    .map((description, i) => ({
      description,
      i,
    }))
    .sortBy('description')
    .value();

  async componentDidMount() {
    // To disable submit button at the beginning.
    const { childRef } = this.props;
    if (childRef) {
      childRef(this);
    }

    if (customFleets.ufpFleet.includes(this.props.shopId)) {
      this.setState({
        currentDescription: this.demoDescriptionForUps,
      });
    }

    await this.loadShopUsers();
    await this.loadShopWorkOrderStatuses();
    await this.loadShopLocations();
  }

  get createdByOptions() {
    return this.state.users.map((user, index) => (
      <Option key={index} value={user.userId}>
        {`${user.firstName} ${user.lastName}`.trim() || user.email}
      </Option>
    ));
  }

  get assignedToOptions() {
    return this.state.users.map((user, index) => (
      <Option key={index} value={user.userId}>
        {`${user.firstName} ${user.lastName}`.trim() || user.email}
      </Option>
    ));
  }

  get serviceTasksOptions() {
    return this.serviceTaskSuggestions.map((serviceTask, index) => (
      <Option key={index} value={serviceTask.i}>
        {serviceTask.description}
      </Option>
    ));
  }

  initialWorkOrderNumber =
    this.props.workOrder.woInvoiceNumber || randomWorkOrderNumber();

  loadShopUsers = async () => {
    const users = await UserStore.fetchUsersForTable({
      offset: 0,
      limit: 100,
      filter: null,
    });
    this.setState({ users });
  };

  loadShopLocations = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ locations: [] });
        return;
      }
      const locations = await ShopStore.getShopLocations(
        ShopStore.currentShop.id
      );
      this.setState({ locations, allLocations: locations });
    } catch (error) {
      Logger.error(error);
    }
  };

  loadShopWorkOrderStatuses = async () => {
    const statuses = await WorkOrderStore.getShopWorkOrderStatuses(
      this.props.shopId
    );
    const defaultStatus = (statuses?.data || []).find(
      (status) => status.is_default
    ) ||
      statuses?.data[0] || { key: 'open', value: 'Open' };
    this.setState({
      statuses: _.chain(statuses)
        .get('data', [])
        .map((status) => ({
          id: status.id,
          key: status.key,
          name: status.description,
          description: status.description,
          color: status.color,
          text: status.description,
          value: status.key,
          is_default: status.is_default,
        }))
        .value(),
      initialStatus: defaultStatus.key,
    });
  };

  getCurrentLoggedUser = () => {
    return CurrentUserStore.user.id;
  };

  onSearchServiceTask = (value) => {
    this.setState({ currentServiceTaskOnSearch: value });
  };

  onSelectServiceTask = (value) => {
    const suggestion = this.serviceTaskSuggestions.find((s) => s.i === value);
    this.setState({
      currentServiceTask: suggestion.description,
    });
  };

  onChangeDescription = (event) => {
    const { value } = event.target;
    this.setState({
      currentDescription: value,
    });
  };

  onClickAddServiceTask = () => {
    const key = this.serviceTaskSuggestions.length + 1;
    this.serviceTaskSuggestions.push({
      i: key,
      description: this.state.currentServiceTaskOnSearch,
    });
    this.onSelectServiceTask(key);
  };

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave(true);
    }
  };

  repairTypeInitialOption = () => {
    return this.props.type === 'defects' ? 'driver_identified' : 'inspection';
  };

  repairTypeDisabledOption = () => {
    return this.props.type === 'defects' ? true : false;
  };

  serviceTaskFilterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onSelectLocation = (value) => {
    const [_, code] = value.split('-');
    const initWONumberParts = this.initialWorkOrderNumber.split('-');

    this.initialWorkOrderNumber = `${code}-${initWONumberParts[1] ||
      this.initialWorkOrderNumber}`;

    this.setState({ location: value, locations: this.state.allLocations });
  };

  onSearchLocation = (value) => {
    const searchStr = value.toLowerCase();
    const filteredLocations = this.state.allLocations.filter(
      ({ code, description }) =>
        code.toLowerCase().includes(searchStr) ||
        description.toLowerCase().includes(searchStr)
    );

    this.setState({ locations: filteredLocations });
  };

  renderServiceTaskAndDescription = (noServiceTaskFound) => {
    if (this.props.type === 'issues') {
      return (
        <>
          {customFleets.ufpFleet.includes(this.props.shopId) && (
            <Col span={24}>
              <label htmlFor="location">
                Attach a Location to this Work Order:
              </label>
              <Select
                value={this.state.location}
                id="location"
                onChange={this.onSelectLocation}
                onSearch={this.onSearchLocation}
                showSearch
                optionFilterProp="children"
              >
                {this.state.locations.map((location) => (
                  <Option
                    key={`${location.id}-${location.code}-${location.description}`}
                    value={`${location.id}-${location.code}-${location.description}`}
                  >
                    {location.code} - {location.description}
                  </Option>
                ))}
              </Select>
            </Col>
          )}
          <Col span={24}>
            <label htmlFor="serviceTask">Service Task:</label>
            <Select
              value={this.state.currentServiceTask}
              id="serviceTask"
              placeholder="Search for a service task"
              onChange={(value) => this.onSelectServiceTask(value)}
              onSearch={(value) => this.onSearchServiceTask(value)}
              showSearch
              optionFilterProp="children"
              filterOption={this.serviceTaskFilterOption}
              notFoundContent={noServiceTaskFound}
            >
              {this.serviceTasksOptions}
            </Select>
          </Col>
          <Col style={{ marginTop: 15 }} span={24}>
            <label htmlFor="description">Description:</label>
            <TextArea
              id="description"
              rows={4}
              placeholder="Add Description"
              value={this.state.currentDescription}
              onChange={this.onChangeDescription}
            />
          </Col>
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const statusOptions = this.state.statuses.map((status) => {
      return (
        <Option key={status.key} value={status.key}>
          {status.name}
        </Option>
      );
    });

    const priorityOptions = Object.keys(WorkOrderRepairPriorityEnum)
      .map((woRepairPriorityKey) => ({
        key: woRepairPriorityKey,
        value: WorkOrderRepairPriorityEnum[woRepairPriorityKey],
      }))
      .map((woRepairPriority) => {
        return (
          <Option key={woRepairPriority.key} value={woRepairPriority.key}>
            {woRepairPriority.value}
          </Option>
        );
      });

    const typeOptions = Object.keys(WorkOrderTypeOfServiceEnum)
      .map((woRepairPriorityKey) => ({
        key: woRepairPriorityKey,
        value: WorkOrderTypeOfServiceEnum[woRepairPriorityKey],
      }))
      .map((woRepairPriority) => {
        return (
          <Option key={woRepairPriority.key} value={woRepairPriority.key}>
            {woRepairPriority.value}
          </Option>
        );
      });

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    const woInvoiceNumberError =
      isFieldTouched('woInvoiceNumber') && getFieldError('woInvoiceNumber');
    const woCreatedByError =
      isFieldTouched('woCreatedBy') && getFieldError('woCreatedBy');
    const woAssignedToError =
      isFieldTouched('woAssignedTo') && getFieldError('woAssignedTo');
    const woStatusError =
      isFieldTouched('woStatus') && getFieldError('woStatus');
    const woRepairTypeError =
      isFieldTouched('woRepairType') && getFieldError('woRepairType');
    const woRepairPriorityError =
      isFieldTouched('woRepairPriority') && getFieldError('woRepairPriority');

    const noServiceTaskFound = this.state.currentServiceTaskOnSearch ? (
      <NoFoundContainer>
        <Button type="link" icon="plus" onClick={this.onClickAddServiceTask}>
          Add {this.state.currentServiceTaskOnSearch}
        </Button>
      </NoFoundContainer>
    ) : (
      <NoFoundContainer>
        <Icon style={{ fontSize: '24px' }} type="inbox" />
        <Text level={4}>No Data</Text>
      </NoFoundContainer>
    );

    return (
      <Form onSubmit={this.onSave}>
        <Text strong>Work Order Details</Text>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Work order number:"
              validateStatus={woInvoiceNumberError ? 'error' : ''}
              help={woInvoiceNumberError || ''}
            >
              {getFieldDecorator('woInvoiceNumber', {
                rules: [
                  { required: true, message: 'Work order number is required!' },
                ],
                initialValue: this.initialWorkOrderNumber,
              })(<Input prefix="#" allowClear={true} placeholder="#000000" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Created by:"
              validateStatus={woCreatedByError ? 'error' : ''}
              help={woCreatedByError || ''}
            >
              {getFieldDecorator('woCreatedBy', {
                rules: [{ required: true, message: 'Created by is required!' }],
                initialValue:
                  this.props.workOrder.woCreatedBy ||
                  this.getCurrentLoggedUser(),
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a User"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {this.createdByOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Assigned To:"
              validateStatus={woAssignedToError ? 'error' : ''}
              help={woAssignedToError || ''}
            >
              {getFieldDecorator('woAssignedTo', {
                rules: [
                  { required: true, message: 'Assigned to is required!' },
                ],
                initialValue: this.props.workOrder.woAssignedTo,
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a User"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {this.assignedToOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Status:"
              validateStatus={woStatusError ? 'error' : ''}
              help={woStatusError || ''}
            >
              {getFieldDecorator('woStatus', {
                initialValue: this.state.initialStatus,
                rules: [{ required: true, message: 'Status is required!' }],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Status"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {statusOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Repair Type:"
              validateStatus={woRepairTypeError ? 'error' : ''}
              help={woRepairTypeError || ''}
            >
              {getFieldDecorator('woRepairType', {
                initialValue: this.repairTypeInitialOption(),
                rules: [
                  { required: true, message: 'Repair Type is required!' },
                ],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Repair Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled={this.repairTypeDisabledOption}
                >
                  {typeOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Repair Priority:"
              validateStatus={woRepairPriorityError ? 'error' : ''}
              help={woRepairPriorityError || ''}
            >
              {getFieldDecorator('woRepairPriority', {
                initialValue: 'major',
                rules: [
                  { required: true, message: 'Repair Priority is required!' },
                ],
              })(
                <Select
                  style={fullWidth}
                  placeholder="Select a Repair Priority"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {priorityOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          {this.renderServiceTaskAndDescription(noServiceTaskFound)}
        </Row>
      </Form>
    );
  }
}

const WorkOrderForm = Form.create({ name: 'work_order_form' })(
  WorkOrderDetailsForm
);

export default WorkOrderForm;
