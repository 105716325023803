import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { AppStore, ShopStore } from 'stores';
import {
  Form,
  Button,
  Typography,
  notification,
  Input,
  Spin,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import DealershipChooser from 'components/DealershipChooser';
import { observer } from 'mobx-react';
import { Logger } from 'stores/Classes';

const { Text } = Typography;

const Container = styled.div`
  padding-right: 2vw;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

type FleetSettingsFormProps = FormComponentProps

/* eslint-disable react/prop-types */
const FleetSettingsForm: React.FC<FleetSettingsFormProps> = ({ form }) => {
  const { getFieldDecorator } = form;

  const [shopId, setShopId] = useState<number>(0);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [savingForm, setSavingForm] = useState<boolean>(false);
  const [fleetAddress, setFleetAddress] = useState<string>('');
  const [fleetPhone, setFleetPhone] = useState<string>('');
  const [fleetEmail, setFleetEmail] = useState<string>('');

  // Initial load of fleet settings
  useEffect(() => {
    const initialize = async () => {
      await loadData(ShopStore.currentShop.id);
      setShopId(ShopStore.currentShop.id);
    };

    initialize();
  }, []);

  const loadData = async (shopId: number) => {
    setLoadingInitial(true);
    try {
      const shop = await ShopStore.getShopById(shopId);
      // Load contact information from currentShop
      const address = _.get(shop, 'address', '');
      const phone = _.get(shop, 'phone', '');
      const email = _.get(shop, 'email', '');

      // Set contact information state
      setFleetAddress(address);
      setFleetPhone(phone);
      setFleetEmail(email);
    } catch (e) {
      Logger.log(e);
    } finally {
      setLoadingInitial(false);
    }
  };

  // Add this new useEffect to update form values after render
  useEffect(() => {
    if (!loadingInitial && form) {
      form.setFieldsValue({
        fleetAddress,
        fleetPhone,
        fleetEmail,
      });
    }
  }, [loadingInitial]); // Only watch for changes in loading state

  const updateFleetSettings = (e: React.FormEvent) => {
    e.preventDefault();

    form.validateFields(async (err: any, values: any) => {
      if (err) return;

      try {
        setSavingForm(true);
        AppStore.addLoading('Setting Fleet Settings');

        // Extract contact information from form values
        const { fleetAddress, fleetPhone, fleetEmail } = values;

        // Update shop entity with contact information
        await ShopStore.updateShop(shopId, {
          address: fleetAddress,
          phone: fleetPhone,
          email: fleetEmail,
        });

        notification.success({
          message: 'Settings updated',
          key: 'fleetSettingsSuccess',
          description: <Container>The Fleet settings were updated!</Container>,
        });
      } catch (e) {
        notification.error({
          message: 'Oops!',
          key: 'fleetSettingsError',
          description: (
            <Container>
              <Text>Error updating the fleet settings</Text>
            </Container>
          ),
        });
        Logger.log(e);
      } finally {
        AppStore.removeLoading('');
        setSavingForm(false);
      }
    });
  };

  if (loadingInitial) {
    return (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    );
  }

  return (
    <>
      <DealershipChooser />

      <Form
        onSubmit={updateFleetSettings}
        className="edit-fleet-settings-form"
      >
        <Form.Item label="Fleet Address">
          {getFieldDecorator('fleetAddress', {
            initialValue: fleetAddress,
          })(<Input placeholder="Fleet Address" />)}
        </Form.Item>

        <Form.Item label="Fleet Phone">
          {getFieldDecorator('fleetPhone', {
            initialValue: fleetPhone,
          })(<Input placeholder="Fleet Phone" />)}
        </Form.Item>

        <Form.Item label="Fleet Email">
          {getFieldDecorator('fleetEmail', {
            initialValue: fleetEmail,
            rules: [
              { type: 'email', message: 'Please enter a valid email' },
            ],
          })(<Input placeholder="Fleet Email" />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={savingForm}
          >
            Update Settings
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(Form.create<FleetSettingsFormProps>({ name: 'edit-fleet-settings-form' })(FleetSettingsForm));