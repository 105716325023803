import { EventBus } from '../stores/EventBus';
import { NotificationType, NotificationStore } from '../stores/NotificationStore';

// Default durations in milliseconds
const DEFAULT_DURATIONS = {
  [NotificationType.INFO]: 5000,
  [NotificationType.SUCCESS]: 5000,
  [NotificationType.ERROR]: 8000,
  [NotificationType.WARNING]: 6000,
  [NotificationType.LOADING]: null, // Loading notifications are persistent by default
};

/**
 * NotificationHelper - Utility for creating and managing notifications
 *
 * Examples:
 *
 * // Show a loading notification
 * const notificationId = notifications.loading('Generating CSV...');
 *
 * try {
 *   // Do the async work
 *   await generateCSV();
 *
 *   // Update the notification to success
 *   notifications.success('CSV exported successfully!', { id: notificationId });
 * } catch (error) {
 *   // Update the notification to error
 *   notifications.error(`Error generating CSV: ${error.message}`, { id: notificationId });
 * }
 */
export const notifications = {
  /**
   * Show an info notification
   */
  info: (message: string, options?: { id?: string; duration?: number | null; onClose?: () => void }) => {
    return _showNotification(message, NotificationType.INFO, options);
  },

  /**
   * Show a success notification
   */
  success: (message: string, options?: { id?: string; duration?: number | null; onClose?: () => void }) => {
    return _showNotification(message, NotificationType.SUCCESS, options);
  },

  /**
   * Show an error notification
   */
  error: (message: string, options?: { id?: string; duration?: number | null; onClose?: () => void }) => {
    return _showNotification(message, NotificationType.ERROR, options);
  },

  /**
   * Show a warning notification
   */
  warning: (message: string, options?: { id?: string; duration?: number | null; onClose?: () => void }) => {
    return _showNotification(message, NotificationType.WARNING, options);
  },

  /**
   * Show a loading notification
   */
  loading: (message: string, options?: { id?: string; duration?: number | null; onClose?: () => void }) => {
    return _showNotification(message, NotificationType.LOADING, options);
  },

  /**
   * Update an existing notification
   */
  update: (id: string, updates: { message?: string; type?: NotificationType; duration?: number | null; onClose?: () => void }) => {
    EventBus.$emit('notification:update', id, updates);
  },

  /**
   * Remove a notification
   */
  remove: (id: string) => {
    EventBus.$emit('notification:remove', id);
  },

  /**
   * Clear all notifications
   */
  clear: () => {
    EventBus.$emit('notification:clear');
  }
};

/**
 * Internal helper for showing notifications
 */
function _showNotification(
  message: string,
  type: NotificationType,
  options?: { id?: string; duration?: number | null; onClose?: () => void }
): string {
  const duration = options?.duration !== undefined
    ? options.duration
    : DEFAULT_DURATIONS[type];

  if (options?.id) {
    // If we have an ID, update the existing notification
    EventBus.$emit('notification:update', options.id, {
      message,
      type,
      duration,
      onClose: options.onClose,
    });
    return options.id;
  } else {
    // Otherwise create a new notification and call the store directly
    const notificationObj = {
      message,
      type,
      duration,
      onClose: options?.onClose,
    };

    // Need to actually call the store method directly rather than using EventBus
    // to get the returned ID
    const id = NotificationStore.addNotification(notificationObj);
    return id;
  }
}
