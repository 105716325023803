import { Layout } from 'antd';
import styled from 'styled-components';

const StyledFooter = styled(Layout.Footer)`
  && {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  && p {
    margin-bottom: 0;
  }
`;

export default StyledFooter;
