import React, { Component, createRef } from 'react';
import { Table, Input, Tag, Icon, message, Tooltip } from 'antd'; // Using Icon from Ant Design 3.0
import DealershipChooser from 'components/DealershipChooser';
import WorkOrderStore from 'stores/WorkOrderStore';
import { ShopStore } from 'stores';
import CurrentUserStore from 'stores/CurrentUserStore';
import AppStore from 'stores/App.js';
import debounce from 'lodash/debounce'; 
import Tiff from 'tiff.js'; 
import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';

const formatDateTime = (date) => {
  const d = new Date(date);
  return `${d.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })} ${d.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })}`;
};

class SFTPWorkOrdersPage extends Component {

  
  state = {
    searchValue: '',
    data: [],
    editingKey: null, // ID of the row being edited
    editingValue: '', // Current editing value
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    fileTransferFilters: [],
    workOrderAttachedFilter: [],
    isModalVisible: false,
    previewImageUrl: '',
  };
  
  constructor(props) {
    super(props);
    this.imageCache = new Map(); // Cache TIFF images
    this.canvasRefs = {}; // Store refs for each canvas
  }

  renderImageWithFilename = (text, record) => {
    const { editingKey, editingValue } = this.state; // Get editing state
    const isEditing = editingKey === record.event_id; // Check if this row is being edited
  
    // If the row is being edited, render an input field
    if (isEditing) {
      return (
        <Input
          value={editingValue}
          onChange={(e) => this.setState({ editingValue: e.target.value })}
          style={{ width: '100%' }}
          onPressEnter={this.saveEdit} // Save on Enter key press
        />
      );
    }
  
    const httpUrl = record.file_url.replace(
      's3://work-orders-scan/',
      'https://work-orders-scan.s3.us-east-2.amazonaws.com/'
    );
  
    if (!this.canvasRefs[record.file_url]) {
      this.canvasRefs[record.file_url] = createRef();
    }
  
    const drawImageOnCanvas = (imageSrc, canvas) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const ctx = canvas.getContext('2d');
        const canvasWidth = 35;
        const canvasHeight = 35;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
  
        const originalWidth = img.width;
        const originalHeight = img.height;
        const scale = Math.min(canvasWidth / originalWidth, canvasHeight / originalHeight);
        const scaledWidth = originalWidth * scale;
        const scaledHeight = originalHeight * scale;
        const offsetX = (canvasWidth - scaledWidth) / 2;
        const offsetY = (canvasHeight - scaledHeight) / 2;
  
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight, offsetX, offsetY, scaledWidth, scaledHeight);
      };
    };
  
    if (this.imageCache.has(httpUrl)) {
      setTimeout(() => {
        const cachedDataUrl = this.imageCache.get(httpUrl);
        const canvas = this.canvasRefs[record.file_url].current;
        if (canvas) {
          drawImageOnCanvas(cachedDataUrl, canvas);
        }
      }, 100);
    } else {
      fetch(httpUrl)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const tiff = new Tiff({ buffer });
          const tiffCanvas = tiff.toCanvas();
          const dataUrl = tiffCanvas.toDataURL();
          this.imageCache.set(httpUrl, dataUrl);
  
          const canvas = this.canvasRefs[record.file_url].current;
          if (canvas) {
            drawImageOnCanvas(dataUrl, canvas);
          }
        })
        .catch((error) => console.error('Error loading TIFF image:', error));
    }
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <canvas
          ref={this.canvasRefs[record.file_url]}
          onClick={() => this.showImagePreview(httpUrl)}
          style={{
            cursor: 'pointer',
            width: 35,
            height: 35,
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: '#f0f0f0',
          }}
        />
        <div>{text}</div>
      </div>
    );
  };  

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/sftp-work-orders');
    this.fetchData();
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({ searchValue: value }); // Update the search input value in state
    this.fetchData(1, this.state.pagination.pageSize, value.trim()); // Trigger search with debounce
  };

  handleFileTransferFilterChange = (selectedKeys) => {
    this.setState({ fileTransferFilters: selectedKeys });
    this.fetchData(1, this.state.pagination.pageSize, this.state.searchValue.trim());
  };

  handleWorkOrderAttachedFilterChange = (selectedKeys) => {
    this.setState({ workOrderAttachedFilter: selectedKeys });
    this.fetchData(1, this.state.pagination.pageSize, this.state.searchValue.trim());
  };

  fetchData = debounce(async (page = 1, pageSize = 10, fileName = '') => {
    const { fileTransferFilters, workOrderAttachedFilter } = this.state;
  
    this.setState({ loading: true });
  
    try {
      const shopId = ShopStore.currentShop.id;
      const matched =
        workOrderAttachedFilter.length === 1
          ? workOrderAttachedFilter[0] === 'YES'
            ? true
            : false
          : undefined;
  
      const resp = await WorkOrderStore.getWorkOrderFileEvents({
        shopId,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        ...(fileName && { fileName }),
        ...(fileTransferFilters.length > 0 && { status: fileTransferFilters.join(',') }),
        ...(matched !== undefined && { matched }),
      });
  
      const { data, meta } = resp;
  
      // ✅ Clear canvas refs to force re-render
      this.canvasRefs = {};
  
      this.setState({
        data,
        pagination: {
          current: page,
          pageSize,
          total: meta?.count || 0,
        },
        loading: false,
      });
  
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ loading: false });
    }
  }, 300);
  

  handleTableChange = (pagination, filters) => {
    if (filters.file_current_status) {
      this.setState({ fileTransferFilters: filters.file_current_status }, () => {
        this.fetchData(
          pagination.current,
          pagination.pageSize,
          this.state.searchValue.trim()
        );
      });
    }

    if (filters.match_work_order_id) {
      this.setState({ workOrderAttachedFilter: filters.match_work_order_id }, () => {
        this.fetchData(
          pagination.current,
          pagination.pageSize,
          this.state.searchValue.trim()
        );
      });
    }

    this.setState({
      pagination: {
        ...pagination,
      },
    });
  };

  startEdit = (record) => {
    this.setState({ editingKey: record.event_id, editingValue: record.work_order_id });
  };

  saveEdit = async () => {
    const { data, editingKey, editingValue } = this.state;
    const shopId = ShopStore.currentShop.id; // Get the shop ID
  
    this.setState({ saving: true }); // Start loading
    
    try {
      const update = await WorkOrderStore.updateFileEvents({
        eventId: editingKey, 
        workOrderId: editingValue, 
        updatedBy: CurrentUserStore.user.email,
        shopId: shopId,
      });
  
      if (update && update.event_id) { 
        message.success('Saved successfully!');
  
        // Remove cached image so it reloads on render
        const updatedRecord = data.find(item => item.event_id === editingKey);
        if (updatedRecord && updatedRecord.file_url) {
          const httpUrl = updatedRecord.file_url.replace(
            's3://work-orders-scan/',
            'https://work-orders-scan.s3.us-east-2.amazonaws.com/'
          );
          this.imageCache.delete(httpUrl); // Clear cache for this image
        }
  
        const updatedData = data.map((item) =>
          item.event_id === editingKey
            ? {
                ...item,
                work_order_id: editingValue,
                invoice_status: update.invoice_status,
                match_work_order_id: update.match_work_order_id,
              }
            : item
        );
        this.setState({
          data: updatedData,
          editingKey: null,
          editingValue: '',
          saving: false, // Stop loading
        });
  
      } else {
        console.error('Failed to update file events:', update || 'Unknown error');
        message.error('Failed to save changes.');
        this.setState({ saving: false }); // Stop loading
      }
    } catch (error) {
      console.error('Error updating file events:', error);
      this.setState({ saving: false }); // Stop loading
    }
  };  
  
  cancelEdit = () => {
    this.setState({ editingKey: null, editingValue: '' });
  };


  showImagePreview = (url) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const tiff = new Tiff({ buffer });
        const canvas = tiff.toCanvas();
  
        // Convert canvas to a base64 image URL
        const base64Image = canvas.toDataURL();
  
        this.setState({
          isModalVisible: true,
          previewImageUrl: base64Image,
        });
      })
      .catch((error) => {
        console.error('Error loading TIFF image for preview:', error);
      });
  };
  
  
  hideImagePreview = () => {
    this.setState({
      isModalVisible: false,
      previewImageUrl: '',
    });
  };
  
  render() {
    const { data, pagination, loading, editingKey, editingValue } = this.state;

    const columns = [
      {
        title: 'Filename',
        dataIndex: 'work_order_id',
        key: 'work_order_id',
        render: this.renderImageWithFilename
      },
      {
        title: 'Status',
        dataIndex: 'current_status',
        key: 'current_status',
        render: (status) => {
          if (status === 'COMPLETED') {
            return <div>Scanned</div>;
          } else if (status === 'IN_PROGRESS') {
            return <div>Progress</div>;
          }
          return <div>Failed</div>;
        },
      },
      {
        title: 'Work Order Attached',
        dataIndex: 'match_work_order_id',
        key: 'match_work_order_id',
        filters: [
          { text: 'No Work Order Attached', value: 'NO' },
          { text: 'Work Order Attached', value: 'YES' },
        ],
        filteredValue: this.state.workOrderAttachedFilter,
        render: (text) =>
          text ? 
          <span
            onClick={() => this.props.history.push(`/work-order/${text}/edit`)}
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {text}
          </span> : 'No Work Order Attached',
      },
      {
        title: 'File Transfer',
        dataIndex: 'current_status',
        key: 'file_current_status',
        filters: [
          { text: 'In Progress', value: 'IN_PROGRESS' },
          { text: 'Complete', value: 'COMPLETED' },
          { text: 'Failed', value: 'FAILED' },
        ],
        filteredValue: this.state.fileTransferFilters, // Bind filteredValue to state
        render: (text) => {
          if (text === 'COMPLETED') {
            return <Tag color="green">Complete</Tag>;
          } else if (text === 'IN_PROGRESS') {
            return <Tag color="orange">In Progress</Tag>;
          }
          return (
            <Tooltip
              title="Unable to scan due to poor image quality or obstructions. Please manually enter details from the attached image."
            >
              <Tag color="red">
                <Icon type="info-circle" style={{ marginRight: 4 }} />
                Failed
              </Tag>
            </Tooltip>
          );
        },
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => <div>{formatDateTime(date)}</div>,
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (date) => <div>{formatDateTime(date)}</div>,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) =>
          this.state.editingKey === record.event_id ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              {this.state.saving ? (
                <Icon type="loading" style={{ fontSize: 18, color: 'blue' }} />
              ) : (
                <>
                  <Icon
                    type="check-circle"
                    style={{ fontSize: 18, color: 'blue', cursor: 'pointer' }}
                    onClick={this.saveEdit}
                  />
                  <Icon
                    type="close-circle"
                    style={{ fontSize: 18, color: 'blue', cursor: 'pointer' }}
                    onClick={this.cancelEdit}
                  />
                </>
              )}
            </div>
          ) : (
            <div>
              <Icon
                type="edit"
                style={{ fontSize: 18, color: 'blue', cursor: 'pointer' }}
                onClick={() => this.startEdit(record)}
              />
              <span
                onClick={() => this.startEdit(record)}
                style={{ marginLeft: 3, color: 'blue', cursor: 'pointer' }}
              >
                Edit
              </span>
            </div>
          ),
      },      
    ];

    return (
      <div>
        <h1>SFTP Work Orders</h1>
        <DealershipChooser />
        <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-start' }}>
        <Input
            placeholder="Search by Filename"
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
            onPressEnter={this.handleSearch} 
            suffix={
              <Icon
                type="search"
                onClick={this.handleSearch}
                style={{ cursor: 'pointer' }}
              />
            }
            style={{ width: 300 }}
        />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          rowKey="id"
        />
        <Modal
          visible={this.state.isModalVisible}
          footer={null}
          onCancel={this.hideImagePreview}
          centered
          width={600}
          destroyOnClose
        >
        <img
          src={this.state.previewImageUrl}
          alt="Preview"
          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
        />
      </Modal>
      </div>
    );
  }
}

export default withRouter(SFTPWorkOrdersPage);
