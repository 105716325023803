import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { action, decorate, observable, observe } from 'mobx';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import _ from 'lodash';

import moment from 'moment';

import { Layout, Spin } from 'antd';

import 'helpers/HelperFunctions';

import {
  AppStore,
  CurrentUserStore,
  ShopStore,
  CarStore,
  IntegrationStore,
  TableStore,
  GeotabConnector,
  ReportsDataStore,
} from 'stores';
import {
  IntegrationCacheStore,
  IssuesTableCacheStore,
  PitstopTableCacheStore,
  ReportCacheStore,
} from 'stores/CacheStore';

import { dealershipRoutes, technicianRoutes, userRoutes } from 'Routes';
import { customFleets } from 'shared';

import { Header, Sidebar } from 'containers/Shell';
import { LoginGeotab, Logout } from 'containers/Auth';
import {
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
} from 'v3/pages';

import { Loading, SampleDataBar } from 'components';
import {
  GeotabIntegrationForm,
  ProfileForm,
  SamsaraIntegrationForm,
} from 'v3/components/forms/onboarding';
import OtherFormModal from 'containers/Integration/OtherFormModal';
import OktaCallback from 'v3/components/forms/auth/OktaCallback';

import DeviceStore from 'stores/Classes/DeviceStore';
import getDeviceType from 'shared/deviceTypeUtil';
import { EventBus } from 'stores/EventBus';
import StyledLayout from 'components/UI/StyledLayout';
import StyledContent from 'components/UI/StyledContent';
import StyledFooter from 'components/UI/StyledFooter';
import LoadingScreen from 'components/UI/LoadingScreen';
import { Logger } from 'stores/Classes';
import NotificationsContainer from './components/Notifications/NotificationsContainer';

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: any;
  }
  const pendo: {
    initialize: (options: any) => void;
  };
  const __insp: {
    push: (args: any[]) => void;
  };
}

let timer: any;

// Extend TableStore type
declare module 'stores' {
  interface TableStore {
    demo: boolean;
    data: any[];
  }

  interface CarStoreC {
    data: any;
    reset: () => void;
  }
}

class DashboardWrapper extends Component<RouteComponentProps> {
  constructor(props: any) {
    super(props);
    // load google translate
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;

    // add window resize event listen
    window.addEventListener('resize', this.updateDimensions);

    // register geotab listener if in geotab dashboard
    if (AppStore.isOnGeotabDashboard()) {
      GeotabConnector.registerListener();
    }
  }

  carTableStore = new TableStore();

  state = {
    collapsed: false,
    width: 0,
    loadingText1: true,
    deviceType: { device: '', orientation: null },
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    const realCars = await CarStore.countShopRealCars(ShopStore.currentShop.id);
    Logger.log('🚙 realCars on current shop:', realCars);

    if (CurrentUserStore?.loaded) {
      pendo.initialize({
        visitor: {
          id: String(CurrentUserStore.user.id), // Required if user is logged in
          email: CurrentUserStore.user.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: CurrentUserStore.user.name, // Recommended if using Pendo Feedback
          is_paid_user: realCars > 0,
        },

        account: {
          id: String(ShopStore.currentShop.id), // Required if using Pendo Feedback
          name: ShopStore.currentShop.name, // Optional
        },
      });
    }

    if (customFleets.gmrv.includes(ShopStore.currentShop.id)) {
      this.props.history.push('/cars');
    }

    const doNotRedirectRoutes = [
      '/vehicles',
      '/cars',
      '/issues',
      '/maintenance',
      '/highpriorityvehicles',
      '/location',
      '/contacts',
      '/user-management',
      '/work-order',
      '/work-order/add',
      '/work-order-desktop',
      '/work-order-desktop/add',
      '/warranties',
      '/purchase-orders',
      '/purchase-orders/add',
      '/parts',
      '/parts/add',
    ];

    if (!doNotRedirectRoutes.includes(this.props.location.pathname)) {
      // Enabled Default highpriorityvehicles page for: Fox & James and Sub-Fleets
      const isFoxAndJames = customFleets.foxAndJames.includes(
        ShopStore.currentShop.id
      );

      if (isFoxAndJames) {
        return this.props.history.push('/highpriorityvehicles');
      } else {
        const { device } = DeviceStore;

        if (['mobile', 'tablet'].includes(device)) {
          return this.props.history.push('/work-order');
        }

        return this.props.history.push('/vehicles');
      }
    }

    Logger.log('🚀 Checking ShopStore ID:', ShopStore.currentShop.id);
    Logger.log('🚙 hasRealCars:', CarStore.hasRealCars);

    if (ShopStore.currentShop.id !== -1) {
      Logger.log('✅ Shop is valid. Loading demo cars...');
      await this.loadDemoCars();

      if (
        CurrentUserStore?.user?.loaded && CurrentUserStore.user.role === 'admin'
      ) {
        Logger.log('👤 User Role is Admin. Checking CarStore status...');
        Logger.log('📊 CarStore Loaded:', CarStore?.demoCarsTable?.loaded);
        Logger.log('🚗 hasDemoCars:', CarStore.hasDemoCars);
        Logger.log('🚙 hasRealCars:', CarStore.hasRealCars);

        if (
          CarStore?.demoCarsTable?.loaded &&
          CarStore.hasDemoCars &&
          CarStore.hasRealCars
        ) {
          Logger.log('🔥 Unassigning demo cars...');
          await CarStore.unassignDemoCar();
          this.reset();

          ReportsDataStore.reload();
        } else if (
          CarStore?.demoCarsTable?.loaded &&
          !CarStore.hasDemoCars &&
          !CarStore.hasRealCars
        ) {
          Logger.log('⚡ No cars found. Loading demo cars...');
          await this.loadDemos();
        } else {
          Logger.log('ℹ️ No actions taken. Conditions not met.');
        }
      } else {
        Logger.log('🔒 User is NOT an admin. Skipping car checks.');
      }
    } else {
      Logger.log('❌ Invalid Shop ID. Exiting process.');
    }


    Cookies.set('lastSelectedShop', String(ShopStore.currentShop.id), { expires: 5 });
  });

  disposer1 = observe(CurrentUserStore, 'loaded', async () => {
    if (CurrentUserStore?.loaded) {
      const realCars = await CarStore.countShopRealCars(ShopStore.currentShop.id);
      Logger.log('🚙 realCars:', realCars);

      // in your authentication promise handler or callback
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: String(CurrentUserStore.user.id), // Required if user is logged in
          email: CurrentUserStore.user.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: CurrentUserStore.user.name, // Recommended if using Pendo Feedback
          is_paid_user: realCars > 0,
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: String(ShopStore.currentShop.id), // Required if using Pendo Feedback
          name: ShopStore.currentShop.name, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
      __insp.push([
        'tagSession',
        {
          email: CurrentUserStore.user.email,
          userId: CurrentUserStore.user.id,
          shop: ShopStore.currentShop.name,
        },
      ]);

      if (realCars <= 0) {
        await this.loadDemoCars();
      }
    }
  });

  disposer2 = observe(IntegrationStore, 'modalIntegrationForm', async () => {
    if (IntegrationStore.modalIntegrationForm) {
      AppStore.openModals.set(IntegrationStore.modalIntegrationForm, true);

      await this.loadDemos();
    }
  });

  initLoadingTextInterval = () => {
    timer = setInterval(() => {
      this.setState({ loadingText1: !this.state.loadingText1 });
    }, 3000);
  };

  clearLoadingTextInterval = () => {
    clearInterval(timer);
    timer = false;
  };

  async componentDidMount() {
    // update window dimension
    this.updateDimensions();

    if (
      !['/login', '/register', '/login/geotab', '/reset-password'].includes(
        this.props.location.pathname
      )
    ) {
      Cookies.set(
        'latestRoute',
        this.props.location.pathname + this.props.location.search,
        {
          expires: 1,
        }
      );
    }

    // Initialize google analytics page view tracking
    this.props.history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    await CurrentUserStore.getCurrentUser(false);

    // Check if Motive OAuth code is present in URL
    if (this.props.location.search.includes('code')) {
      const code = this.props.location.search.split('=')[1];
      await this.handleMotiveOauthCode(code);
    }

    this.initLoadingTextInterval();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (CurrentUserStore.pending && !timer) {
      this.initLoadingTextInterval();
    } else if (!CurrentUserStore.pending && timer) {
      this.clearLoadingTextInterval();
    }
    if (!AppStore.isOnGeotabDashboard()) {
      const latestRoute = Cookies.get('latestRoute');

      if (
        this.props.location.pathname + this.props.location.search !==
        latestRoute
      ) {
        if (
          !['/login', '/register', '/login/geotab', '/reset-password'].includes(
            this.props.location.pathname
          )
        ) {
          Cookies.set(
            'latestRoute',
            this.props.location.pathname + this.props.location.search,
            {
              expires: 1,
            }
          );
        }

        if (CurrentUserStore.user) {
          const _latestRoute = Cookies.get('latestRoute');

          if (
            _latestRoute !==
            this.props.location.pathname + this.props.location.search
          ) {
            if (_latestRoute) {
              this.props.history.push(_latestRoute);
            }
          }

          if (_.isNil(_latestRoute)) {
            this.props.history.push('/vehicles');
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);

    this.disposer();
    this.disposer1();
    this.disposer2();

    if (timer) {
      this.clearLoadingTextInterval();
    }
  }

  updateDimensions = () => {
    const newState = { width: window.innerWidth, deviceType: getDeviceType() };
    this.setState(newState);
  };

  googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };

  toggleSideBar = () => {
    this.setState({ collapsed: !this.state.collapsed }, () => {
      EventBus.$emit('dashboardWrapper/SIDEBAR_TOGGLED', this.state.collapsed);
    });
  };

  noAuthRoutes = () => {
    return [
      <Route exact path={['/login', '/']} key="/login" component={LoginPage} />,
      <Route
        exact
        path="/login/geotab"
        key="/login/geotab"
        render={(prop) => (
          <LoginGeotab toggleSideBar={this.toggleSideBar}></LoginGeotab>
        )}
      />,
      <Route
        exact
        path={['/register']}
        key="/register"
        component={RegisterPage}
      />,
      <Route
        exact
        path="/reset-password"
        key="/reset-password"
        component={ResetPasswordPage}
      />,
      <Route exact path="/callback" key="/callback" component={OktaCallback} />,
      <Redirect from="*" to="/login" key="redirect" />,
    ];
  };

  handleMotiveOauthCode = async (code: any) => {
    try {
      if (code) {
        await IntegrationStore.handleMotiveOauth(code);
      }
    } catch (e) {
      // do nothing
    }
  };

  loadDemoCars = async () => {
    try {
      if (ShopStore.currentShop.id !== -1) {
        IntegrationStore.modalIntegrationForm = null;

        // first check if shop has cars
        const hasCars = await CarStore.checkIfShopHasCars(
          ShopStore.currentShop.id
        );

        if (hasCars) {
          // if shop has cars, check if has integration
          IntegrationStore.loaded = false;
          const hasIntegration = await IntegrationStore.checkIfShopHasIntegration(
            ShopStore.currentShop.id
          );
          IntegrationStore.loaded = true;
          const temporaryData = { id: 1, vin: 'DEMO-' };
          if (!hasIntegration) {
            // shop has demo cars linked to it
            // if the shop doesn't have integration, set the CarStore information to tell CarStore to use demo data
            CarStore.demoCarsTable.demo = true;
            CarStore.demoCarsTable.loaded = true;
            CarStore.setCarData(temporaryData, 1);
            CarStore.demoCarsTable.data = [1];

            // if the shop has real cars, unassign demo cars and reset / reload
            if (
              (await CarStore.countShopRealCars(ShopStore.currentShop.id)) > 0
            ) {
              await CarStore.unassignDemoCar();
              this.reset();
              ReportsDataStore.reload();
            }
          } else {
            // if the shop has integration, set the CarStore information to tell CarStore that the shop has real data
            temporaryData.vin = 'integration_set';
            CarStore.demoCarsTable.loaded = true;
            CarStore.demoCarsTable.demo = false;
            CarStore.setCarData(temporaryData, 1);
            CarStore.demoCarsTable.data = [1];
          }
        } else {
          CarStore.demoCarsTable.loaded = true;
        }
      }
    } catch (err) {
      AppStore.addError('Sorry! We are unable to load vehicles data!');
    } finally {
      IntegrationStore.loaded = true;
    }
  };

  loadDemos = async () => {
    // assign demo cars when no cars in fleet
    await CarStore.assignDemoCar(ShopStore.currentShop.id);

    this.reset();

    this.loadDemoCars();

    ReportsDataStore.reload();
  };

  reset = () => {
    PitstopTableCacheStore.reset();
    IssuesTableCacheStore.reset();
    IntegrationCacheStore.reset();
    ReportCacheStore.reset();
    (CarStore as any).reset();
    CarStore.demoCarsTable.reset();
  };

  render() {
    const { device } = DeviceStore;
    if (CurrentUserStore.pending) {
      return (
        <>
          <LoadingScreen>
            <div>
              <h1>Welcome to Pitstop Dashboard</h1>
              {// switch between texts to show different loading messages
                this.state.loadingText1 ? (
                  <p>
                    Thank you for waiting while our AI Flywheel is hard at work
                    analyzing your data!
                  </p>
                ) : (
                  <p>
                    Did You Know? Pitstop's software analyzes over 30 Billion data
                    points!
                  </p>
                )}
              <Spin />
            </div>
          </LoadingScreen>
        </>
      );
    }

    if (!CurrentUserStore?.loaded) {
      return (
        <>
          <Switch>{this.noAuthRoutes()}</Switch>
        </>
      );
    }

    let routes;

    if (CurrentUserStore?.loaded) {
      switch (CurrentUserStore?.user?.role) {
        case 'admin':
          routes = dealershipRoutes();
          break;
        case 'dealership':
          routes = dealershipRoutes();
          break;
        case 'technician':
          routes = technicianRoutes();
          break;
        case 'user':
        default:
          routes = userRoutes();
      }

      // update the google translate cookie with the current user's preferred language
      if (CurrentUserStore?.user?.settings?.preferredLanguage) {
        Cookies.set(
          'googtrans',
          `/en/${CurrentUserStore.user.settings.preferredLanguage}`,
          {
            expires: 365,
          }
        );
      }
    }

    const updatedmarginleft =
      this.state.collapsed || ['mobile', 'tablet'].includes(device);

    return (
      <>
        <Layout>
          <Logout />
          <Loading />
          <Header toggleSideBar={this.toggleSideBar} />
          <Sidebar
            collapsed={this.state.collapsed}
            toggleSideBar={this.toggleSideBar}
          />
          <StyledLayout className="dashboard-content">
            <StyledContent updatedmarginleft={updatedmarginleft.toString()}>
              <div id="pendo-helper">&nbsp;</div>

              {/* ✅ Render SampleDataBar only if there are NO real cars */}
              {!CarStore.hasRealCars && <SampleDataBar />}

              <ProfileForm id="PROFILE_FORM_MODAL" />
              <GeotabIntegrationForm id="geotab-integration-form" />
              <SamsaraIntegrationForm id="samsara-integration-form" />
              <OtherFormModal
                id="other-integration-form"
                shopId={ShopStore.currentShop.id}
                afterCreate={() => {
                  Logger.log('🔄 afterCreate');
                }}
              />

              <Switch>{routes}</Switch>
            </StyledContent>
            <StyledFooter style={{ textAlign: 'center' }}>
              <p>Developed by Pitstop Inc &copy; {moment().format('YYYY')}</p>
            </StyledFooter>
          </StyledLayout>
        </Layout>
        <NotificationsContainer />
      </>
    );

  }
}

decorate(DashboardWrapper, {
  carTableStore: observable,
  reset: action,
  loadDemoCars: action,
});

export default withRouter(observer(DashboardWrapper));
