import { Layout } from 'antd';
import styled from 'styled-components';

const StyledContent: any = styled(Layout.Content)`
  background: #ffffff;
  padding: 24px;
  height: 100%;

  margin-left: ${(props: any) => {
    return props.updatedmarginleft === 'true' ? '0px' : '240px';
  }}
  margin-top: 40px;

  @media (max-width: 576px) {
    margin-top: 0px;
  }
`;

export default StyledContent;
