import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'antd';

// Styled wrapper for the table with synchronized scrollbars
const TableWrapper = styled.div`
  position: relative;

  /* Table container with proper scrolling */
  .table-container {
    overflow: hidden;
    position: relative;
  }

  /* Fixed bottom scrollbar styling - right aligned */
  .bottom-scrollbar-container {
    position: fixed;
    bottom: 0;
    right: 16px; /* Position on the right side with some padding */
    background-color: #fff;
    height: 22px; /* Increased height for better visibility */
    overflow: hidden;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    /* Width will be set dynamically to match table width */
    padding: 2px; /* Add some padding inside the container */
  }

  .bottom-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    height: 13px; /* Increased height */
    padding-top: 2px; /* Center the scrollbar thumb vertically */

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 12px; /* Increased scrollbar thickness */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.4); /* Darker for better visibility */
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
      border: 2px solid white; /* Add border for better visibility */
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Light gray track */
      border-radius: 6px;
    }

    .bottom-scrollbar-content {
      height: 1px;
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
    margin-bottom: 24px; /* Add more space for the fixed scrollbar */
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #f7f7f7 !important;
  }

  .ant-table tbody > tr > td.hover-effect-cell:hover {
    background-color: #e6f1ff !important;
  }

  .ant-table tbody > tr > td.ant-table-row-expand-icon-cell:hover {
    background-color: #e6f1ff !important;
  }

  .issues-table-wrapper .ant-table-tbody > tr:hover > td {
    background-color: #e6f1ff !important;
  }

  /* Hide the horizontal scrollbar but keep functionality */
  .ant-table-body {
    overflow-x: scroll !important;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  /* Add padding at the bottom to account for the fixed scrollbar */
  margin-bottom: 28px; /* Increased to accommodate larger scrollbar */
`;

// Table component with synchronized double scrollbars
const TableContainer = (props) => {
  const bottomScrollRef = useRef(null);
  const tableRef = useRef(null);
  const bottomContainerRef = useRef(null);

  useEffect(() => {
    const bottomScrollbar = bottomScrollRef.current;
    const bottomContainer = bottomContainerRef.current;
    let tableBody = null;

    // Find table body after rendering
    if (tableRef.current) {
      tableBody = tableRef.current.querySelector('.ant-table-body');
    }

    if (bottomScrollbar && tableBody && bottomContainer) {
      // Set the width of the bottom scrollbar content to match the width of the table
      const setBottomScrollbarWidth = () => {
        const table = tableBody.querySelector('table');
        if (table) {
          // Add a significant buffer to ensure we can scroll all the way
          const tableWidth = Math.ceil(table.offsetWidth) + 30;
          const contentElement = bottomScrollbar.querySelector('.bottom-scrollbar-content');
          contentElement.style.width = `${tableWidth}px`;

          // Get the table container dimensions and position
          const tableRect = tableRef?.current?.getBoundingClientRect();

          if (!tableRect) {
            return;
          }

          // Set the scrollbar width to match the table's width
          bottomContainer.style.width = `${tableRect.width}px`;

          // Calculate the right offset to align the scrollbar with the table
          const rightOffset = window.innerWidth - (tableRect.left + tableRect.width);
          bottomContainer.style.right = `${Math.max(rightOffset, 16)}px`;
        }
      };

      // Create persistent scroll handler functions that don't get recreated on cleanup
      const handleBottomScroll = () => {
        if (tableBody) {
          tableBody.scrollLeft = bottomScrollbar.scrollLeft;
        }
      };

      const handleTableScroll = () => {
        if (bottomScrollbar) {
          bottomScrollbar.scrollLeft = tableBody.scrollLeft;
        }
      };

      // Initial width setup with a small delay to ensure accurate calculations
      setTimeout(setBottomScrollbarWidth, 100);

      // Update width on window resize
      window.addEventListener('resize', setBottomScrollbarWidth);

      // Sync scroll positions
      bottomScrollbar.addEventListener('scroll', handleBottomScroll);
      tableBody.addEventListener('scroll', handleTableScroll);

      // Callback for when table data changes
      const observer = new MutationObserver(() => {
        setTimeout(setBottomScrollbarWidth, 50);
      });

      // Watch for changes to the table (like sorting, filtering, etc.)
      observer.observe(tableBody, {
        childList: true,
        subtree: true
      });

      // Cleanup event listeners
      return () => {
        window.removeEventListener('resize', setBottomScrollbarWidth);
        bottomScrollbar.removeEventListener('scroll', handleBottomScroll);
        tableBody.removeEventListener('scroll', handleTableScroll);
        observer.disconnect();
      };
    }
  }, [props.dataSource]); // Re-run when data source changes

  return (
    <TableWrapper>
      <div className="table-container" ref={tableRef}>
        <StyledTable {...props} />
      </div>
      <div className="bottom-scrollbar-container" ref={bottomContainerRef}>
        <div className="bottom-scrollbar" ref={bottomScrollRef}>
          <div className="bottom-scrollbar-content"></div>
        </div>
      </div>
    </TableWrapper>
  );
};

TableContainer.propTypes = {
  dataSource: PropTypes.array
};

export default TableContainer;
