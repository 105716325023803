import React from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  Badge,
  Col,
  Icon,
  Row,
  Spin,
  Typography,
  Tag,
  Button,
  Tooltip,
  Popover,
} from 'antd';
import {
  customFleets,
  PitstopButton,
  prescriptiveMaintenanceMessages,
} from 'shared';

import {
  AppStore,
  CarStore,
  IssueStore,
  CurrentUserStore,
  ShopStore,
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';

import {
  getRoutineDueEngineHours,
  getRoutineDueMileage,
} from 'containers/IssueProfile/share/utils';

import { getPriorityTagColor } from 'stores/Classes/IssueObject';
import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';

import PitstopBadge from 'components/General/PitstopBadge';
import DeviceStore from 'stores/Classes/DeviceStore';

import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { PitStopTagStatus } from 'shared/PitstopUI/PitStopTagStatus';
import { getColor, remains, capitalizeWords } from 'shared/pms';

const { Text } = Typography;

const CarIndicator = styled.div`
  width: 5px;
  position: absolute;
  background-color: ${(props) => props.color || 'initial'};
  height: 180%;
  top: -35px;
  padding: 0;
  left: -25px;
`;

const CarIDSection = styled.div`
  position: relative;

  p {
    margin-bottom: 0px;
  }
`;

const ScannerSection = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const IssueCategorySection = styled.span`
  white-space: pre-wrap;
`;

const NextPMWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NextPMPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .percentage {
    font-size: 20px;
  }
  .description {
    font-size: 12px;
  }
  .ant-tag {
    border-radius: 100px;
    background-color: white;
    margin-top: 5px;
    font-size: 12px;
  }
`;

const StyledAction = styled.span`
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const renderIndicator = (car) => {
  const priority = car.issueCounts;
  if (priority?.critical > 0) {
    return <CarIndicator color={getPriorityTagColor('Critical')} />;
  }
  if (priority?.major > 0) {
    return <CarIndicator color={getPriorityTagColor('Major')} />;
  }
  return <CarIndicator />;
};

export const renderSuggestedAction = (car) => {
  //maxPriority is returning wrong, so temporarily overriding it
  //const maxPriority = car.maxPriority;
  const maxPriority = car.issueCounts.critical > 0 ? '4' : '2';
  //TODO Find some way to make adjPriority accessible in the car object
  //const maxAdjPriority = car.maxAdjPriority;

  //maxMajorIssueDate is returning wrong, so temporarily overriding it
  //const maxMajorIssueDate = car.maxMajorIssueDate;
  const now = new Date();
  //Temp maxMajorIssueDate to always trigger the stale major message
  const maxMajorIssueDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 8
  );
  const routineServicePresent = car.routineServicePresent;
  const issueCounts = null; // car.issueCounts;
  return (
    <p>
      {prescriptiveMaintenanceMessages(
        maxPriority,
        maxMajorIssueDate,
        routineServicePresent,
        issueCounts
      )}
    </p>
  );
};

export const renderVehicleId = (car) => {
  // console.log(car);
  return (
    <CarIDSection>
      {renderIndicator(car)}
      <strong data-test="table-asset-column-vehicle-name">
        {car.carName || car.mmy}
      </strong>
      {car.carName && <p>{car.mmy}</p>}
      {customFleets.motiveFleets.includes(ShopStore.currentShop.id) ? (
        <></>
      ) : (
        <>
          <p>
            {`${Number(calcMileage(car.totalMileage).toFixed(1)).toReadable()}
              ${CurrentUserStore?.user?.displayedOdometer || 'mi'}`}
          </p>
          {!customFleets.wolfline.includes(ShopStore.currentShop.id) &&
            !customFleets.bmc.includes(ShopStore.currentShop.id) && (
              <p>
                {Math.round(
                  car?.getEngineHours
                    ? car?.getEngineHours()
                    : car.engineHours
                    ? Number(convertSecondsToHours(car.engineHours)).toFixed(1)
                    : 0
                ).toReadable()}{' '}
                engine hours
              </p>
            )}
        </>
      )}
      <p>
        <Link to={`/car/${car.id}`}>View Details</Link>
      </p>
    </CarIDSection>
  );
};

const StyledBadge = ({ count, backgroundColor }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{ backgroundColor }}
    overflowCount={1000000}
  />
);

StyledBadge.propTypes = {
  count: PropTypes.any,
  backgroundColor: PropTypes.string,
};

const StyledBadgeV2 = ({ style = {}, count, borderColor, color }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{
      ...style,
      backgroundColor: '#ffffff',
      borderColor,
      color,
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }}
    overflowCount={1000000}
  />
);

StyledBadgeV2.propTypes = {
  style: PropTypes.any,
  count: PropTypes.any,
  borderColor: PropTypes.string,
  color: PropTypes.string,
};

export const renderIssues = (car) => {
  const criticalCount = car.issueCounts.critical || 0;
  const majorCount = car.issueCounts.major || 0;
  const minorCount = car.issueCounts.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={criticalCount}
            backgroundColor={getPriorityTagColor('Critical')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Critical
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={majorCount}
            backgroundColor={getPriorityTagColor('Major')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Major
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={minorCount}
            backgroundColor={getPriorityTagColor('Minor')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Minor
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesV2 = (car) => {
  const criticalCount = car?.issueCounts?.critical || 0;
  const majorCount = car?.issueCounts?.major || 0;
  const minorCount = car?.issueCounts?.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <StyledBadgeV2
            count={criticalCount}
            borderColor={getPriorityTagColor('Critical')}
            color={getPriorityTagColor('Critical')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={majorCount}
            borderColor={getPriorityTagColor('Major')}
            color={getPriorityTagColor('Major')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={minorCount}
            borderColor={getPriorityTagColor('Minor')}
            color={getPriorityTagColor('Minor')}
          />
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesWithShowIssuesLink = (
  car,
  onExpandClick = () => {},
  currentExpandedCarIds = []
) => {
  const criticalCount = car.issueCounts.critical || 0;
  const majorCount = car.issueCounts.major || 0;
  const minorCount = car.issueCounts.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <StyledBadgeV2
            count={criticalCount}
            borderColor={getPriorityTagColor('Critical')}
            color={getPriorityTagColor('Critical')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={majorCount}
            borderColor={getPriorityTagColor('Major')}
            color={getPriorityTagColor('Major')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={minorCount}
            borderColor={getPriorityTagColor('Minor')}
            color={getPriorityTagColor('Minor')}
          />
        </Col>
        <Col span={24}>
          <Button type="link" onClick={() => onExpandClick(car, 'issue')}>
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
            {currentExpandedCarIds.indexOf(car.id) > -1
              ? 'Hide Details'
              : 'Show Issues'}
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesCat = (car) => {
  const categories = car.issues.active.data
    .concat(car.issues.upcoming.data)
    .reduce((prev, id) => {
      if (IssueStore.data.has(id)) {
        const issueDetail = IssueStore.data.get(id).issueDetail;
        if (
          prev.length < 3 &&
          issueDetail &&
          issueDetail.item &&
          issueDetail.item !== 'unknown' &&
          issueDetail.item.length > 0
        ) {
          prev.push(issueDetail.item);
        }
        if (prev.length === 3) {
          prev.push('...');
        }
      }
      return prev;
    }, []);
  return <IssueCategorySection>{categories.join(', ')}</IssueCategorySection>;
};

export const renderScanner = (scanner, car) => {
  const scannerId = _.get(scanner, 'scannerId');
  const lastConnectionTimestamp = _.get(car, 'lastConnectionTimestamp');
  let timestampText = 'Check device connection';
  let monthDiff = 0;

  if (lastConnectionTimestamp) {
    const status = moment
      .tz(
        lastConnectionTimestamp * 1000,
        CurrentUserStore.user.settings.timezone
      )
      .fromNow();

    monthDiff = moment().diff(
      moment.tz(
        lastConnectionTimestamp * 1000,
        CurrentUserStore.user.settings.timezone
      ),
      'M'
    );

    timestampText = `Last Active ${status}`;
  }

  if (
    (scannerId && lastConnectionTimestamp && monthDiff < 1) ||
    _.isBoolean(CarStore.demo)
  ) {
    return (
      <ScannerSection>
        <Badge status="success" text="Active" />
        <p>{scannerId}</p>
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else if (
    (scannerId && !lastConnectionTimestamp) ||
    (scannerId && lastConnectionTimestamp && monthDiff >= 1)
  ) {
    return (
      <ScannerSection>
        <Badge status="warning" text="Inactive" />
        <p>{scannerId}</p>
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else if (!scannerId && lastConnectionTimestamp) {
    return (
      <ScannerSection>
        <Badge status="error" text="Disconnected" />
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else {
    return (
      <ScannerSection>
        <Badge status="error" text="No Device" />
      </ScannerSection>
    );
  }
};

export const renderDriver = (view, editable = true) => (carObj) => {
  return (
    <Observer>
      {() => {
        const car = CarStore.data.get(carObj.id);

        let displayButton = (
          <PitstopButton
            style={{ width: '100%' }}
            onClick={() => {
              view.setState({ selectedCarId: car.id }, () => {
                AppStore.openModals.set(view.modalId, true);
              });
            }}
          >
            <Icon type="edit" />
            {car.user ? 'Edit' : 'Assign'}
          </PitstopButton>
        );

        let displayProfileLink = <></>;

        if (car.user) {
          displayProfileLink = (
            <div style={{ textAlign: 'center' }}>
              <Link to={`/user/${car.user.id}`}>{car.user.name}</Link>
            </div>
          );
        }

        if (!editable || CurrentUserStore.user.role === UserRoles.customer) {
          displayButton = <></>;
        }

        return (
          <>
            <Col span={24}>{displayButton}</Col>
            <Col span={24}>{displayProfileLink}</Col>
          </>
        );
      }}
    </Observer>
  );
};

export const renderNextPMVisit = (nextPmCar) => {
  return (
    <Observer>
      {() => {
        if (!nextPmCar || !nextPmCar.nextPm) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(nextPmCar.nextPm)) {
          return (
            <>
              <Col span={24}>
                <Link
                  to={`/service-interval-new?carId=${nextPmCar.id}&carType=${nextPmCar.carType}`}
                >
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        const dueMessages = [];
        for (const nextPm of nextPmCar.nextPm) {
          if (getRoutineDueEngineHours(nextPm))
            dueMessages.push(
              <>
                <b>On Engine Hours:</b>{' '}
                {`${Number(getRoutineDueEngineHours(nextPm)).toReadable()}`}{' '}
                engine hours
              </>
            );

          if (getRoutineDueMileage(nextPm))
            dueMessages.push(
              <>
                <b>On Mileage: </b>{' '}
                {`${Number(getRoutineDueMileage(nextPm)).toReadable()} ${
                  CurrentUserStore.user.displayedOdometer
                }`}
              </>
            );
        }
        if (dueMessages.length === 0) {
          return (
            <>
              <Col span={24}>
                <Link
                  to={`/service-interval-new?carId=${nextPmCar.id}&carType=${nextPmCar.carType}`}
                >
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        return (
          <>
            <Col span={24}>
              {_.map(dueMessages, (msg) => (
                <>
                  {msg}
                  <br />
                </>
              ))}
            </Col>
          </>
        );
      }}
    </Observer>
  );
};

export const renderNextPMDueInPercentage = (
  car,
  onExpandClick = () => {},
  currentExpandedCarIds = []
) => {
  const { device } = DeviceStore;
  const {
    due_engine_hours,
    due_mileage,
    due_time_in_s,
    rule_type,
    display_type,
    pm_percent,
    pm_status,
    engineHours,
    totalMileage,
    pm_description,
    pm_action,
  } = car;
  if (pm_percent === null || pm_percent === undefined) {
    return (
      <>
        {CurrentUserStore.isTechnician ? (
          <>--</>
        ) : (
          <Col span={24}>
            <Link
              to={`/service-interval-new?carId=${car.id}&carType=${car.carType}`}
            >
              <Icon type="edit" />
              <span className="marginLeft5">Setup PM</span>
            </Link>
          </Col>
        )}
      </>
    );
  }
  let displayValue;
  let displayText;

  if (rule_type === 'time') {
    // Calc of remaining Time
    const now = new Date(); // Current date
    const dueAt = moment.unix(due_time_in_s);

    // Calculate the difference in days
    const diffInDays = dueAt.diff(now, 'days');

    // Calculate the difference in months
    // const diffInMonths = dueAt.diff(now, 'months');
    const diffInMonths = Math.ceil(diffInDays / 30.4375);

    if (diffInMonths < 1) {
      displayValue = Math.abs(diffInDays);
      displayText = 'day(s)';
    } else {
      displayValue = Math.abs(diffInMonths);
      displayText = 'month(s)';
    }
  } else {
    // Calc of remaining mileage and engine hours
    const hoursRemainingValue =
      rule_type === 'engine_hours'
        ? Math.ceil(
            isNaN(Number(due_engine_hours)) && isNaN(Number(engineHours))
              ? undefined
              : Math.abs(
                  (Number(due_engine_hours) - Number(engineHours)) / 3600
                )
          )
        : undefined;

    const kmRemaining =
      rule_type === 'mileage'
        ? Math.ceil(
            isNaN(Number(due_mileage)) && isNaN(Number(totalMileage))
              ? undefined
              : Math.abs(Number(due_mileage) - Number(totalMileage))
          )
        : undefined;

    const milesRemaining = kmRemaining
      ? Math.ceil(kmRemaining / 1.60934)
      : undefined;

    const userSettings = CurrentUserStore.user.settings;
    const milesOrKm =
      userSettings?.odometer === 'km' ? kmRemaining : milesRemaining;
    const milesOrKmText = userSettings?.odometer === 'km' ? 'km' : 'miles';
    displayText = milesOrKm !== undefined ? milesOrKmText : 'engine hours';
    const milesOrEngineHours =
      milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
    // force it to always display positive numbers
    displayValue = Math.abs(milesOrEngineHours);
  }

  const userSettings = CurrentUserStore.user.settings;
  const odometer = userSettings?.odometer === 'km' ? 'km' : 'mi';

  const carRemains = remains({
    dueTime: due_time_in_s,
    safetyInspection: false,
    ruleType: rule_type,
    dueEngineHours: due_engine_hours,
    engineHours: engineHours,
    dueMileage: due_mileage,
    totalMileage: totalMileage,
    odometer,
    displayType: display_type,
  });

  const style = {
    color: getColor(pm_status),
  };
  return (
    <>
      <Col span={24}>
        <NextPMPercentageWrapper style={style}>
          <span className="percentage" style={style}>
            {Math.max(0, Math.floor(pm_percent))}%
          </span>
          <Tooltip placement="top" title={pm_action}>
            <StyledAction className="description" style={style}>
              {pm_action}
            </StyledAction>
          </Tooltip>
          <span className="description" style={style}>
            {`${
              pm_status === 'No action needed'
                ? 'On Track'
                : capitalizeWords(pm_status)
            } ${
              display_type.display === 'time'
                ? moment(new Date(due_time_in_s * 1000)).format('MM/DD/YYYY')
                : ''
            }`}
          </span>
          {carRemains.map((remain, index) => (
            <span className="description">
              {pm_status === 'Overdue' ? 'By' : 'In'} {remain.displayValue}{' '}
              {remain.displayText}
            </span>
          ))}
          {/* <Tag
            key={1}
            style={{
              color: getColor(),
              borderColor: getColor(),
            }}
          >
            <Icon type={getIcon()} style={{ marginRight: '0.5rem' }} />
            {pm_status}
          </Tag> */}
        </NextPMPercentageWrapper>
      </Col>
      {device !== 'mobile' && (
        <Col span={24}>
          <Button type="link" onClick={() => onExpandClick(car, 'pm')}>
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
            {currentExpandedCarIds.indexOf(car.id) > -1
              ? 'Hide Details'
              : 'Show PMs'}
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
          </Button>
        </Col>
      )}
    </>
  );
};

export const renderNextPMVisitV2 = ({
  car = {},
  nextPm = null,
  engineHours,
  totalMileage,
}) => {
  return (
    <Observer>
      {() => {
        if (!nextPm || !nextPm?.nextPm) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(nextPm?.nextPm)) {
          return (
            <>
              <Col span={24}>
                <Link
                  to={`/service-interval-new?carId=${car.id}&carType=${car.carType}`}
                >
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        const dueMessages = [];
        for (const currentNextPm of nextPm?.nextPm || []) {
          if (getRoutineDueEngineHours(currentNextPm)) {
            const routineDueEngineHours = Number(
              getRoutineDueEngineHours(currentNextPm)
            );
            const engineHoursInHours = convertSecondsToHours(engineHours);
            const dueSoon = engineHoursInHours < routineDueEngineHours;
            if (dueSoon) {
              dueMessages.push({
                message: (
                  <>
                    <Text>
                      <Icon
                        type="clock-circle"
                        style={{ marginRight: '0.5rem' }}
                      />
                      In{' '}
                      {Math.round(
                        Number(routineDueEngineHours - engineHoursInHours)
                      )}{' '}
                      Engine Hours
                    </Text>
                  </>
                ),
                dueSoon,
              });
            } else {
              // overdue
              dueMessages.push({
                message: (
                  <>
                    <Text type="danger">
                      <Icon type="alert" style={{ marginRight: '0.5rem' }} />
                      Overdue by{' '}
                      {Math.round(
                        Number(engineHoursInHours - routineDueEngineHours)
                      )}{' '}
                      Engine Hours
                    </Text>
                  </>
                ),
                dueSoon,
              });
            }
          }

          if (getRoutineDueMileage(currentNextPm)) {
            const dueSoon =
              calcMileage(totalMileage) < getRoutineDueMileage(currentNextPm);
            if (dueSoon) {
              dueMessages.push({
                message: (
                  <>
                    <Text>
                      <Icon
                        type="dashboard"
                        style={{ marginRight: '0.5rem' }}
                      />
                      Due in{' '}
                      {Math.round(
                        Number(
                          getRoutineDueMileage(currentNextPm) -
                            calcMileage(totalMileage)
                        )
                      )}{' '}
                      {CurrentUserStore.user.displayedOdometer}
                    </Text>
                  </>
                ),
                dueSoon,
              });
            } else {
              // overdue
              dueMessages.push({
                message: (
                  <>
                    <Text type="danger">
                      <Icon type="alert" style={{ marginRight: '0.5rem' }} />
                      Overdue by{' '}
                      {Math.round(
                        Number(
                          calcMileage(totalMileage) -
                            getRoutineDueMileage(currentNextPm)
                        )
                      )}{' '}
                      {CurrentUserStore.user.displayedOdometer}
                    </Text>
                  </>
                ),
                dueSoon,
              });
            }
          }
        }
        if (dueMessages.length === 0) {
          return (
            <>
              <Col span={24}>
                <Link
                  to={`/service-interval-new?carId=${car.id}&carType=${car.carType}`}
                >
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        return (
          <>
            <Col span={24}>
              <NextPMWrapper>
                <Text strong>Routine Service</Text>
                {_.map(dueMessages, (msg) => (
                  <>
                    {msg.message}
                    <Tag key={msg.message} color={msg.dueSoon ? 'blue' : 'red'}>
                      {msg.dueSoon ? 'Due Soon' : 'Overdue'}
                    </Tag>
                  </>
                ))}
              </NextPMWrapper>
            </Col>
          </>
        );
      }}
    </Observer>
  );
};

renderNextPMVisitV2.propTypes = {
  car: PropTypes.any,
  nextPm: PropTypes.any,
  engineHours: PropTypes.any,
  totalMileage: PropTypes.any,
};

export const renderWorkOrderAssigned = (workOrdersForCar, shopWorkOrderStatuses = []) => {
  const { device } = DeviceStore;

  const RowWorkOrderItem = styled(Row).attrs({ as: 'div' })`
    display: flex;
    justify-content: space-between;
  `;

  const ColStatus = styled(Col).attrs({ as: 'div' })`
    display: flex;
    align-items: center;
  `;
  workOrdersForCar.sort((a, b) =>
    moment(b.created_at).diff(moment(a.created_at))
  );

  const getWorkOrderStatusObj = (status) => {
    const obj = shopWorkOrderStatuses.find((s) => s.key === status);
    return obj || { name: status, color: '#000000' };
  };

  return (
    <Observer>
      {() => {
        if (!workOrdersForCar) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(workOrdersForCar)) {
          return (
            <>
              <Col span={24}>N/A</Col>
            </>
          );
        }
        const workOrdersForCarMessages = [];
        if (workOrdersForCar.length > 0) {
          // Add first work order
          const firstWorkOrder = workOrdersForCar[0];
          workOrdersForCarMessages.push(
            <>
              {device === 'mobile' ? (
                <>
                  <Link to={`/work-order/${firstWorkOrder.id}/edit`}>
                    <RowWorkOrderItem
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <ColStatus>
                        <Text strong style={{ marginRight: 5 }}>
                          #{firstWorkOrder.invoice_number}
                        </Text>
                        <PitStopTagStatus
                          content={
                            <Text
                              style={{
                                color: getWorkOrderStatusObj(firstWorkOrder.status).color,
                              }}
                            >
                              {getWorkOrderStatusObj(firstWorkOrder.status).name}
                            </Text>
                          }
                        ></PitStopTagStatus>
                      </ColStatus>
                      <Col>
                        <Row type="flex" align="middle">
                          <ExternalIcon />
                          <span className="marginLeft5">View</span>
                        </Row>
                      </Col>
                    </RowWorkOrderItem>
                  </Link>
                </>
              ) : (
                <>
                  <b>#{firstWorkOrder.invoice_number}</b>
                  {' - '}
                  {getWorkOrderStatusObj(firstWorkOrder.status).name}
                  <br />
                  <Link to={`/work-order/${firstWorkOrder.id}/edit`}>
                    <Icon type="book" />
                    <span className="marginLeft5">View</span>
                  </Link>
                </>
              )}
            </>
          );

          // Add remaining work orders in popover if they exist
          if (workOrdersForCar.length > 1) {
            const remainingWorkOrders = workOrdersForCar.slice(1);
            const popoverContent = (
              <>
                {remainingWorkOrders.map(workOrder => (
                  <div key={workOrder.id} style={{marginBottom: '8px'}}>
                    <Link to={`/work-order/${workOrder.id}/edit`}>
                      <b>#{workOrder.invoice_number}</b>
                      {' - '}
                      <span style={{color: getWorkOrderStatusObj(workOrder.status).color}}>
                        {getWorkOrderStatusObj(workOrder.status).name}
                      </span>
                    </Link>
                  </div>
                ))}
              </>
            );

            workOrdersForCarMessages.push(
              <Popover
                content={popoverContent}
                title="Past Work Orders"
                trigger="click"
              >
                <Button type="link" style={{ padding: 0 }}>
                  See {remainingWorkOrders.length} more...
                </Button>
              </Popover>
            );
          }
        }
        return (
          <>
            {device !== 'mobile' ? (
              <Col>
                {_.map(workOrdersForCarMessages, (msg) => (
                  <>
                    {msg}
                    {device !== 'mobile' && <br />}
                  </>
                ))}
              </Col>
            ) : (
              <>
                {_.map(workOrdersForCarMessages, (msg) => (
                  <>{msg}</>
                ))}
              </>
            )}
          </>
        );
      }}
    </Observer>
  );
};

export const renderAssetSafetyInspections = (
  car,
  onExpandClick = () => {},
  currentExpandedCarIds = []
) => {
  const { device } = DeviceStore;
  const { safety_due_time_in_s, safety_pm_percent, safety_pm_status } = car;

  const dueInDays = moment.unix(safety_due_time_in_s).diff(moment(), 'days');
  const overdueDays = moment().diff(moment.unix(safety_due_time_in_s), 'days');

  const overdueOrDueIn = safety_due_time_in_s
    ? overdueDays > 0
      ? overdueDays
      : dueInDays
    : null;

  const getColor = () => {
    switch (safety_pm_status) {
      case 'Overdue':
        return '#FF202F';
      case 'Due Soon':
        return '#FF812A';
      case 'No action needed':
      case 'In Compliance':
        return '#52c41a';
      default:
        return '#333333';
    }
  };

  const getIcon = () => {
    switch (safety_pm_status) {
      case 'Overdue':
        return 'alert';
      case 'Due Soon':
        return 'calendar';
      case 'Upcoming':
        return 'clock-circle';
      case 'In Compliance':
      case 'No action needed':
        return 'check-circle';
      default:
        return 'minus-circle';
    }
  };

  const getDueDateInText = () => {
    switch (safety_pm_status) {
      case 'Overdue':
        return 'Overdue';
      case 'Due Soon':
        return 'Due Soon';
      case 'Upcoming':
        return 'Due';
      default:
        return 'Due';
    }
  };

  const getDueInText = () => {
    switch (safety_pm_status) {
      case 'Overdue':
        return 'By';
      case 'Due Soon':
      case 'Upcoming':
      default:
        return 'In';
    }
  };

  const getBadgeText = () => {
    switch (safety_pm_status) {
      case 'Overdue':
        return 'Overdue';
      case 'Due Soon':
        return 'Due Soon';
      case 'Upcoming':
        return 'Upcoming';
      case 'No action needed':
      case 'In Compliance':
        return 'In Compliance';
      default:
        return '';
    }
  };

  return !safety_due_time_in_s ? (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      --
    </div>
  ) : (
    <>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography.Text
            style={{
              color: getColor(),
              fontSize: '20px',
            }}
          >
            {Math.max(0, Math.floor(safety_pm_percent))}
            {'%'}
          </Typography.Text>
        </Col>
        <Col
          span={24}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography.Text
            style={{
              fontSize: '12px',
              color: getColor(),
            }}
          >
            <div>{`${getDueDateInText()} ${moment(
              new Date(safety_due_time_in_s * 1000)
            ).format('MM/DD/YYYY')}`}</div>
            {safety_pm_status !== 'In Compliance' && (
              <div>
                {getDueInText()} {overdueOrDueIn} day(s)
              </div>
            )}
          </Typography.Text>
        </Col>
        {safety_pm_status === 'In Compliance' && (
          <Col
            span={24}
            style={{
              textAlign: 'center',
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PitstopBadge
              color={getColor()}
              icon={getIcon()}
              text={getBadgeText()}
            />
          </Col>
        )}
      </Row>
      <Row>
        {device !== 'mobile' && (
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button type="link" onClick={() => onExpandClick(car, 'pm')}>
              <Icon
                type={
                  currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'
                }
              />
              {currentExpandedCarIds.indexOf(car.id) > -1
                ? 'Hide Details'
                : 'Show Inspections'}
              <Icon
                type={
                  currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'
                }
              />
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

renderAssetSafetyInspections.propTypes = {
  car: PropTypes.any,
};
