import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, Col } from 'antd';

import {
  AssetworksFleetFocus,
  Calamp,
  FASTER,
  FleetComplete,
  Fleetio,
  GPSInsight,
  Motive,
  RTAfleet,
  SoleraOmnitracs,
  Teletrac,
  Trailcon,
  Trimble,
  VerizonConnect,
  Webfleet
} from 'assets/gps_telematic';

import { ImagePicker } from 'shared/ImagePicker';
import PitstopModal from 'shared/PitstopUI/PitstopModal';

import { AppStore, IntegrationStore } from 'stores';
import { ContactLink } from 'v3/components/pitstop';

class OtherFormModal extends React.Component {
  state = {
    selectedTelematic: null,
    selectedTelematicError: false,
  };

  childCallables = null;

  setChildCallables = callables => {
    this.childCallables = callables;
  };

  getImgName = imgFile => {
    let imgFileArr = _.split(imgFile, '/');
    return _.split(imgFileArr[imgFileArr.length - 1], '.')[0];
  };

  handleCreate = async () => {
    const { afterCreate } = this.props;

    try {
      this.childCallables.clearImagePick();
      this.setState({
        selectedTelematicError: false,
      });

      afterCreate && afterCreate();
      IntegrationStore.isReloaded = true;
      this.props.next && this.props.next();
    } catch (err) {
      const message = _.get(err, 'response.data.message') || err.message;
      AppStore.addError(`unable to create integration record: '${message}`);
      throw err;
    }
  };

  handleCancel = () => { };

  getIntegrationList = () => {
    let imageList = [
      AssetworksFleetFocus,
      Calamp,
      FASTER,
      FleetComplete,
      Fleetio,
      GPSInsight,
      Motive,
      RTAfleet,
      SoleraOmnitracs,
      Teletrac,
      Trailcon,
      Trimble,
      VerizonConnect,
      Webfleet,
    ];

    let getPickedImage = image => {
      this.setState({
        selectedTelematic: image,
        selectedTelematicError: false,
      });
    }

    return (
      <Row gutter={[16, 16]} style={{ marginTop: '20px', justifyContent: 'center' }}>
        {imageList.map((image, index) => (
          <Col xs={12} sm={8} md={6} lg={4} xl={3} key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
            <div
              style={{
                border: this.state.selectedTelematic === image ? '2px solid #02103d' : '',
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%', // Ensure uniform width
                height: '120px', // Fixed height for uniform size
                maxWidth: '150px', // Limit the maximum size
              }}
              onClick={() => {
                this.setState({
                  selectedTelematic: image,
                  selectedTelematicError: false,
                });
              }}
            >
              <img
                src={image}
                alt="Telematic Solution"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // Ensure the logos are contained within the box
                  filter: this.state.selectedTelematic === image ? 'none' : 'grayscale(100%)',
                  transition: 'filter 0.3s ease',
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  render() {
    const { id } = this.props;

    return (
      <PitstopModal
        id={id}
        title="Add Other Integration Devices"
        okText="Create"
        onCancel={this.handleCancel}
        onOk={this.handleCreate}
        successMessage={'Integration Record Created.'}
        width={1000}
        style={{ top: '2vw' }}
        bodyStyle={{
          padding: 24,
        }}
      >
        {this.getIntegrationList()}
        <ContactLink />
      </PitstopModal>
    );
  }

  static propTypes = {
    shopId: PropTypes.number,
    afterCreate: PropTypes.func,
    id: PropTypes.string,
    next: PropTypes.func,
  };
}

export default OtherFormModal;
