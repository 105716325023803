import { observable, action, decorate } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { EventBus } from './EventBus';

// Notification types
export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  LOADING = 'loading',
}

// Notification interface
export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
  timestamp: number;
  duration?: number | null; // null for persistent notifications
  onClose?: () => void;
}

class NotificationStoreClass {
  notifications: Notification[] = [];

  constructor() {
    // Set up event listeners
    EventBus.$on('notification:add', this.addNotification);
    EventBus.$on('notification:update', this.updateNotification);
    EventBus.$on('notification:remove', this.removeNotification);
    EventBus.$on('notification:clear', this.clearNotifications);
  }

  addNotification = (notification: Omit<Notification, 'id' | 'timestamp'>) => {
    const newNotification: Notification = {
      id: uuidv4(),
      timestamp: Date.now(),
      ...notification,
    };
    this.notifications.push(newNotification);

    // Auto remove non-persistent notifications
    if (notification.duration !== null && notification.duration !== undefined) {
      setTimeout(() => {
        this.removeNotification(newNotification.id);
      }, notification.duration);
    }

    return newNotification.id;
  };

  updateNotification = (id: string, updates: Partial<Omit<Notification, 'id' | 'timestamp'>>) => {
    const index = this.notifications.findIndex(n => n.id === id);
    if (index !== -1) {
      this.notifications[index] = {
        ...this.notifications[index],
        ...updates,
      };
    }
  };

  removeNotification = (id: string) => {
    const notification = this.notifications.find(n => n.id === id);
    if (notification && notification.onClose) {
      notification.onClose();
    }
    this.notifications = this.notifications.filter(n => n.id !== id);
  };

  clearNotifications = () => {
    this.notifications = [];
  };
}

decorate(NotificationStoreClass, {
  notifications: observable,
  addNotification: action,
  updateNotification: action,
  removeNotification: action,
  clearNotifications: action,
});

export const NotificationStore = new NotificationStoreClass();
