import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import NotificationItem from './NotificationItem';
import { NotificationStore } from '../../stores/NotificationStore';

const NotificationsWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const NotificationsContainer: React.FC = () => {
  const handleClose = (id: string) => {
    NotificationStore.removeNotification(id);
  };

  return (
    <NotificationsWrapper>
      {NotificationStore.notifications.map(notification => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onClose={handleClose}
        />
      ))}
    </NotificationsWrapper>
  );
};

export default observer(NotificationsContainer);
