import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import IntegrationPage from 'containers/Integration/IntegrationPage';

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6f7ff;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const BarButton = styled.button`
  background: #02103d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #003399;
  }
`;

class SampleDataBar extends Component {
  state = {
    showIntegrationPopup: false,
  };

  toggleIntegrationPopup = (visible) => {
    this.setState({ showIntegrationPopup: visible });
  };

  render() {
    const { showIntegrationPopup } = this.state;

    return (
      <>
        <BarContainer>
          <span>
            You’re on a free trial. Unlock Pitstop’s full potential by
            integrating your fleet’s data.
          </span>
          <BarButton onClick={() => this.toggleIntegrationPopup(true)}>
            Integrate Fleet
          </BarButton>
        </BarContainer>
        {showIntegrationPopup && (
          <IntegrationPage
            onClose={() => this.toggleIntegrationPopup(false)}
          />
        )}
      </>
    );
  }
}

export default observer(SampleDataBar);