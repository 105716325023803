import { Layout } from 'antd';
import styled from 'styled-components';

const StyledLayout = styled(Layout)`
  padding: 24px 0px 24px 24px;

  @media (max-width: 992px) {
    padding: 24px 0px;
  }

  @media (max-width: 576px) {
    padding: 0px;
  }
`;

export default StyledLayout;
