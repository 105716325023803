import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Form, InputNumber, Popover, Spin, Switch, Tooltip } from 'antd';
import PitstopSelect from 'components/Forms/PitstopSelect';
import { CarStatusStore, CarStore, ContactStore, ShopStore } from 'stores';

interface FilterValues {
  timePeriod?: number;
  limit?: number;
  filter?: {
    status?: string[];
    vendor?: string[];
    make?: string[];
    model?: string[];
    year?: string[];
    carIds?: string[];
  }
}

interface ShopStatus {
  key: string;
  description: string;
}

interface ShopContact {
  id: string;
  name: string;
}

interface ShopMake {
  car_make: string;
}

interface ShopModel {
  car_model: string;
}

interface ShopYear {
  car_year: string;
}

interface ShopCar {
  id: string;
  unitId: string;
}

interface FleetReportsFiltersFormProps {
  timePeriod: number;
  limit: number;
  filter: FilterValues;
  hideStatusFilter?: boolean;
  showMaintenanceInsights?: boolean;
  hideVendorFilter?: boolean;
  isMaintenanceInsightsStacked?: boolean;
  suppressTimePeriod?: number[];
  shopStatuses: ShopStatus[];
  shopContacts: ShopContact[];
  shopMakes: ShopMake[];
  shopModels: ShopModel[];
  shopYears: ShopYear[];
  shopCars: ShopCar[];
  onChange: (filter: FilterValues) => void;
  onReset: () => void;
  onApply: () => void;
  onToggleMaintenanceInsights: (checked: boolean) => void;
}

export const FleetReportsFiltersForm: FC<FleetReportsFiltersFormProps> = ({
  timePeriod,
  limit,
  filter,
  hideStatusFilter,
  showMaintenanceInsights,
  hideVendorFilter,
  isMaintenanceInsightsStacked,
  suppressTimePeriod,
  onChange,
  onReset,
  onApply,
  onToggleMaintenanceInsights,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isLoadingInitialFilterData, setIsLoadingInitialFilterData] = useState(false);
  const [shopContacts, setShopContacts] = useState<ShopContact[]>([]);
  const [shopStatuses, setShopStatuses] = useState<ShopStatus[]>([]);
  const [shopMakes, setShopMakes] = useState<ShopMake[]>([]);
  const [shopModels, setShopModels] = useState<ShopModel[]>([]);
  const [shopYears, setShopYears] = useState<ShopYear[]>([]);
  const [shopCars, setShopCars] = useState<ShopCar[]>([]);

  const loadInitialFilterData = async () => {
    setIsLoadingInitialFilterData(true);
    const [
      shopContacts,
      shopStatuses,
      shopMakes,
      shopModels,
      shopYears,
      shopCars,
    ] = await Promise.all([
      ContactStore.getShopContacts(ShopStore.currentShop.id),
      CarStatusStore.fetchShopCarStatuses(ShopStore.currentShop.id),
      ShopStore.getShopMakes(ShopStore.currentShop.id),
      ShopStore.getShopModels(ShopStore.currentShop.id),
      ShopStore.getShopYears(ShopStore.currentShop.id),
      CarStore.getShopCarsBasicInfoAndReturn(ShopStore.currentShop.id),
    ]);
    setShopContacts(shopContacts || []);
    setShopStatuses(shopStatuses || []);
    setShopMakes(shopMakes || []);
    setShopModels(shopModels || []);
    setShopYears(shopYears || []);
    setShopCars(shopCars || []);
    setIsLoadingInitialFilterData(false);
  };

  useEffect(() => {
    loadInitialFilterData();
  }, []);

  // watch for ShopStore.currentShop.id changes and load initial filter data
  useEffect(() => {
    loadInitialFilterData();
  }, [ShopStore.currentShop.id]);

  const handleFilterChange = (
    key: keyof FilterValues,
    value: string[] | number | undefined
  ) => {
    onChange({ [key]: value });
  };

  const handlePopoverFilterChange = (
    changedFields: Partial<FilterValues['filter']>
  ) => {
    onChange({ filter: { ...filter.filter, ...changedFields } });
  };

  const handleReset = () => {
    onReset();
    setPopoverVisible(false);
  };

  const handleApply = () => {
    onApply();
    setPopoverVisible(false);
  };

  if (isLoadingInitialFilterData) {
    return <Spin />;
  }

  const timePeriodOptions = [
    { label: '7 days', value: 7 },
    { label: '30 days', value: 30 },
    { label: '90 days', value: 90 },
    { label: '180 days', value: 180 },
    { label: '360 days', value: 360 },
  ];

  const filteredTimePeriodOptions = timePeriodOptions.filter((option) => {
    return !suppressTimePeriod?.includes(option.value);
  });

  return (
    <Form
      layout="inline"
      style={{ marginTop: '10px' }}
    >
      <Form.Item label="Select Date Range">
        <PitstopSelect
          style={{ minWidth: 100 }}
          defaultValue={7}
          value={timePeriod}
          onChange={(value: number) => {
            handleFilterChange('timePeriod', value)
          }}
          options={filteredTimePeriodOptions}
        />
      </Form.Item>
      <Form.Item label="Asset Limit">
        <InputNumber
          min={1}
          defaultValue={20}
          value={limit}
          onChange={(value: number | undefined) => {
            handleFilterChange('limit', value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Popover
          placement="bottomLeft"
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
          content={
            <Row gutter={[8, 8]}>
              {!hideStatusFilter && (
                <Col span={24}>
                  <Typography.Text>Select Status:</Typography.Text>
                  <PitstopSelect
                    mode='multiple'
                    options={shopStatuses.map((status) => ({
                      label: status.description,
                      value: status.key,
                    }))}
                    value={filter.filter?.status}
                    onChange={(value: string[] | undefined) => {
                      handlePopoverFilterChange({ status: value });
                    }}
                  />
                </Col>
              )}
              {!hideVendorFilter && (
                <Col span={24}>
                  <Typography.Text>Select Vendor</Typography.Text>
                  <PitstopSelect
                  mode='multiple'
                  options={shopContacts.map((contact) => ({
                    label: contact.name,
                    value: contact.id,
                  }))}
                  value={filter.filter?.vendor}
                  onChange={(value: string[] | undefined) => {
                    handlePopoverFilterChange({ vendor: value });
                  }}
                  />
                </Col>
              )}
              {/* Filter by Make */}
              <Col span={24}>
                <Typography.Text>Filter by Make</Typography.Text>
                <PitstopSelect
                  mode='multiple'
                  options={shopMakes.map((make) => ({
                    label: make.car_make,
                    value: make.car_make,
                  }))}
                  value={filter.filter?.make}
                  onChange={(value: string[] | undefined) => {
                    handlePopoverFilterChange({ make: value });
                  }}
                />
              </Col>
              {/* Filter by Model */}
              <Col span={24}>
                <Typography.Text>Filter by Model</Typography.Text>
                <PitstopSelect
                  mode='multiple'
                  options={shopModels.map((model) => ({
                    label: model.car_model,
                    value: model.car_model,
                  }))}
                  value={filter.filter?.model}
                  onChange={(value: string[] | undefined) => {
                    handlePopoverFilterChange({ model: value });
                  }}
                />
              </Col>
              {/* Filter by Year */}
              <Col span={24}>
                <Typography.Text>Filter by Year</Typography.Text>
                <PitstopSelect
                  mode='multiple'
                  options={shopYears.map((year) => ({
                    label: year.car_year,
                    value: year.car_year,
                  }))}
                  value={filter.filter?.year}
                  onChange={(value: string[] | undefined) => {
                    handlePopoverFilterChange({ year: value });
                  }}
                />
              </Col>
              {/* Search by Assets */}
              <Col span={24}>
                <Typography.Text>Search by Assets</Typography.Text>
                <PitstopSelect
                  placeholder="Search Asset ID"
                  options={(shopCars || []).map((car) => ({
                    label: car.unitId,
                    value: car.id,
                  }))}
                  mode="multiple"
                  value={filter.filter?.carIds}
                  onChange={(value: string[] | undefined) => {
                    handlePopoverFilterChange({ carIds: value });
                  }}
                />
              </Col>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button style={{ marginRight: 10 }} onClick={handleReset}>
                  Reset
                </Button>
                <Button type="primary" onClick={handleApply}>
                  Apply
                </Button>
              </Col>
            </Row>
          }
        >
          <Button type="primary" icon="filter">
            Filters
          </Button>
        </Popover>
      </Form.Item>
      {showMaintenanceInsights && (
      <Form.Item label="Maintenance Insights">
        <Switch
          checked={isMaintenanceInsightsStacked}
          onChange={(checked: boolean) => onToggleMaintenanceInsights(checked)}
          checkedChildren="ON"
          unCheckedChildren="OFF"
        />
        <Tooltip
          placement="right"
          title={
            'Toggle to divide each bar in the chart highlighting a red section for unplanned maintenance and blue section for planned maintenance'
          }
        >
          <Button
            style={{ marginLeft: 10, marginRight: 20 }}
            size="small"
            shape="circle"
            icon="info"
          />
          </Tooltip>
        </Form.Item>
      )}
    </Form>
  );
};
