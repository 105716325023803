import { decorate, action } from 'mobx';
import _ from 'lodash';

import AppStore from './App';
import ShopStore from './ShopStore';
import AbstractStore from './abstract/AbstractStore';
import { webServiceProvider } from '../shared';
import { Logger } from './Classes';

class ContactStore extends AbstractStore {
  contactAbortController = null;
  createContactAbortController = new AbortController();

  getShopContacts = async (
    shopId = ShopStore.currentShop.id,
    { offset, limit, filter, sort } = {}
  ) => {
    if (!shopId || shopId === -1) {
      return;
    }
    try {
      // Cancel any ongoing request
      if (this.contactAbortController) {
        this.contactAbortController.abort();
      }

      // Create new abort controller for this request
      this.contactAbortController = new AbortController();

      const { data } = await webServiceProvider.getMany(
        'v1/contact',
        {
          shopId,
        },
        this.contactAbortController.signal
      );

      return data;
    } catch (e) {
      // Don't throw error if request was intentionally aborted
      if (e.name === 'AbortError') {
        return;
      }
      // AppStore.addError(`Error in getting contacts: ${e.message}`);
      throw e;
    }
  };

  createShopContact = async (payload) => {
    try {
      // check duplicate contact - with same email for shop
      const contacts = await this.getShopContacts(payload.shopId);
      const duplicateContact = _.find(contacts, (contact) => {
        return contact.email === payload.email;
      });
      if (duplicateContact) {
        throw new Error('Contact with same email already exists');
      }

      this.createContactAbortController.abort();

      this.createContactAbortController = new AbortController();

      const data = await webServiceProvider.post(
        'v1/contact',
        payload,
        this.createContactAbortController.signal
      );

      return data;
    } catch (e) {
      throw e;
    }
  };

  updateShopContact = async (contactId, payload) => {
    try {
      const data = await webServiceProvider.put(
        `v1/contact/${contactId}`,
        payload
      );

      return data;
    } catch (e) {
      AppStore.addError(`Error in updating contact: ${e.message}`);
      throw e;
    }
  };
}

decorate(ContactStore, {
  getShopContacts: action,
  createShopContact: action,
});

const ContactStoreInstance = new ContactStore();
export default ContactStoreInstance;
