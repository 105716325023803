import React, { Component } from 'react';
import { computed, decorate, observe } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _, { get } from 'lodash';

import { Card, Row, Col, Table, Button, Tooltip, Icon, Upload } from 'antd';

import { showDemoVinOrRealVin } from 'shared/utils';

import {
  AppStore,
  ShopStore,
  CarStore,
  UserStore,
  CurrentUserStore,
  ContactStore,
} from 'stores';
import { CarObject, Logger } from 'stores/Classes';

import {
  calcMileage,
  calcMileageBack,
  convertHourToSeconds,
} from 'helpers/unitCalculations';
import { interpolateColors } from 'helpers/color-generator';

import DriverSummary from './DriverSummary';

import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';
import { TextField } from 'shared/PitstopUI';
import moment from 'moment-timezone';
import WarrantyStore from 'stores/WarrantyStore';
import WarrantiesTable from 'components/Warranties/WarrantiesTable';
import { customFleets } from 'shared';

const TextRow = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 34px;
`;

const LabelText = styled.p`
  width: 40%;
  font-weight: 700;
  margin-bottom: 0;
  align-self: center;
`;

const ContentText = styled.p`
  flex: 1;
  margin-bottom: 0;
  align-self: center;
`;

const SectionTitle = styled.h3`
  font-size: 22px;
  font-weight: 400;
  padding-left: 12px;
  margin-top: 16px;
`;

const CardInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 0;
`;

const SmallTable = styled(Table)`
  .ant-table-small {
    border: none;
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th {
    font-weight: 600;
  }

  button {
    color: #595959;
  }
`;

const UploadedDocuments = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
`;

const UploadDocumentRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;

  .document-info {
    margin-left: 10px;
    flex: 1;

    .file-title {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 13px;
    }

    .added-on {
      margin: 2px 0 0;
      font-size: 12px;
      color: #595959;
    }
  }

  .ant-btn {
    color: #595959;
  }
`;

const UploadedDocument = ({ title, addedOn }) => {
  return (
    <UploadDocumentRow>
      <Icon type="file-pdf" size="lg" />
      <div className="document-info">
        <p className="file-title">{title}</p>
        <p className="added-on">Added on {addedOn}</p>
      </div>
      <Button type="link" icon="delete" />
    </UploadDocumentRow>
  );
};

UploadedDocument.propTypes = {
  title: PropTypes.string.isRequired,
  addedOn: PropTypes.string.isRequired,
};

class CarProfileHeader extends Component {
  static propTypes = {
    car: PropTypes.instanceOf(CarObject),
    shopId: PropTypes.number.isRequired,
  };

  disposer = observe(CarStore, 'demo', async () => {
    if (CarStore.demo) {
      await this.getVehicleDetailReport();
      await this.getVehicleGeneralReport();
    }
  });

  state = {
    warrantyData: [],
    isLoadingAsssetWarranties: true,
    warrantyFilters: {},
    warrantyPagination: {},
    warrantySorter: {},
    vendors: [],
  };

  async componentDidMount() {
    await this.getVehicleDetailReport();
    await this.getVehicleGeneralReport();
    if (!this.integrationStatus.loaded) {
      await this.props.car.getIntegrationStatus();
    }
    await this.loadShopVendors();
    this.loadWarranties();
  }

  componentWillUnmount() {
    this.disposer();
  }

  loadShopVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ vendors: [] });
        return;
      }
      const vendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ vendors });
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    }
  };

  loadWarranties = async () => {
    try {
      this.setState({ isLoadingAsssetWarranties: true });
      const { id } = this.props.car;
      const filters = {
        ...this.state.warrantyFilters,
        carIds: id,
      };
      if (filters.status && filters.status.length) {
        filters.statuses = filters.status.join(',');
      }
      if (this.state.warrantyPagination.current) {
        const page = this.state.warrantyPagination.current;
        filters.limit = this.state.warrantyPagination.pageSize;
        filters.offset = (page - 1) * filters.limit;
      }
      if (this.state.warrantySorter.column && this.state.warrantySorter.order) {
        const field = this.state.warrantySorter.field;
        const direction =
          this.state.warrantySorter.order === 'ascend' ? '' : '-';
        filters.sortBy = `${direction}${field}`;
      }
      const response = await WarrantyStore.getWarranties(
        ShopStore.currentShop.id,
        filters
      );
      const { data = [], meta = {} } = response || {};
      this.setState({
        warrantyData: data,
        warrantyPagination: {
          ...this.state.warrantyPagination,
          total: meta?.total || 0,
        },
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingAsssetWarranties: false });
    }
  };

  getVehicleDetailReport = async () => {
    await this.props.car.getVehicleDetailReport();
  };

  getVehicleGeneralReport = async () => {
    await this.props.car.getVehicleGeneralReport();
  };

  get vehicleDetailReport() {
    return this.props.car.detailReport;
  }

  get vehicleGeneralReport() {
    return this.props.car.generalReport;
  }

  get mileageField() {
    const totalMileage = calcMileage(Number(this.props.car.totalMileage));

    return (
      <TextRow>
        <LabelText>Mileage</LabelText>
        <TextField
          placeholder="Enter new mileage"
          requiredValue={true}
          type="number"
          defaultValue={`${Number(totalMileage.toFixed(1)).toReadable()} ${
            CurrentUserStore.user.displayedOdometer
          }`}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, {
              totalMileage: Number(calcMileageBack(value)),
            })
          }
        />
      </TextRow>
    );
  }

  get carNameField() {
    const { carName } = this.props.car;

    return (
      <TextRow>
        <LabelText>Unit Id</LabelText>
        <TextField
          placeholder="Enter an unique identifier for the vehicle"
          defaultValue={carName}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, { carName: value })
          }
        />
      </TextRow>
    );
  }

  get carVinField() {
    const { vin } = this.props.car;

    return (
      <TextRow>
        <LabelText>VIN</LabelText>
        <TextField
          placeholder="Enter new VIN"
          defaultValue={showDemoVinOrRealVin(vin)}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, { vin: value })
          }
        />
      </TextRow>
    );
  }

  get deviceField() {
    const { scanner } = this.props.car;
    const device = scanner ? scanner.scannerId || scanner.id : null;

    return (
      <TextRow>
        <LabelText>Device</LabelText>
        <TextField
          placeholder="Enter new device id"
          noValueText="No Device Connected"
          defaultValue={device}
          onSubmit={(value) =>
            CarStore.updateScannerForCar(this.props.car, { scannerId: value })
          }
        />
      </TextRow>
    );
  }

  get carMake() {
    const { make } = this.props.car;

    return (
      <TextRow>
        <LabelText>Make</LabelText>
        <TextField
          placeholder="Enter new car make"
          defaultValue={make}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, { make: value })
          }
        />
      </TextRow>
    );
  }

  get carModel() {
    const { model } = this.props.car;

    return (
      <TextRow>
        <LabelText>Model</LabelText>
        <TextField
          placeholder="Enter new car model"
          defaultValue={model}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, { model: value })
          }
        />
      </TextRow>
    );
  }

  get carYear() {
    const { year } = this.props.car;

    return (
      <TextRow>
        <LabelText>Year</LabelText>
        <TextField
          placeholder="Enter new car year"
          type="year"
          defaultValue={year}
          maxLenght={4}
          onSubmit={(value) =>
            CarStore.updateCar(this.props.car, { year: value })
          }
        />
      </TextRow>
    );
  }

  get carEngineHours() {
    return (
      <TextRow>
        <LabelText>Engine Hours</LabelText>
        <TextField
          placeholder="Enter new car engine hours"
          type="number"
          defaultValue={Math.round(
            this.props.car.getEngineHours()
          ).toReadable()}
          onSubmit={async (value) => {
            let engineHours = convertHourToSeconds(value);

            if (!_.isNil(engineHours)) {
              await CarStore.updateCar(this.props.car, {
                engineHours,
              });
            }

            AppStore.addSuccess('Your vehicle has been updated!');
          }}
        />
      </TextRow>
    );
  }

  get issuesData() {
    if (CarStore.demo === false) {
      return {
        key: 'issues',
        value: [],
      };
    }

    const { issueCounts } = this.props.car;
    let value = [
      {
        name: 'critical',
        color: '#eb3d34',
        y: Number(issueCounts.critical) || 0,
      },
      {
        name: 'major',
        color: '#e28931',
        y: Number(issueCounts.major) || 0,
      },
      {
        name: 'minor',
        color: '#e2eb34',
        y: Number(issueCounts.minor) || 0,
      },
    ];

    return {
      key: '',
      seriesName: 'issues',
      emptyMessage: 'Your vehicle has no Issues',
      value,
    };
  }

  get tagsField() {
    const { tags } = this.props.car;

    return (
      <TextRow>
        <LabelText>Tags</LabelText>
        <ContentText>
          {tags && tags.replace ? tags.replace(/"/g, '') : 'No tags available'}
        </ContentText>
      </TextRow>
    );
  }

  get breakdownReasonsData() {
    if (CarStore.demo === false) {
      return {
        key: 'breakdowns',
        value: [],
      };
    }

    let vehicleDetail = this.vehicleDetailReport;

    let breakdownReasons = _.get(vehicleDetail, 'data.reasonBreakdown');

    let labels = [],
      value = [];

    if (!breakdownReasons) {
      if (_.isBoolean(CarStore.demo)) {
        value = [
          { name: 'Road Call', y: 1 },
          { name: 'Warranty', y: 20 },
          { name: 'Driver Report', y: 24 },
          { name: 'Routine', y: 4 },
          { name: 'PM', y: 7 },
          { name: 'Inspection', y: 4 },
          { name: 'Capitalization', y: 1 },
        ];
      }
    } else {
      labels = Object.keys(breakdownReasons);

      value = _.map(labels, (label) => ({
        name: label,
        y: breakdownReasons[label] || 0,
      }));
    }

    let colors = interpolateColors(labels.length);

    value = _.map(value, (v, i) => ({
      ...v,
      color: colors[i],
    }));

    return {
      seriesName: 'downtime',
      emptyMessage: 'Your vehicle has no downtime',
      value,
    };
  }

  get shopName() {
    const { shopId } = this.props;

    if (!ShopStore.data.has(shopId)) return 'N/A';

    return ShopStore.data.get(shopId).name;
  }

  get mainDriverName() {
    const { userId } = this.props.car;

    if (!UserStore.data.has(userId)) return 'N/A';

    return UserStore.data.get(userId).name;
  }

  get integrationStatus() {
    return this.props.car.integrationStatus;
  }

  renderChart = (data) => {
    return (
      <DoughnutChart
        chart={{
          height: 200,
          margin: [0, 200, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            width: 150,
            x: -30,
            y: 30,
          },
        }}
        data={data}
        loaded={CarStore.demo === false || this.vehicleDetailReport.loaded}
      />
    );
  };

  render() {
    // const { vin } = this.props.car;

    const integrationStatus = this.integrationStatus;
    const faultCodeMigrationSuccessEvent = _.find(integrationStatus.data, (i) =>
      _.get(i, 'event.id', '').includes('geotab_dtc_pull|success')
    );

    // todo: check for fail events
    const faultCodeMigrationSuccessEventTimestamp = moment(
      _.get(faultCodeMigrationSuccessEvent, 'meta.triggeredAt', NaN)
    );
    const getFaultCodeMigrationText = () =>
      `${faultCodeMigrationSuccessEventTimestamp.format(
        'LLLL'
      )} (${faultCodeMigrationSuccessEventTimestamp.fromNow()})`;

    return (
      <>
        <Row gutter={[12, 32]}>
          <Col span={8}>
            <SectionTitle>Asset Details</SectionTitle>
            <Card>
              {this.carNameField}
              {/* <TextRow>
                <LabelText>VIN</LabelText>
                <ContentText>{showDemoVinOrRealVin(vin)}</ContentText>
              </TextRow> */}
              {this.carVinField}
              {this.mileageField}
              {this.deviceField}
              <TextRow>
                <LabelText>Fleet</LabelText>
                <ContentText>{this.shopName}</ContentText>
              </TextRow>
              <TextRow>
                <LabelText>Main Driver</LabelText>
                <ContentText>{this.mainDriverName}</ContentText>
              </TextRow>
              {this.carMake}
              {this.carModel}
              {this.carYear}
              {this.carEngineHours}
              {this.tagsField}
              <TextRow
                style={{
                  display: faultCodeMigrationSuccessEvent
                    ? 'inline-table'
                    : 'none',
                }}
              >
                <LabelText>Fault Code Last Checked At</LabelText>
                <ContentText>{getFaultCodeMigrationText()}</ContentText>
              </TextRow>
              {customFleets.ufpFleet.includes(ShopStore.currentShop.id) && (
                <>
                  <TextRow>
                    <LabelText>Tire Specs:</LabelText>
                    <TextField
                      placeholder="Enter new tire specs"
                      defaultValue={get(this.props.car, 'meta.tireSpecs', '')}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            tireSpecs: value,
                          },
                        });

                        AppStore.addSuccess('Your vehicle has been updated!');
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Tire Size</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(this.props.car, 'meta.tireSize', '')}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            tireSize: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Brand</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(this.props.car, 'meta.tireBrand', '')}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            tireBrand: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Notes</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(this.props.car, 'meta.notes', '')}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            notes: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Vendor</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(this.props.car, 'meta.vendor', '')}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            vendor: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Tire Location</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(
                        this.props.car,
                        'meta.tireLocation',
                        ''
                      )}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            tireLocation: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                  <TextRow>
                    <LabelText>Additional Information</LabelText>
                    <TextField
                      placeholder="Enter new tire size"
                      defaultValue={get(
                        this.props.car,
                        'meta.additionalInfo',
                        ''
                      )}
                      onSubmit={async (value) => {
                        await CarStore.updateCar(this.props.car, {
                          meta: {
                            ...get(this.props.car, 'meta', {}),
                            additionalInfo: value,
                          },
                        });
                      }}
                    />
                  </TextRow>
                </>
              )}
            </Card>
          </Col>

          <Col span={16}>
            <SectionTitle>Warranties</SectionTitle>
            <Card>
              <CardInfo>
                Enter any warranties associated with the vehicle that are
                relevant to keep record
              </CardInfo>
              <WarrantiesTable
                data={this.state.warrantyData}
                isLoading={this.state.isLoadingAsssetWarranties}
                assets={[this.props.car]}
                preSelectedAssets={[this.props.car.id]}
                vendors={this.state.vendors}
                onUpdate={this.loadWarranties}
                onChangePagination={({ pagination, filters, sorter }) => {
                  this.setState(
                    {
                      warrantyPagination: pagination,
                      warrantyFilters: filters,
                      warrantySorter: sorter,
                    },
                    this.loadWarranties
                  );
                }}
              />
            </Card>
            {ShopStore.currentShop.id === 374 && (
              <>
                <SectionTitle>Purchase History</SectionTitle>
                <Card>
                  <CardInfo>
                    Keep track of any sales or costs associated with the asset
                    that is relevant
                  </CardInfo>
                  <SmallTable
                    size="small"
                    columns={[
                      {
                        title: 'Desc.',
                        dataIndex: 'desc',
                        key: 'desc',
                      },
                      {
                        title: 'Purchased',
                        dataIndex: 'purchaseDate',
                        key: 'purchaseDate',
                      },
                      {
                        title: 'Sold',
                        dataIndex: 'sold',
                        key: 'sold',
                      },
                      {
                        title: '',
                        key: 'action',
                        render: () => (
                          <Tooltip title="Edit">
                            <Button icon="edit" size="sm" type="link" />
                          </Tooltip>
                        ),
                      },
                    ]}
                    dataSource={[
                      {
                        key: '1',
                        desc: `${this.props.car?.make || 'Asset'} Purchase`,
                        purchaseDate: '2023-01-03',
                        sold: '2024-01-02',
                      },
                    ]}
                    pagination={false}
                  />
                  <Button
                    type="link"
                    icon="plus-circle"
                    style={{ marginTop: 10 }}
                    onClick={() => {}}
                  >
                    Add New Row
                  </Button>
                </Card>
              </>
            )}
          </Col>
        </Row>
        {ShopStore.currentShop.id === 374 && (
          <Row gutter={[12, 2]}>
            <Col span={16}>
              <SectionTitle>Document Upload</SectionTitle>
              <Card>
                <CardInfo>
                  Upload documents such as DOT inspections, service records,
                  warranty info, etc for your asset
                </CardInfo>
                <Upload name="file" onChange={() => {}}>
                  <Button
                    icon="upload"
                    style={{ marginTop: 10 }}
                    onClick={() => {}}
                  >
                    Upload
                  </Button>
                </Upload>
                <UploadedDocuments>
                  <UploadedDocument
                    title="DOT inspection.pdf"
                    addedOn="2023-01-03"
                  />
                  <UploadedDocument
                    title="Billofsale.pdf"
                    addedOn="2023-01-03"
                  />
                  <UploadedDocument title="Image478.pdf" addedOn="2023-01-03" />
                  <UploadedDocument title="Invoice.pdf" addedOn="2023-01-03" />
                </UploadedDocuments>
              </Card>
            </Col>
          </Row>
        )}
        <div style={{ marginTop: 10 }}>
          <DriverSummary shopId={this.props.shopId} />
        </div>
      </>
    );
  }
}

decorate(CarProfileHeader, {
  shopName: computed,
  mainDriverName: computed,
  vehicleDetailReport: computed,
  vehicleGeneralReport: computed,
  breakdownReasonsData: computed,
  issuesData: computed,
});

export default observer(CarProfileHeader);
